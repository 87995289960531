const retryWithDelay = (attemptFn, failAttemptFn, maxAttempt = 10, timeout = 1000) => {
  let attempts = 0;
  const execute = async () => {
    const isSuccess = await attemptFn();
    attempts++;
    if (!isSuccess) {
      if (maxAttempt && attempts >= maxAttempt) {
        typeof failAttemptFn === 'function' && failAttemptFn();
      } else {
        setTimeout(execute, timeout);
      }
    }
  };
  return execute();
};

export default retryWithDelay;
