import { createContext, useState, useEffect } from 'react';
import useSWR from 'swr';
import urlJoin from 'url-join';

import { useConfig } from '../ConfigContext/ConfigProvider';
import fetch from '../Utils/fetch';

const LOGIN_STATE = {
  LOADING: 'LOADING',
  LOGGED_IN: 'LOGGED_IN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
};

const AuthContext = createContext();

const getIsLogin = (loginState) => {
  switch (loginState) {
    case LOGIN_STATE.LOGGED_IN:
      return true;
    case LOGIN_STATE.NOT_LOGGED_IN:
      return false;
    default:
      return null;
  }
};

const AuthProvider = ({ children }) => {
  const { userAPI, googleAuthenticationPath } = useConfig();
  const [loginState, setLoginState] = useState(LOGIN_STATE.LOADING);
  const [userInfo, setUserInfo] = useState(null);
  const [errorState, setErrorState] = useState(null);

  const USER_PROFILE_PATH = urlJoin(userAPI, '/me');
  const USER_LOGOUT_PATH = urlJoin(userAPI, '/logout');
  const USER_LOGIN_PATH = urlJoin(userAPI, '/login');

  const { data: userProfileData, mutate } = useSWR([USER_PROFILE_PATH], (url) => fetch(url));

  useEffect(() => {
    if (userProfileData) {
      const { error } = userProfileData;

      if (error) {
        setErrorState(error);
        setLoginState(LOGIN_STATE.NOT_LOGGED_IN);
      } else {
        setUserInfo(userProfileData);
        setLoginState(LOGIN_STATE.LOGGED_IN);
        setErrorState(null);
      }
    } else {
      setLoginState(LOGIN_STATE.LOADING);
    }
  }, [userProfileData]);

  const login = async ({ email, password, apolloClient }) => {
    setLoginState(LOGIN_STATE.LOADING);
    try {
      const result = await fetch(USER_LOGIN_PATH, null, fetch.METHOD.POST, { email, password });
      if (result.error) throw new Error(result.eror);
      setLoginState(LOGIN_STATE.LOGGED_IN);
      mutate(USER_PROFILE_PATH);
      /**
       * As AuthProvider is in higher level than Apollo Provider. We will use workaround to pass apollo client when login and logout
       * next.js will not pre render static page if it's in ApolloProvider.
       */
      if (apolloClient) {
        apolloClient.resetStore();
      }
    } catch (error) {
      setLoginState(LOGIN_STATE.NOT_LOGGED_IN);
      throw new Error(error);
    }
  };

  const logout = async ({ apolloClient } = {}) => {
    setLoginState(LOGIN_STATE.LOADING);
    try {
      await fetch(USER_LOGOUT_PATH, null, fetch.METHOD.POST);
      mutate(USER_PROFILE_PATH);
      setUserInfo(null);
      /**
       * As AuthProvider is in higher level than Apollo Provider. We will use workaround to pass apollo client when login and logout
       * next.js will not pre render static page if it's in ApolloProvider.
       */
      if (apolloClient) {
        apolloClient.resetStore();
      }
    } catch (error) {
      console.error(error);
      mutate(USER_PROFILE_PATH);
    }
  };

  const loginWithGoogle = () => {
    const isServer = typeof window === 'undefined';
    if (isServer) return;
    window.location.href = googleAuthenticationPath;
  };

  const fetchUserInfo = () => {
    mutate(USER_PROFILE_PATH);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: getIsLogin(loginState),
        loginWithGoogle,
        login,
        userInfo,
        fetchUserInfo,
        errorState,
        loading: loginState === LOGIN_STATE.LOADING,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext };
