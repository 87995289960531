import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import retryWithDelay from '../../Utils/retryWithDelay';
import { getMySalesOrder } from '../../GraphQL/query/Purchase.query';
import {
  PaymentMySalesOrderQuery,
  PaymentMySalesOrderInputProps,
  PaymentMySalesOrderInput,
} from './types';
import { SALES_ORDER_STATUS } from '../useMySalesOrderData.d';

const useMySalesOrder = ({
  orderNo,
  onCompleted,
  onTimeout,
  ...props
}: PaymentMySalesOrderInputProps) => {
  const {
    data: mySalesOrderData,
    error,
    loading,
    refetch: refetchMySalesOrderStatus,
    ...apolloUtils
  } = useQuery<PaymentMySalesOrderQuery, PaymentMySalesOrderInput>(getMySalesOrder, {
    variables: {
      orderNo,
    },
    ...props,
  });

  useEffect(() => {
    retryWithDelay(
      async () => {
        try {
          const { data } = await refetchMySalesOrderStatus();
          const status = data?.sfFoPaymentMySalesOrder?.status;
          if (status === SALES_ORDER_STATUS.SUCCESS) {
            onCompleted(data?.sfFoPaymentMySalesOrder);
            return true;
          }
        } catch (error) {
          console.error(error);
        }
        return false;
      },
      // failed
      async () => {
        try {
          const { data } = await refetchMySalesOrderStatus();
          onTimeout(data?.sfFoPaymentMySalesOrder);
        } catch (error) {
          // In case of course not found
          console.error(error);
          onTimeout();
        }
      },
      // maxAttempts (seconds)
      5
    );
  }, []);

  return {
    loading,
    error,
    data: mySalesOrderData?.sfFoPaymentMySalesOrder,
    ...apolloUtils,
  };
};

export default useMySalesOrder;
