const Home = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 21V15H14V21H19V13H22L12 4L2 13H5V21H10Z" fill="currentColor" />
    </svg>
  );
};

export default Home;
