import styled, { css } from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { Flex, Text, Box } from '../Base';
import { RadioGroupProps, FormControlLabelProps, ChoiceProps } from './type';

const handleBorderColor = (theme, isError, isSelected) => {
  if (isError) {
    return theme.colors.text.redText;
  }
  if (isSelected) {
    return theme.colors.border.activeChoice;
  }
  return theme.colors.border.uiState.default;
};

const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>`
  ${({ theme, disabled, isError, $isSelected }) => css`
    &.MuiFormControlLabel-root {
      border: 1px solid ${handleBorderColor(theme, isError, $isSelected)};
      border-radius: 8px;
      padding: ${theme.space[2]}px ${theme.space[3]}px;
      transition: border-color 200ms ease-in-out;

      &:hover {
        border-color: ${disabled ? '' : theme.colors.border.activeChoice};
      }
    }
    .MuiTypography-root.MuiFormControlLabel-label {
      margin: 0 ${theme.space[4]}px;
      font-weight: ${theme.fontWeights.light};
      font-family: inherit;

      &.Mui-disabled {
        color: ${theme.colors.text.uiState.disabled};
      }
    }
    &.MuiFormControlLabel-root {
      margin: 0;
    }
  `}
`;

const StyledRadioGroup = styled(RadioGroup)<RadioGroupProps>`
  ${({ theme, $columns }) => css`
    &.MuiFormGroup-root {
      display: grid;
      gap: ${theme.space[3]}px;
      grid-template-columns: 1fr;

      @media screen and (min-width: ${theme.breakpoints.sm}) {
        gap: ${theme.space[3]}px;
        grid-template-columns: ${$columns ? `repeat(${$columns}, 1fr)` : `repeat(2, 1fr)`};
      }
    }
  `}
`;

const StyledRadio = styled(Radio)`
  ${({ theme }) => css`
    &.MuiRadio-root {
      color: ${theme.colors.grayscale[300]};
      &.Mui-checked {
        color: ${theme.colors.primary[500]};
        &:hover {
          background-color: ${theme.colors.primary[100]};
        }
      }
      &:hover {
        background-color: ${theme.colors.primary[100]};
      }
      &.Mui-disabled {
        color: ${theme.colors.text.uiState.disabled};
      }
    }
  `}
`;

const Choice = ({
  title,
  choices,
  name: inputName,
  value: selectedValue,
  disabled = false,
  isError = false,
  columns = 2,
  onChange,
}: ChoiceProps) => {
  return (
    <Box p={1}>
      <FormControl fullWidth disabled={disabled}>
        {title && (
          <Flex flex={1} flexDirection={'column'} mb={3}>
            <Text textStyle="body1">{title}</Text>
          </Flex>
        )}
        <StyledRadioGroup
          $columns={columns}
          value={selectedValue}
          onChange={onChange}
          style={{ padding: 0 }}
        >
          {Array.isArray(choices) &&
            choices.length > 0 &&
            choices.map(({ value, label }, index) => {
              const isSelected = value === selectedValue;
              return (
                <StyledFormControlLabel
                  key={index}
                  $isSelected={isSelected}
                  isError={isError}
                  disabled={disabled}
                  // mui props for FormControlLabel
                  value={value}
                  label={label}
                  labelPlacement={'end' as const}
                  control={<StyledRadio name={inputName} />}
                />
              );
            })}
        </StyledRadioGroup>
      </FormControl>
    </Box>
  );
};

export default Choice;
