import { useMutation } from '@apollo/client';

import { SFFoPaymentGet2C2PRedirectURL } from '../../GraphQL/mutation/Purchase.mutation';

import {
  use2C2PRedirectURLProps,
  handleMutate2C2PRedirectURLVariables,
  MutationOptionsExcludeVariables,
} from './types';

const use2C2PRedirectURL = (mutationOptions?: use2C2PRedirectURLProps) => {
  const [mutate, { data, error, loading }] = useMutation(
    SFFoPaymentGet2C2PRedirectURL,
    mutationOptions
  );

  const handleMutate2C2PRedirectURL = async (
    variables: handleMutate2C2PRedirectURLVariables,
    apolloProps: MutationOptionsExcludeVariables = {}
  ) => {
    const { salesOrderNo } = variables;
    const result = await mutate({
      variables: { input: { salesOrderNo } },
      ...apolloProps,
    });
    return {
      data: result.data?.sfFoPaymentGet2C2PRedirectURL,
      errors: result.errors,
    };
  };

  return {
    loading,
    error,
    data: data?.sfFoPaymentGet2C2PRedirectURL,
    mutate2C2PRedirectURL: handleMutate2C2PRedirectURL,
  };
};

export default use2C2PRedirectURL;
