const ClockMobile = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0469 0.673828H6.04688V2.00716H10.0469V0.673828ZM7.38021 9.34049H8.71354V5.34049H7.38021V9.34049ZM12.7335 4.92716L13.6802 3.98049C13.3935 3.64049 13.0802 3.32049 12.7402 3.04049L11.7935 3.98716C10.7602 3.16049 9.46021 2.66716 8.04688 2.66716C4.73354 2.66716 2.04688 5.35383 2.04688 8.66716C2.04688 11.9805 4.72688 14.6672 8.04688 14.6672C11.3669 14.6672 14.0469 11.9805 14.0469 8.66716C14.0469 7.26049 13.5535 5.96049 12.7335 4.92716ZM8.04688 13.3405C5.46688 13.3405 3.38021 11.2538 3.38021 8.67383C3.38021 6.09383 5.46688 4.00716 8.04688 4.00716C10.6269 4.00716 12.7135 6.09383 12.7135 8.67383C12.7135 11.2538 10.6269 13.3405 8.04688 13.3405Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ClockMobile;
