import LegacyModal from './LegacyModal';
import ModalContentDetail from './ModalContentDetail';
import BaseModal from './BaseModal';
import SimpleModal from './SimpleModal';
import TopStripeModal from './TopStripeModal';
import ModalContent from './ModalContent';
import ModalActionButtons from './ModalActionButtons';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

export {
  LegacyModal,
  BaseModal,
  SimpleModal,
  TopStripeModal,
  ModalContentDetail,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalActionButtons,
};
export default LegacyModal;
