import styled from 'styled-components';

import { Box } from '../../Components/Base';
import { HamburgerProps } from './types';

const HamburgerBox = styled((props) => (
  // TODO: move outside component
  <Box display={{ _: 'inline-block', sm: 'none' }} {...props} />
))`
  cursor: pointer;
`;

const Hamburger = ({ onClick }: HamburgerProps) => (
  <HamburgerBox onClick={onClick}>
    <Box width={20} height={2} bg="icon.uiState.default" m="0 auto 4px" />
    <Box width={20} height={2} bg="icon.uiState.default" m="0 auto 4px" />
    <Box width={20} height={2} bg="icon.uiState.default" m="0 auto" />
  </HamburgerBox>
);

export default Hamburger;
