import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const paymentConfig = window.Skooldio?.paymentConfig ?? {};

const { defaultLanguage } = paymentConfig;

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: defaultLanguage || 'th',
    fallbackLng: 'th',
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
