const CreditBankConnection = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9427 8C17.2227 8.98985 17.7053 9.89462 18.3429 10.6667H2.66667V8L16 1.33333L17.7283 2.19748C17.2661 2.9582 16.9384 3.80968 16.7782 4.71892L16 4.33333L8.6 8H16.9427ZM6.66667 22.6667V13.3333H9.33333V22.6667H6.66667ZM14.6667 22.6667V13.3333H17.3333V22.6667H14.6667ZM2.66667 28V25.3333H29.3333V28H2.66667ZM22.6667 22.6667V13.3333L24 13.3333L25.3333 13.3333V22.6667H22.6667ZM22.6667 1.33333V4.66667H19.3333V7.33333H22.6667V10.6667H25.3333V7.33333H28.6667V4.66667L25.3333 4.66667V1.33333H22.6667Z"
      fill="currentColor"
    />
  </svg>
);

export default CreditBankConnection;
