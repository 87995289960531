import { forwardRef, Ref } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { useTheme } from 'styled-components';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TextInputProps } from './TextInput.d';

const useStyle = (): ClassNameMap<
  'root' | 'input' | 'outlinedInput' | 'outlinedNotched' | 'label' | 'labelShrink' | 'helperText'
> => {
  const theme = useTheme();
  const classes = makeStyles({
    root: {
      width: '100%',
    },
    outlinedNotched: {
      borderColor: theme?.colors?.grayscale['300'],
    },
    input: {
      padding: `${theme.space[2]}px ${theme.space[4]}px`,
      height: '100%',
      boxSizing: 'border-box',
    },
    helperText: {
      //matching with design
      margin: `${theme.space[1]}px ${theme.space[1] / 2}px`,
      '&.Mui-error': {
        color: `${theme?.colors?.system?.danger['700']}`,
      },
    },
    label: {
      //16px as input padding and additional 12px for center label inside input
      transform: 'translate(16px, 12px) scale(1)',
      //label act as placeholder first then as solid label when shrink active
      color: theme?.colors?.grayscale['600'],
      '&.Mui-focused': {
        color: theme?.colors?.grayscale['900'],
      },
    },
    labelShrink: {
      color: theme?.colors?.grayscale['900'],
    },
    outlinedInput: {
      height: '40px',
      color: theme?.colors?.grayscale['900'],
      background: theme.colors.white,
      borderRadius: '8px',
      borderColor: theme?.colors?.grayscale['300'],

      '&.MuiOutlinedInput-multiline': {
        height: 'auto',
      },

      //hover case
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.colors?.primary['300'],
      },

      //active case
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.colors?.primary['300'],
        borderWidth: '2px',
      },

      //error case
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.colors?.system?.danger['700'],
      },

      // disabled case
      '&.Mui-disabled': {
        color: theme?.colors?.grayscale['600'],
        // prevent safari show wrong color in shadow dom ref: https://github.com/mui-org/material-ui/issues/23332
        WebkitTextFillColor: theme?.colors?.grayscale['600'],
        background: theme?.colors?.grayscale['200'],
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.colors?.grayscale['300'],
      },
    },
  })();
  return classes;
};

//TODO: Styled input with theme
const BaseTextInput = (props: TextInputProps, ref: Ref<HTMLElement>): JSX.Element => {
  const classes = useStyle();
  const { InputProps, ...otherProps } = props ?? {};
  return (
    <TextField
      inputRef={ref}
      classes={{ root: classes.root }}
      InputProps={{
        classes: {
          root: classes.outlinedInput,
          input: classes.input,
          notchedOutline: classes.outlinedNotched,
        },
        ...InputProps,
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
      InputLabelProps={{
        classes: {
          outlined: classes.label,
          shrink: classes.labelShrink,
        },
      }}
      {...otherProps}
      variant="outlined"
    />
  );
};

const TextInput = forwardRef(BaseTextInput);

export type { TextInputProps };
export default TextInput;
