import { useController } from 'react-hook-form';

import TextInput from '../../Base/TextInput';
import { FormTextInputProps, FieldValues } from './types';

const FormTextInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormTextInputProps<TFieldValues>
) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    ...props,
    name: props.name,
  });
  return (
    <TextInput
      {...field}
      {...props}
      type={props?.type ?? 'text'}
      error={invalid}
      helperText={error?.message ?? null}
    />
  );
};

export default FormTextInput;
