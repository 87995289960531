import { Text, Box } from '../../Base';
import { LayoutProps, SpaceProps, ColorProps, FlexboxProps } from 'styled-system';

interface ModalHeaderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    LayoutProps,
    SpaceProps,
    ColorProps,
    FlexboxProps {
  color?: string;
  children: React.ReactNode;
}

const ModalHeader = ({ children, ...props }: ModalHeaderProps) => {
  return (
    <Box {...props}>
      {typeof children === 'string' ? (
        <Text textStyle={{ _: 'heading4', md: 'heading3' }} textAlign={'center'}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
};

export default ModalHeader;
