/**
 * แสดงผลการตรวจสอบหมายเลขบัตรประชาชน
 *
 * @param {number} idCardNumber หมายเลขบัตรประชาชน
 * @return ผลการตรวจสอบ "ถูกต้อง" หรือ "ไม่ถูกต้อง"
 * @customfunction
 *
 * Copied from: https://github.com/ChaiyachetU/thai-id-card-check/blob/master/Code.gs
 */

export default function taxIdValidation(idCardNumber: number | string) {
  if (typeof idCardNumber === 'number') {
    idCardNumber = idCardNumber.toString();
  }

  const regex = /^[0-9]\d*$/;
  if (
    idCardNumber.length === 13 &&
    idCardNumber.match(regex) &&
    idCardNumber !== null &&
    idCardNumber !== 'undefined'
  ) {
    // Check valid id card number
    return idCardCheck(idCardNumber);
  } else {
    return 'กรุณากรอกเลขประจำตัวผู้เสียภาษีให้ถูกต้องและครบ 13 หลัก';
  }
}

function idCardCheck(idCardNumber: string) {
  var sum = 0;
  for (var i = 0; i < idCardNumber.length - 1; i++) {
    sum += parseInt(idCardNumber[i]) * (13 - i);
  }

  var checkDigit = (11 - (sum % 11)) % 10;

  if (checkDigit == Number(idCardNumber[12])) {
    return true;
  } else {
    return 'หมายเลขประจำตัวผู้เสียภาษีไม่ถูกต้อง';
  }
}
