import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import numeral from 'numeral';
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation, Trans } from 'react-i18next';
import { Checkbox } from '@skooldio/paas-shared-react-components';

import { Box, Flex, ButtonOutlined, Text, TextInput } from '../Base';
import { PaymentSummaryProps } from './types';

const StyledCheckbox = styled(Checkbox)`
  && {
    &.MuiCheckbox-colorSecondary {
      color: ${({ theme }) => theme.colors.grayscale[400]};
    }
  }
`;

const StyledFormControlLabel = styled(MUIFormControlLabel)`
  && {
    margin: 0;
    align-items: start;
    margin-top: 20px;
  }

  .MuiFormControlLabel-label {
    margin-left: 8px;
  }
`;

const DiscountButton = styled(ButtonOutlined)`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.primary[500]};
    color: ${theme.colors.primary[500]};
    background-color: white;
    width: 100px;
    height: 40px;
    padding: 6px;
  `}
`;

const PaymentSummaryDetails = ({
  totalPrice,
  currentDiscountCode,
  isInvalidDiscountCode,
  isDiscountCodeValidated,
  paymentAmount,
  discountPrice,
  unit,
  withholdingTax,
  productQuantity,
  onDiscountCodeChange,
  onDiscountCodeSubmit,
  onDiscountCodeCancel,
  discountRemark,
  setIsAcceptedOfDiscountPolicy,
}: PaymentSummaryProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!currentDiscountCode) return;
    onDiscountCodeSubmit?.(currentDiscountCode);
  }, []);

  useEffect(() => {
    const handleSubmitDiscountCode = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return;

      e.preventDefault();
      onDiscountCodeSubmit?.(currentDiscountCode);
    };
    document
      .getElementById('discountCodeInput')
      ?.addEventListener('keypress', handleSubmitDiscountCode);
    return () =>
      document
        .getElementById('discountCodeInput')
        ?.removeEventListener('keypress', handleSubmitDiscountCode);
  }, [currentDiscountCode, onDiscountCodeSubmit]);

  return (
    <>
      <Flex fontWeight="400" justifyContent="space-between" alignItems="center" mb="8px">
        <span>{t('purchase_summary_total')}</span>
        <p>
          <Text>
            <Trans
              i18nKey={
                unit === 'THB'
                  ? 'price_with_currency_with_custom_style_thb'
                  : 'price_with_currency_with_custom_style_usd'
              }
              values={{ price: numeral(totalPrice).format('0,0.00') }}
              components={{ 1: <span style={{ marginLeft: '10px' }} /> }}
            />
          </Text>
        </p>
      </Flex>

      {typeof productQuantity === 'number' && (
        <Flex fontWeight="400" justifyContent="space-between" alignItems="center" mb="8px">
          <span>{t('purchase_summary_quantity')}</span>
          <p>
            <Text as="span">{productQuantity}</Text>
            <Text as="span" ml="10px">
              {t('purchase_summary_person')}
            </Text>
          </p>
        </Flex>
      )}

      {typeof discountPrice === 'number' && discountPrice > 0 && (
        <Flex fontWeight="400" justifyContent="space-between" alignItems="center" mb="8px">
          <span>{t('purchase_summary_discount')}</span>
          <p>
            <Text color="#EB5757">
              -
              <Trans
                i18nKey={
                  unit === 'THB'
                    ? 'price_with_currency_with_custom_style_thb'
                    : 'price_with_currency_with_custom_style_usd'
                }
                values={{ price: numeral(discountPrice).format('0,0.00') }}
                components={{ 1: <span style={{ marginLeft: '10px' }} /> }}
              />
            </Text>
          </p>
        </Flex>
      )}

      {typeof withholdingTax === 'number' && withholdingTax > 0 && (
        <Flex fontWeight="400" justifyContent="space-between" alignItems="center" mb="8px">
          <span>{t('purchase_summary_3_percent_withholding_tax')}</span>
          <p>
            <Text color="#EB5757">
              <Trans
                i18nKey={
                  unit === 'THB'
                    ? 'price_with_currency_with_custom_style_thb'
                    : 'price_with_currency_with_custom_style_usd'
                }
                values={{ price: numeral(withholdingTax).format('0,0.00') }}
                components={{ 1: <span style={{ marginLeft: '10px' }} /> }}
              />
            </Text>
          </p>
        </Flex>
      )}

      <Flex fontWeight="600" justifyContent="space-between" alignItems="center" mb="8px">
        <Text textStyle="body1Medium">{t('purchase_summary_final_price')}</Text>
        <p>
          <Text textStyle="subtitle1">
            <Trans
              i18nKey={
                unit === 'THB'
                  ? 'price_with_currency_with_custom_style_thb'
                  : 'price_with_currency_with_custom_style_usd'
              }
              values={{ price: numeral(paymentAmount).format('0,0.00') }}
              components={{ 1: <span style={{ marginLeft: '6px' }} /> }}
            />
          </Text>
        </p>
      </Flex>
      <Text
        as="span"
        fontSize="12px"
        fontWeight="400"
        letterSpacing="0.25px"
        lineHeight="16px"
        color="hsla(210, 51%, 16%, 0.6)"
      >
        {t('purchase_summary_price_included_7_percent_vat')}
      </Text>
      <Flex justifyContent="space-between" pt="18px">
        <Box flex="1 1 auto">
          <TextInput
            id="discountCodeInput"
            fullWidth
            size={'small'}
            label={t('purchase_summary_discount_code')}
            value={currentDiscountCode}
            onChange={onDiscountCodeChange}
            disabled={isDiscountCodeValidated}
            error={isInvalidDiscountCode}
            helperText={
              isInvalidDiscountCode ? t('purchase_summary_discount_code_invalid') : undefined
            }
          />
        </Box>
        <Box pl={4}>
          {isDiscountCodeValidated ? (
            <DiscountButton type="button" onClick={onDiscountCodeCancel}>
              <Text textStyle="body1Medium">{t('purchase_summary_discount_cancel')}</Text>
            </DiscountButton>
          ) : (
            <DiscountButton
              type="button"
              disabled={!currentDiscountCode}
              onClick={() => onDiscountCodeSubmit?.(currentDiscountCode)}
            >
              <Text textStyle="body1Medium">{t('purchase_summary_discount_code_apply')}</Text>
            </DiscountButton>
          )}
        </Box>
      </Flex>
      {!!discountRemark ? (
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                  setIsAcceptedOfDiscountPolicy(true);
                  return;
                }

                setIsAcceptedOfDiscountPolicy(false);
              }}
            />
          }
          label={
            <Text textStyle="caption" color="system.danger.500" fontWeight="600">
              {discountRemark}
            </Text>
          }
        />
      ) : null}
    </>
  );
};

export default PaymentSummaryDetails;
