import { useEffect } from 'react';

type LoadScriptProps = { id: string; src: string; onLoad: () => void; onError: () => void };

const useLoadScript = ({ id, src, onLoad, onError }: LoadScriptProps) => {
  useEffect(() => {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.onload = onLoad;
      script.onerror = onError;
      document.body.appendChild(script);
    }
    if (existingScript && onLoad) onLoad();
  }, [id, src, onLoad, onError]);
};

export default useLoadScript;
