const PlayCircle = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z
        M8.2 8.133a1.467 1.467 0 012.18-1.282l6.96 3.867a1.467 1.467 0 010 2.564l-6.96 3.867a1.467 1.467 0 01-2.18-1.282V8.133z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PlayCircle;
