import styled, { useTheme } from 'styled-components';

import { Text, Box, Flex, Grid } from '../Base';
import FooterMainContent from './FooterMainContent';
import SocialMedia from './SocialMedia';
import FooterNavigation from './FooterNavigation';
import SVGIcon, { ICON_LIST } from '../SVGIcon';
import { SocialIconDetail, FooterMenuItemProps } from './types';
import { ReactNode } from 'react';

const CopyrightText = styled((props) => (
  <Text color="text.darkText" display="inline-block" {...props} />
))`
  opacity: 0.5;
`;

const Footer = ({
  title,
  subTitle,
  socialChannel,
  footerMenuList,
  showBadge,
  children,
}: {
  title?: string;
  subTitle?: string;
  socialChannel?: SocialIconDetail[];
  footerMenuList?: FooterMenuItemProps[];
  showBadge?: boolean;
  children?: ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  const year = new Date().getFullYear();
  const showMainContent = title.length > 0 || subTitle.length > 0;

  return (
    <Box as="footer" mt="auto" py={{ _: 7 }} px={{ _: 4 }}>
      {children}
      <Box maxWidth={{ _: 'none', md: 1200 }} m="0 auto" width={1} px={{ _: 0, md: 4 }}>
        <Grid
          gridTemplateColumns={{ _: 'auto', lg: 'max(500px) minmax(100px, auto)' }}
          alignItems="flex-start"
          justifyContent="space-between"
          mb={{ _: 6, md: 7, lg: 8 }}
          gridRowGap={{ _: 5, lg: 0 }}
        >
          {showMainContent && <FooterMainContent title={title} content={subTitle} />}
          <SocialMedia socialChannel={socialChannel} />
        </Grid>
        <FooterNavigation footerMenuList={footerMenuList} />
        <Flex
          flexDirection={{ _: 'column', sm: 'row ' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <CopyrightText textStyle="caption" textAlign={{ _: 'center', sm: 'left' }}>
            Copyright &copy; {`${year} All rights reserved for all contents.`}
          </CopyrightText>
          {showBadge && (
            <Flex
              my={{ _: 4, sm: 0 }}
              alignItems="center"
              justifyContent="space-between"
              as="a"
              href="https://skooldio.com/"
              target="blank"
            >
              <>
                <CopyrightText mr={2} textStyle="caption">
                  Powered By
                </CopyrightText>
                <SVGIcon
                  name={ICON_LIST.SKOOLDIO_LOGO}
                  width={100}
                  height={32}
                  color={theme.colors.text.darkText}
                />
              </>
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
