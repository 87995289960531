const GreenLineApp = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.593 24H5.407A5.41 5.41 0 0 1 0 18.593V5.407A5.41 5.41 0 0 1 5.407 0h13.178A5.411 5.411 0 0 1 24 5.407v13.178A5.411 5.411 0 0 1 18.593 24Z"
        fill="#06C755"
      />
      <path
        d="M20.003 10.867c0-3.577-3.593-6.495-8.002-6.495-4.41 0-8.003 2.91-8.003 6.495 0 3.21 2.85 5.895 6.69 6.405.262.06.615.173.705.398.082.202.052.517.022.72 0 0-.097.562-.112.682-.038.203-.158.788.69.435.855-.36 4.605-2.715 6.285-4.642 1.163-1.275 1.725-2.565 1.725-3.998Z"
        fill="#fff"
      />
      <path
        d="M17.34 12.937h-2.243a.15.15 0 0 1-.15-.15V9.299a.15.15 0 0 1 .15-.15h2.243a.15.15 0 0 1 .15.15v.57a.15.15 0 0 1-.15.15h-1.53v.593h1.53a.15.15 0 0 1 .15.15v.57a.15.15 0 0 1-.15.15h-1.53v.592h1.53a.15.15 0 0 1 .15.15v.57c0 .075-.068.143-.15.143ZM9.023 12.937a.15.15 0 0 0 .15-.15v-.57a.15.15 0 0 0-.15-.15h-1.53V9.292a.15.15 0 0 0-.15-.15h-.57a.15.15 0 0 0-.15.15v3.487c0 .083.068.15.15.15h2.25v.008ZM10.372 9.142h-.57a.15.15 0 0 0-.15.15v3.495c0 .082.068.15.15.15h.57a.15.15 0 0 0 .15-.15V9.292c.008-.083-.06-.15-.15-.15ZM14.235 9.142h-.57a.15.15 0 0 0-.15.15v2.077l-1.597-2.16c0-.007-.008-.007-.015-.015l-.008-.007-.007-.008h-.008l-.007-.007h-.008s-.007 0-.007-.008h-.008s-.007 0-.007-.007h-.623a.15.15 0 0 0-.15.15v3.495c0 .082.068.15.15.15h.57a.15.15 0 0 0 .15-.15v-2.07l1.598 2.16a.09.09 0 0 0 .037.037l.008.008h.037c.015 0 .023.007.038.007h.562a.15.15 0 0 0 .15-.15V9.3c.023-.09-.045-.157-.135-.157Z"
        fill="#06C755"
      />
    </svg>
  );
};

export default GreenLineApp;
