import React from 'react';
import { useTheme } from 'styled-components';
import { Dialog, DialogProps } from '@material-ui/core';

export interface BaseModalProps extends Omit<DialogProps, 'onClose'> {
  children: React.ReactNode;
  onClose?: () => void;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
}

/**
 * A primitive layer for modal implementation.
 * Please do not modify this if you're unsure of effects it shall cause.
 * Using material ui library.
 */
const BaseModal = ({
  open,
  children,
  onClose,
  disableBackdropClick,
  disableEscapeKeyDown,
  ...props
}: BaseModalProps) => {
  const theme = useTheme();

  const handleClose = (e: object, reason: string) => {
    if (
      (reason === 'backdropClick' && disableBackdropClick) ||
      (reason === 'escapeKeyDown' && disableEscapeKeyDown)
    ) {
      return;
    }
    onClose?.();
  };

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: theme.radii.medium },
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
      open={open}
      onClose={handleClose}
      {...props}
    >
      {children}
    </Dialog>
  );
};

export default BaseModal;
