import styled from 'styled-components';
import { Grid } from '../Base';

const DotLoaderWrapper = styled((props) => (
  <Grid
    gridColumnGap="5px"
    gridTemplateColumns="repeat(4, 10px)"
    height={50}
    width={50}
    {...props}
  />
))`
  span {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.colors.primary['500']};
    border-radius: 50%;
    animation: dots1 1s infinite ease-in-out;
  }

  span:nth-child(1) {
    animation-delay: 0.2s;
  }

  span:nth-child(2) {
    animation-delay: 0.3s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }

  span:nth-child(4) {
    animation-delay: 0.5s;
  }

  @keyframes dots1 {
    0% {
      transform: translateY(0px);
      background: ${({ theme }) => theme.colors.primary['500']};
    }
    50% {
      transform: translateY(10px);
      background: ${({ theme }) => theme.colors.primary['400']};
    }
    100% {
      transform: translateY(0px);
      background: ${({ theme }) => theme.colors.primary['300']};
    }
  }
`;

const DotLoader = (): JSX.Element => {
  return (
    <DotLoaderWrapper>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </DotLoaderWrapper>
  );
};

export default DotLoader;
