import { css } from 'styled-components';

const LineEllipsis = (lineLimit: number, shouldAddWebkit?: boolean) => css`
  && {
    text-overflow: ellipsis;
    overflow: hidden;
    display: ${shouldAddWebkit ? '-webkit-box' : 'block'};
    -webkit-line-clamp: ${lineLimit};
    -webkit-box-orient: vertical;
  }
`;

export { LineEllipsis };
