const PersonTalk = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 8.667a2.666 2.666 0 1 0 0-5.334 2.666 2.666 0 1 0 0 5.334Zm0-4c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.334 1.333c-.733 0-1.333-.6-1.333-1.333s.6-1.333 1.333-1.333ZM6 10c-1.78 0-5.334.893-5.334 2.667V14h10.667v-1.333c0-1.774-3.554-2.667-5.334-2.667Zm-4 2.667c.146-.48 2.206-1.334 4-1.334 1.8 0 3.866.86 4 1.334H2ZM10.052 4.7c.56.787.56 1.807 0 2.593l1.12 1.127c1.346-1.347 1.346-3.38 0-4.847L10.053 4.7Zm3.326-3.367L12.293 2.42c1.846 2.013 1.846 5.04 0 7.16l1.086 1.087c2.6-2.594 2.607-6.634 0-9.334Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PersonTalk;
