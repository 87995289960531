import SVGIcon from '../SVGIcon';

const APPLICATION_STORE_BADGE_SVG_NAME = {
  MAC: SVGIcon.ICON_LIST.DOWNLOAD_MAC_OS_APP,
  WINDOWS: SVGIcon.ICON_LIST.DOWNLOAD_WINDOWS_OS_APP,
  IOS: SVGIcon.ICON_LIST.DOWNLOAD_IOS_APP,
  ANDROID: SVGIcon.ICON_LIST.DOWNLOAD_ANDROID_OS_APP,
};

export { APPLICATION_STORE_BADGE_SVG_NAME };
