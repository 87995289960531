import { Avatar, Tooltip, makeStyles } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { WorkshopCardInstructorData } from './types';
import { Flex, Text } from '../../Base';

interface WorkshopInstructorProps {
  instructorData: WorkshopCardInstructorData[];
}

const useStyle = (): ClassNameMap<'avatarGroup'> => {
  const classes = makeStyles({
    avatarGroup: {
      '& .MuiAvatar-root': {
        fontSize: 14,
        '&:last-child': {
          width: 32,
          height: 32,
        },
      },
    },
  })();
  return classes;
};

const SingleInstructor = ({ instructorData }: WorkshopInstructorProps) => {
  const { displayName, profileImageUrl } = instructorData[0] ?? {};

  return (
    <Flex alignItems="center">
      <Avatar
        src={profileImageUrl}
        alt={displayName}
        style={{
          width: 32,
          height: 32,
        }}
      />
      {!!displayName && (
        <Text textOverflow="ellipsis" ml={2} textStyle="caption" color="text.primary" as="span">
          {displayName}
        </Text>
      )}
    </Flex>
  );
};

const MultipleInstructors = ({ instructorData }: WorkshopInstructorProps) => {
  const classes = useStyle();

  return (
    <Flex alignItems="center" flexWrap="wrap" className={classes.avatarGroup}>
      <AvatarGroup max={5}>
        {instructorData.map(({ displayName, profileImageUrl }, index) => (
          <Tooltip key={index} title={displayName} placement="top" arrow>
            <Avatar
              src={profileImageUrl}
              alt={displayName}
              style={{
                width: 32,
                height: 32,
              }}
            />
          </Tooltip>
        ))}
      </AvatarGroup>
    </Flex>
  );
};

const WorkshopInstructor = ({ instructorData }: WorkshopInstructorProps): JSX.Element => {
  if (!instructorData) {
    return null;
  }

  return instructorData.length === 1 ? (
    <SingleInstructor instructorData={instructorData} />
  ) : (
    <MultipleInstructors instructorData={instructorData} />
  );
};

export { WorkshopInstructor };
