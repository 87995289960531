import styled, { css } from 'styled-components';

import Text from './Base/Text';

export interface SectionTitleProps {
  title?: string;
}

const SectionTitleText = styled((props) => (
  <Text textStyle="heading4" pl={4} mb={5} color="text.darkText" as="h2" {...props} />
))`
  ${({ theme }) =>
    css`
      border-left: 4px solid ${theme.colors.primary[500]};
    `}
`;

const SectionTitle = (props: SectionTitleProps): JSX.Element => {
  const { title = 'รายการสินค้า' } = props;
  return <SectionTitleText>{title}</SectionTitleText>;
};

export default SectionTitle;
