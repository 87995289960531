export enum SALES_ORDER_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  PAID = 'PAID',
  VOID = 'VOID',
}

// from enum SFFoShipmentProvider storefront gateway
export enum SHIPMENT_PROVIDER {
  FLASH_EXPRESS = 'FLASH_EXPRESS',
  KERRY_EXPRESS = 'KERRY',
  THAILAND_POST_EMS = 'TP_EMS',
  THAILAND_POST_REGISTED_MAIL = 'TP_REG',
  BEST_EXPRESS = 'BEST',
  DHL = 'DHL',
  ALPHA = 'ALP',
  NINJA_VAN = 'NINJA',
  J_AND_T = 'JT',
  SCG_EXPRESS = 'SCG',
  NIM_EXPRESS = 'NIM',
  OTHER = 'OTHER',
  OFFICIAL = 'OFFICIAL',
}

export enum PAYMENT_METHOD {
  QR_CODE = 'QRCODE',
  ATM = 'ATM',
  CREDIT_CARD = 'CC',
  ALL = 'ALL',
  IN_APP_PURCHASE_IOS = 'IN_APP_PURCHASE_IOS',
  IN_APP_PURCHASE_ANDROID = 'IN_APP_PURCHASE_ANDROID',
  COUNTER_SERVICE = 'COUNTER_SERVICE',
}

export enum SHIPMENT_METHOD {
  ONLINE = 'ONLINE',
  DELIVER_BY_SUPPLIER = 'DELIVER_BY_SUPPLIER',
}

export enum SHIPMENT_STATUS {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
  VOIDED = 'VOIDED',
  RETURN = 'RETURN',
}

export enum CUSTOMER_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATE = 'CORPORATE',
}