import { useState } from 'react';
import { useMutation } from '@apollo/client';
import isUrl from 'is-url';
import { useNavigate } from 'react-router-dom';

import { placeOrderWithOfflineTransaction } from '../GraphQL/mutation/Product.mutation';
import use2C2PRedirectURL from './use2C2PRedirectURL';

import {
  PAYMENT_TASK,
  SFFoPlaceOrderWithOfflineTransactionInputProps,
  PlaceOrderWithOfflineTransactionMutation,
  PlaceOrderWithOfflineTransactionMutationOptionsProps,
  PURCHASE_ORDER_ERROR,
} from './usePurchaseOrder.d';

export const PURCHASE_ERROR_MESSAGE = {
  ONLINE_COURSE:
    'Unable to place an online course because it may already existed or the product constraint is not met',
  BUNDLE:
    'Unable to place a bundle because it may already existed or the product constraint is not met',
};

const getPurchaseOrderError = (errorMessage?: string) => {
  switch (errorMessage) {
    case PURCHASE_ERROR_MESSAGE.ONLINE_COURSE:
    case PURCHASE_ERROR_MESSAGE.BUNDLE:
      return PURCHASE_ORDER_ERROR.DUPLICATED_ONLINE_COURSE;
    default:
      return PURCHASE_ORDER_ERROR.UNEXPECTED;
  }
};

const usePurchaseOrder = (
  mutationOptions?: PlaceOrderWithOfflineTransactionMutationOptionsProps
) => {
  const navigate = useNavigate();
  const [isCoursePurchasing, setIsCoursePurchasing] = useState<boolean>(false);
  const [isPurchaseOrderFailed, setIsPurchaseOrderFailed] = useState<boolean>(false);
  const [purchaseOrderFailedMessage, setPurchaseOrderFailedMessage] =
    useState<PURCHASE_ORDER_ERROR>();
  const { mutate2C2PRedirectURL } = use2C2PRedirectURL();
  const [purchaseCourse, { data, error, loading, ...apolloUtils }] = useMutation<
    PlaceOrderWithOfflineTransactionMutation,
    { input: SFFoPlaceOrderWithOfflineTransactionInputProps }
  >(placeOrderWithOfflineTransaction, mutationOptions);

  const purchaseOrder = async ({
    orderDetail,
    apolloProps,
    onPurchaseSuccess,
  }: {
    orderDetail: SFFoPlaceOrderWithOfflineTransactionInputProps;
    apolloProps?: any;
    onPurchaseSuccess?: () => void;
  }) => {
    const {
      items,
      customerType,
      paymentMethod,
      customerFullName,
      customerAddress,
      customerMobile,
      customerEmail,
      discountCode,
      resultUrl,
      shipment,
      companyInfo,
      customerInfo,
      taxInvoiceRequested,
    } = orderDetail;

    setIsCoursePurchasing(true);
    const { data: purchasingData } = await purchaseCourse({
      variables: {
        input: {
          items,
          customerType,
          paymentVersion: 'V2',
          paymentMethod,
          customerFullName,
          customerAddress,
          customerMobile,
          customerEmail,
          discountCode,
          resultUrl,
          shipment,
          companyInfo,
          customerInfo,
          taxInvoiceRequested,
        },
      },
      ...apolloProps,
    });
    const {
      task,
      salesOrder,
      isSuccess,
      error: purchaseOrderError,
    } = purchasingData?.sfFoPlaceOrderWithOfflineTransaction ?? {};
    if (isSuccess) {
      if (onPurchaseSuccess) {
        onPurchaseSuccess();
      }
      if (task === PAYMENT_TASK.GET_REDIRECT_URL && salesOrder?.orderNo) {
        const { data } = await mutate2C2PRedirectURL({ salesOrderNo: salesOrder.orderNo });
        const responseUrl = data?.redirectUrl;
        if (typeof responseUrl === 'string' && isUrl(responseUrl)) {
          window.location.href = responseUrl;
        }
      }
      if (task === PAYMENT_TASK.NONE && salesOrder?.orderNo) {
        navigate(`/orders/${salesOrder?.orderNo}/thank-you`);
      }
    } else {
      const purchaseError = getPurchaseOrderError(purchaseOrderError);
      setPurchaseOrderFailedMessage(purchaseError);
      setIsPurchaseOrderFailed(true);
      setIsCoursePurchasing(false);
    }
  };

  const clearPurchaseOrderStatus = () => {
    setIsPurchaseOrderFailed(false);
  };

  return {
    loading,
    error,
    data,
    isCoursePurchasing,
    setIsPurchaseOrderFailed,
    isPurchaseOrderFailed,
    purchaseOrderFailedMessage,
    clearPurchaseOrderStatus,
    purchaseOrder,
    ...apolloUtils,
  };
};

export default usePurchaseOrder;
