import { ICON_LIST } from '@skooldio/paas-shared-react-components';

export enum APP_MENU {
  HOME,
  COURSE,
  PROMOTION,
  HOW_TO_USE,
}

export interface MenuItem {
  label: string;
  icon: ICON_LIST;
  link: string;
}
