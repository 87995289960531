import { forwardRef, Ref } from 'react';
import TextInput from '../Base/TextInput';
import ReactTextMasked from 'react-text-mask';
import { TextMaskedInputProps, MaskedInputProps } from './TextMaskedInput.d';

const MaskedInput = (props: MaskedInputProps) => {
  const inputRef = props?.inputRef;
  return (
    <ReactTextMasked
      {...props}
      ref={(ref: { inputElement: Ref<HTMLInputElement> }) => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
    />
  );
};

const BaseMaskedInput = (props: TextMaskedInputProps, ref: Ref<HTMLElement>): JSX.Element => {
  const { mask, guide = false, showMask = false, ...otherTextInputProps } = props;
  const maskedInputProps = {
    mask,
    guide,
    showMask,
  };
  return (
    <TextInput
      ref={ref}
      {...otherTextInputProps}
      InputProps={{
        inputComponent: MaskedInput,
        inputProps: maskedInputProps,
      }}
    />
  );
};

const TextMaskedInput = forwardRef(BaseMaskedInput);

export type { TextMaskedInputProps };
export default TextMaskedInput;
