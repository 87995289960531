const Calendar = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.4 14.6664C3.0112 14.6664 2.68053 14.5304 2.408 14.2584C2.136 13.9859 2 13.6552 2 13.2664V4.1336C2 3.7448 2.136 3.41413 2.408 3.1416C2.68053 2.8696 3.0112 2.7336 3.4 2.7336H3.9336V2.0168C3.9336 1.828 4.00027 1.66693 4.1336 1.5336C4.26693 1.40027 4.4336 1.3336 4.6336 1.3336C4.8224 1.3336 4.98613 1.40293 5.1248 1.5416C5.264 1.6808 5.3336 1.8448 5.3336 2.0336V2.7336H10.6664V2.0168C10.6664 1.828 10.7331 1.66693 10.8664 1.5336C10.9997 1.40027 11.1664 1.3336 11.3664 1.3336C11.5552 1.3336 11.7192 1.40293 11.8584 1.5416C11.9971 1.6808 12.0664 1.8448 12.0664 2.0336V2.7336H12.6C12.9888 2.7336 13.3195 2.8696 13.592 3.1416C13.864 3.41413 14 3.7448 14 4.1336V13.2664C14 13.6552 13.864 13.9859 13.592 14.2584C13.3195 14.5304 12.9888 14.6664 12.6 14.6664H3.4ZM3.4 13.2664H12.6V6.7H3.4V13.2664ZM3.4 5.3H12.6V4.1336H3.4V5.3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Calendar;
