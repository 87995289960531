import { SidebarLayout } from '@skooldio/paas-shared-react-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { setPageTitle } from '../Utils/page';
import { PurchaseHistoryPagingProvider } from '../Contexts/PurchaseHistoryPagingProvider';
import { AccountMenuProvider } from '../Contexts/AccountMenuContext';
import PurchaseHistory from '../Containers/PurchaseHistory';
import SideMenu from '../Containers/SideMenu';
import { Box } from '../Components/Base';

const Purchase = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{setPageTitle(t('purchase_history_title'))}</title>
      </Helmet>
      <SidebarLayout>
        <AccountMenuProvider>
          <Box display={{ _: 'none', md: 'block' }}>
            <SideMenu />
          </Box>
        </AccountMenuProvider>
        <PurchaseHistoryPagingProvider>
          <PurchaseHistory />
        </PurchaseHistoryPagingProvider>
      </SidebarLayout>
    </>
  );
};

export default Purchase;
