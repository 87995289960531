import styled from 'styled-components';

import { Text, Box, Grid } from '../Base';
import Link from '../Base/Link';

import { FooterNavigationProps } from './types';

const MenuItem = styled(Link)`
  color: ${({ theme }) => theme.colors.text.darkText};
`;

const FooterNavigation = ({ footerMenuList = [] }: FooterNavigationProps): JSX.Element => {
  return (
    <Grid
      alignItems="center"
      mb={{ _: 7, md: 5 }}
      gridRowGap={{ _: 2, md: 0 }}
      gridColumnGap={{ _: 1, sm: 0 }}
      gridTemplateColumns={{
        _: 'repeat(1, auto)',
        sm: 'repeat(auto-fit, minmax(50%, max-content))',
        md: 'repeat(auto-fit, minmax(100px, max-content))',
      }}
    >
      {footerMenuList.map(({ menuTitle, permalink }) => (
        <Box key={menuTitle} mr={{ _: 0, md: 7 }} display={{ _: 'inline-flex', md: 'block' }}>
          <MenuItem href={permalink} passHref target="_blank">
            <Text textStyle={{ _: 'body2', lg: 'body1Medium' }} fontWeight="semibold">
              {menuTitle}
            </Text>
          </MenuItem>
        </Box>
      ))}
    </Grid>
  );
};

export default FooterNavigation;
