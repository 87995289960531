import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import SectionTitle from '../SectionTitle';

import PaymentMethodOption from './PaymentMethodOption';
import { PaymentMethodProps, PaymentMethodOptionProps } from './PaymentMethod.d';

interface StyledRadioGroupProps extends RadioGroupProps {
  $isInValid: boolean;
}

const useStyles = (): ClassNameMap<'mainRadioWrapper' | 'optionGroupWrapper'> => {
  const classes = makeStyles({
    mainRadioWrapper: {
      width: '100%',
    },
    optionGroupWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      rowGap: '12px',
    },
  })();

  return classes;
};

const StyledRatioGroup = styled(RadioGroup)<StyledRadioGroupProps>`
  ${({ theme, $isInValid }) => css`
    ${$isInValid &&
    css`
      .MuiIconButton-label {
        color: ${theme.colors.system.danger['700']};
      }
    `}
  `}
`;

const PaymentMethod = ({
  inputProps = {},
  selectedMethod,
  paymentMethodOptions,
}: PaymentMethodProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isInValid = false, ...restInputProps } = inputProps;

  return (
    <section>
      <SectionTitle title={t('purchase_payment_method')} />
      <FormControl classes={{ root: classes.mainRadioWrapper }}>
        <StyledRatioGroup
          aria-label="payment-method"
          classes={{ root: classes.optionGroupWrapper }}
          $isInValid={isInValid}
          {...restInputProps}
        >
          {paymentMethodOptions?.map(
            ({ method, text, imageList }: PaymentMethodOptionProps): JSX.Element => {
              return (
                <PaymentMethodOption
                  key={method}
                  text={text}
                  method={method}
                  imageList={imageList}
                  isSelected={selectedMethod === method}
                />
              );
            }
          )}
        </StyledRatioGroup>
      </FormControl>
    </section>
  );
};

export default PaymentMethod;
