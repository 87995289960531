const CheckIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" {...props}>
      <path
        d="M12.5 39L27.6716 54L60.5 18"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
