import BaseModal from '../BaseModal';
import { Box } from '../../Base';

import { BaseModalProps } from '../BaseModal/BaseModal';

/**
 * Simple modal component.
 * To be a general modal according to the design system.
 * From the design system, Modal width should be fixed to 480px.
 */
const SimpleModal = ({ open, children, ...props }: BaseModalProps) => {
  return (
    <BaseModal open={open} {...props}>
      <Box width="480px" maxWidth="100%" px={{ _: 4, sm: 7 }} py={7}>
        {children}
      </Box>
    </BaseModal>
  );
};

export default SimpleModal;
