import usePrivateRoute from '../Domain/usePrivateRoute';

const PrivateRoute = ({ children }: { children: JSX.Element }): JSX.Element | null => {
  const isRouteBlocked = usePrivateRoute();
  if (isRouteBlocked) return null; //do ui on validating here

  return children;
};

export default PrivateRoute;
