import { ProductCategoriesProps } from '../PaymentProvider.d';

const isVirtualWorkshop = (categories: ProductCategoriesProps[]): boolean => {
  const category2Index = categories?.findIndex(
    (category) => category.parentPermalink === 'category2'
  );
  const workshopTypes = categories?.[category2Index]?.categories?.map(
    (category) => category.permalink
  );

  return workshopTypes?.includes('live');
};

export default isVirtualWorkshop;
