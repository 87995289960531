import { useConfig } from '../../Contexts/ConfigContext';
import {
  SocialMediaList,
  DEFAULT_SOCIAL_MEDIA_CHANNELS,
} from '@skooldio/paas-shared-react-components';

const useSocialMedia = (): SocialMediaList => {
  const { socialMedia } = useConfig();
  const socialMediaChannels: SocialMediaList = [];
  if (socialMedia?.facebookURL) {
    socialMediaChannels.push({
      ...DEFAULT_SOCIAL_MEDIA_CHANNELS.facebookChannel,
      socialMediaUrl: socialMedia?.facebookURL,
    });
  }

  if (socialMedia?.twitterURL) {
    socialMediaChannels.push({
      ...DEFAULT_SOCIAL_MEDIA_CHANNELS.twitterChannel,
      socialMediaUrl: socialMedia?.twitterURL,
    });
  }

  if (socialMedia?.youtubeURL) {
    socialMediaChannels.push({
      ...DEFAULT_SOCIAL_MEDIA_CHANNELS.youtubeChannel,
      socialMediaUrl: socialMedia?.youtubeURL,
    });
  }

  if (socialMedia?.tiktokURL) {
    socialMediaChannels.push({
      ...DEFAULT_SOCIAL_MEDIA_CHANNELS.tiktokChannel,
      socialMediaUrl: socialMedia?.tiktokURL,
    });
  }

  if (socialMedia?.instagramURL) {
    socialMediaChannels.push({
      ...DEFAULT_SOCIAL_MEDIA_CHANNELS.instagramChannel,
      socialMediaUrl: socialMedia?.instagramURL,
    });
  }

  if (socialMedia?.lineURL) {
    socialMediaChannels.push({
      ...DEFAULT_SOCIAL_MEDIA_CHANNELS.lineChannel,
      socialMediaUrl: socialMedia?.lineURL,
    });
  }
  return socialMediaChannels;
};

export default useSocialMedia;
