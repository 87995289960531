import MenuItem from '../MenuItem';

import { MenuListProps } from './types';

const MenuList = ({ menus, menuActiveLabel, onClickMenuItem }: MenuListProps): JSX.Element => {
  return (
    <>
      {menus?.map((menu) => (
        <MenuItem
          key={menu.label}
          {...menu}
          isActive={menuActiveLabel === menu.label}
          onClick={() => {
            menu.onClick();
            onClickMenuItem && onClickMenuItem();
          }}
        />
      ))}
    </>
  );
};

export default MenuList;
