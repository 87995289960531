const User = (props: any): JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="19" stroke="currentColor" strokeWidth="2" />
      <circle cx="12.5007" cy="17.9167" r="1.66667" fill="currentColor" />
      <circle cx="26.6667" cy="17.9167" r="1.66667" fill="currentColor" />
      <path
        d="M10 12.5C10 12.5 11.2796 11.25 12.5 11.25C13.7204 11.25 15 12.5 15 12.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.75 12.5C23.75 12.5 25.0296 11.25 26.25 11.25C27.4704 11.25 28.75 12.5 28.75 12.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default User;
