export type Replacement = { pattern: string; label: string; url: string };

export function replacePatternsWithLinks(text: string, replacements: Replacement[]) {
  replacements.forEach((replacement) => {
    const { pattern, label, url } = replacement;
    const regex = new RegExp(pattern, 'gi');
    text = text.replace(regex, `<a href="${url}" target="_blank">${label}</a>`);
  });
  return text;
}
