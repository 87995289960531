import styled from 'styled-components';

import { Flex, Text } from '../Base';

interface DividerWithTextProps {
  text: string;
  textStyle?: string;
  [key: string]: any;
}

const Divider = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.formDivider};
  }
  &::before {
    margin-right: 10px;
  }
  &::after {
    margin-left: 10px;
  }
`;

const DividerWithText = ({ text, textStyle = 'caption', ...props }: DividerWithTextProps) => (
  <Divider {...props}>
    <Text textStyle={textStyle} color={'text.darkTextAlt'}>
      {text}
    </Text>
  </Divider>
);

export default DividerWithText;
