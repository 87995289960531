import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, ButtonFilled, Text } from '../Base';
import PaymentSummaryDetails from './PaymentSummaryDetails';
import FreeCoursePaymentSummaryDetails from './FreeCoursePaymentSummaryDetails';
import PaymentPolicyAcceptanceText from './PaymentPolicyAcceptanceText';
import { PaymentSummaryProps } from './types';

const PaymentSummaryWrapper = styled((props) => (
  <Box maxWidth={{ _: '100%', lg: '380px' }} width={1} bg="white" {...props} />
))`
  border-radius: 12px;
  position: relative;
  padding: 12px 24px 32px;
  z-index: 2;
  height: max-content;

  &:before {
    content: '';
    background-color: ${({ theme }) => theme.colors.primary[100]};
    position: absolute;
    top: 0;
    left: 0;
    height: 44px;
    width: 100%;
    z-index: -1;
    border-radius: 0;

    ${({ theme }) => css`
      @media screen and (min-width: ${theme.breakpoints.sm}) {
        border-radius: 12px 12px 0 0;
      }
    `}
  }

  p {
    margin: 0;
  }
`;

const PaymentSummary = ({
  totalPrice,
  isProductPriceProcessing,
  ...props
}: PaymentSummaryProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <PaymentSummaryWrapper>
      <Text
        color="text.darkText"
        fontWeight="600"
        fontSize="18px"
        lineHeight="22px"
        letterSpacing="0.1px"
        margin="0 0 38px"
        as="h3"
      >
        {t('purchase_summary_title')}
      </Text>

      {totalPrice === 0 ? (
        <FreeCoursePaymentSummaryDetails />
      ) : (
        <PaymentSummaryDetails totalPrice={totalPrice} {...props} />
      )}
      <Box pt={6}>
        <PaymentPolicyAcceptanceText />

        <ButtonFilled
          data-testid="payment-summary-submit-button"
          py="6px"
          px={0}
          width="100%"
          type="submit"
          disabled={
            isProductPriceProcessing ||
            (!!props.discountRemark && !props.isAcceptedOfDiscountPolicy)
          }
        >
          <Text fontSize="16px" lineHeight="28px" letterSpacing="0.1px" fontWeight="600" as="span">
            {t('purchase_summary_pay')}
          </Text>
        </ButtonFilled>
      </Box>
    </PaymentSummaryWrapper>
  );
};

export default PaymentSummary;
