import { useState, MouseEvent } from 'react';
import styled, { useTheme, css } from 'styled-components';

import Popper from '../Popper';
import SVGIcon, { ICON_LIST } from '../SVGIcon';
import { Text, Box, Flex } from '../Base';
import { UserButtonProps, UserButtonWithMenuPopupProps } from './types';
import { ButtonFilled } from '../Base/Button';

const UserButtonFilled = ({ children, ...props }) => (
  <ButtonFilled
    py={1}
    px={5}
    maxWidth={150}
    height={40}
    width={1}
    data-testid="btn-user-login"
    {...props}
  >
    {children}
  </ButtonFilled>
);

function isUserButtonProps(
  props: UserButtonProps | UserButtonWithMenuPopupProps
): props is UserButtonProps {
  return (props as UserButtonProps).onClick !== undefined;
}

const StyledLoginButtonText = styled((props) => <Text overflow="hidden" {...props} />)`
  text-overflow: ellipsis;
`;

const StyledMenuBlock = styled((props) => <Box my={5} minWidth="250px" {...props} />)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.default};
    border: 1px solid ${theme.colors.border.containerBox};
    border-radius: 8px;
    overflow: hidden;
    > :not(:last-child) {
      border-bottom: 1px solid ${theme.colors.border.divider};
    }
  `}
`;

// TODO: define props type
const Arrow = styled((props) => <Box ml={2} {...props} />)`
  transform: ${({ direction }) => (direction === 'down' ? `rotate(90deg)` : `rotate(270deg)`)};
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const ButtonWithMenuPopup = (
  props: UserButtonProps | UserButtonWithMenuPopupProps
): JSX.Element => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const closePopper = () => {
    setAnchorEl(null);
  };

  const togglePopper = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const childProps = { closePopper, isPopperOpen: open };

  if (isUserButtonProps(props)) {
    const { buttonText, disabled = false, onClick } = props;
    return (
      <UserButtonFilled onClick={onClick} disabled={disabled}>
        <Text textStyle="body1Medium">{buttonText}</Text>
      </UserButtonFilled>
    );
  } else {
    const { buttonText, disabled = false, children } = props;
    return (
      <>
        <UserButtonFilled onClick={togglePopper} disabled={disabled}>
          <Flex justifyContent="space-between" alignItems="center">
            {/* Preserve space for text to be center by space between */}
            <Box></Box>
            <StyledLoginButtonText textStyle="body1Medium">{buttonText}</StyledLoginButtonText>
            <Arrow direction={anchorEl ? 'up' : 'down'}>
              <SVGIcon name={ICON_LIST.CHEVRON} width="7" height="12" />
            </Arrow>
          </Flex>
        </UserButtonFilled>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transformOrigin="right top"
          onClickAway={closePopper}
        >
          <StyledMenuBlock>
            {typeof children === 'function' ? children(childProps) : children}
          </StyledMenuBlock>
        </Popper>
      </>
    );
  }
};

export default ButtonWithMenuPopup;
