import React from 'react';
import { useLocation } from 'react-router-dom';
import { useConfig } from '../../Contexts/ConfigContext';

import { initialize, sendPageview } from './googleAnalytics';

const useGoogleAnalytics = () => {
  const location = useLocation();
  const { analytics } = useConfig();
  const googleAnalyticsID = analytics?.googleAnalyticsID;
  const customerGoogleAnalyticsID = analytics?.customerGoogleAnalyticsID;

  React.useEffect(() => {
    if (customerGoogleAnalyticsID) {
      initialize([
        {
          trackingId: googleAnalyticsID,
          gaOptions: {
            name: 'PaaSTracker',
          },
        },
        {
          trackingId: customerGoogleAnalyticsID,
          gaOptions: {
            name: 'CustomerTracker',
          },
        },
      ]);
    } else {
      initialize(googleAnalyticsID);
    }
  }, [googleAnalyticsID, customerGoogleAnalyticsID]);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageview(currentPath);
  }, [location]);
};

export default useGoogleAnalytics;
