import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import formatPhoneNumberWithDash from '@skooldio/paas-shared-react-components/lib/Utils/formatPhoneNumberWithDash';
import { useTranslation } from 'react-i18next';
import emailSpellChecker from '@zootools/email-spell-checker';
import debounce from 'lodash.debounce';

import PaymentUserInfoView, { PAYMENT_USER_INFO_FIELDS } from '../../Components/PaymentUserInfo';
import { useAuth } from '../../Contexts/AuthContext';
import { usePayment } from '../../Contexts/PaymentProvider';
import { SKU_CATEGORY } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import { useConfig } from '../../Contexts/ConfigContext';

import { REGEX_EMAIL_FORMAT, REGEX_PHONE_FORMAT, MOBILE_PHONE_MASK } from '../Utils/constants';
import { STOREFRONT_TYPE } from '../../Utils/constant';

const DEFAULT_NAME_FIELD_REMARK_TEXT: string = 'กรอกชื่อ-นามสกุลจริง เพื่อใช้ออกใบประกาศนียบัตร';
const getHelperText = (
  isInvalid: boolean,
  isAnonymous?: boolean,
  errorText?: string,
  productType?: SKU_CATEGORY,
  storefrontType?: string
): string | undefined => {
  if (isInvalid) {
    return errorText;
  } else {
    if (
      isAnonymous &&
      productType === SKU_CATEGORY.ONLINE_COURSE &&
      storefrontType === STOREFRONT_TYPE.LMS
    ) {
      return DEFAULT_NAME_FIELD_REMARK_TEXT;
    }

    return;
  }
};

const PaymentUserInfo = ({ children }: { children?: JSX.Element }): JSX.Element => {
  const { t } = useTranslation();
  const { userInfo, isLoggedIn } = useAuth();
  const { setValue } = useFormContext();
  const { enablePhoneNumberField, enableAnonymousUser, storefrontType } = useConfig();
  const { isShipmentPackage, productType, unit } = usePayment();
  const { firstname, lastname, email: userEmail, contactInfo, additional4 } = userInfo ?? {};
  const email = contactInfo?.email ?? userEmail;
  const phoneNumber = additional4;
  const isAnonymousUser = enableAnonymousUser && !isLoggedIn;
  const isTHBCurrency = unit === 'THB';
  const isEnablePhoneNumberField = (enablePhoneNumberField || isShipmentPackage) && isTHBCurrency;
  const [emailSuggestionText, setEmailSuggestionText] = useState<string | undefined>(undefined);

  const {
    field: { ref: firstNameInputRef, ...firstNameInputProps },
    fieldState: { invalid: isFirstNameInputInValid, error: firstNameInputError },
  } = useController({
    name: PAYMENT_USER_INFO_FIELDS.FIRST_NAME,
    rules: { required: t('purchase_form_firstname_required') },
    defaultValue: '',
  });

  const {
    field: { ref: lastNameInputRef, ...lastNameInputProps },
    fieldState: { invalid: isLastNameInputInValid, error: lastNameInputError },
  } = useController({
    name: PAYMENT_USER_INFO_FIELDS.LAST_NAME,
    rules: { required: t('purchase_form_lastname_required') },
    defaultValue: '',
  });

  const {
    field: { ref: emailInputRef, ...emailInputProps },
    fieldState: { invalid: isEmailInputInValid, error: emailInputError },
  } = useController({
    name: PAYMENT_USER_INFO_FIELDS.EMAIL,
    rules: {
      required: t('purchase_form_email_required'),
      pattern: { value: REGEX_EMAIL_FORMAT, message: t('purchase_form_email_format') },
    },
    defaultValue: '',
  });

  const {
    field: { ref: phoneNumberInputRef, ...phoneNumberInputProps },
    fieldState: { invalid: isPhoneNumberInputInValid, error: phoneNumberInputError },
  } = useController({
    name: PAYMENT_USER_INFO_FIELDS.PHONE_NUMBER,
    rules: {
      required: isEnablePhoneNumberField ? t('purchase_form_phone_required') : false,
      pattern: { value: REGEX_PHONE_FORMAT, message: t('purchase_form_phone_format') },
    },

    defaultValue: '',
  });

  useEffect(() => {
    setValue(PAYMENT_USER_INFO_FIELDS.FIRST_NAME, firstname);
    setValue(PAYMENT_USER_INFO_FIELDS.LAST_NAME, lastname);
    setValue(PAYMENT_USER_INFO_FIELDS.EMAIL, email);

    if (phoneNumber && isEnablePhoneNumberField) {
      setValue(PAYMENT_USER_INFO_FIELDS.PHONE_NUMBER, formatPhoneNumberWithDash(phoneNumber));
    }
  }, [firstname, lastname, email, setValue, phoneNumber, isEnablePhoneNumberField]);

  const emailFieldValue = emailInputProps?.value;

  useEffect(() => {
    if (email) return;
    if (!emailFieldValue) return;

    const emailSuggestion = debounce(() => {
      const suggestionObj = emailSpellChecker.run({
        email: emailFieldValue,
        topLevelDomains: [...emailSpellChecker.POPULAR_TLDS, 'com', 'co.th', 'ac.th', 'org', 'edu'],
      });
      setEmailSuggestionText(suggestionObj?.full);
    }, 300);

    emailSuggestion();

    return () => {
      emailSuggestion.cancel();
    };
  }, [emailFieldValue]);

  return (
    <PaymentUserInfoView
      isLoggedIn={isLoggedIn}
      sectionTitle={isShipmentPackage ? t('purchase_shipment_info') : t('purchase_customer_info')}
      firstNameInputProps={{
        inputRef: firstNameInputRef,
        error: isFirstNameInputInValid,
        helperText: getHelperText(
          isFirstNameInputInValid,
          isAnonymousUser,
          firstNameInputError?.message,
          productType,
          storefrontType
        ),
        ...firstNameInputProps,
      }}
      lastNameInputProps={{
        inputRef: lastNameInputRef,
        error: isLastNameInputInValid,
        helperText: getHelperText(
          isLastNameInputInValid,
          isAnonymousUser,
          lastNameInputError?.message,
          productType,
          storefrontType
        ),
        ...lastNameInputProps,
      }}
      emailInputProps={{
        inputRef: emailInputRef,
        error: isEmailInputInValid,
        helperText: emailInputError?.message,
        suggestionText: emailSuggestionText,
        ...emailInputProps,
      }}
      phoneNumberInputProps={
        isEnablePhoneNumberField
          ? {
              inputRef: phoneNumberInputRef,
              mask: MOBILE_PHONE_MASK,
              error: isPhoneNumberInputInValid,
              helperText: phoneNumberInputError?.message,
              ...phoneNumberInputProps,
            }
          : undefined
      }
    >
      {children}
    </PaymentUserInfoView>
  );
};

export default PaymentUserInfo;
