import { useState } from 'react';
import {
  Box,
  LoadingModal,
  ThankYouWorkshop,
  EventTime,
} from '@skooldio/paas-shared-react-components';
import { useNavigate, useParams } from 'react-router-dom';
import urlJoin from 'url-join';
import useDisclosure from '@skooldio/paas-shared-react-components/lib/Utils/useDisclosure';

import { sendDataToGTM } from '../../Utils/analytics/googleTagManager';
import { STOREFRONT_TYPE } from '../../Utils/constant';

import useMySalesOrder from '../../Domain/useMySalesOrder';
import useMyCourseData from '../../Domain/useMyCourseData';
import CreatePasswordModal from '../../Components/CreatePasswordModal';
import { ThankYou as ThankYouView } from '../../Components/ThankYou';
import { ThankYouWithProductInfo as ThankYouWithProductInfoView } from '../../Components/ThankYouWithProductInfo';
import { useConfig } from '../../Contexts/ConfigContext';
import { useAuth } from '../../Contexts/AuthContext';
import { SKU_CATEGORY } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import { mapPurchaseProcessCompletedPayload } from '../../Utils/analytics/mapping';
import { useWorkshopBatchData } from '../../Domain/useWorkshopBatchData';
import { THANKYOU_VARIANT } from '../../Routes/ThankYou';

const ThankYou = () => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { isOpen: isModalOpen, open: openModal } = useDisclosure();
  const {
    storefrontType,
    classroomURL,
    storeURL,
    paymentSuccessContinueUrl,
    myCourseURL = '#',
    thankYouPageVariant,
  } = useConfig();

  const { orderId } = useParams<{ orderId: string }>();
  const [isOrderIdValidating, setIsOrderIdValidating] = useState(true);
  const { data: mySalesOrderData, loading } = useMySalesOrder({
    orderNo: orderId as string,
    onCompleted: (saleOrderData) => {
      if (saleOrderData) {
        const purchaseProcessCompletedPayload = mapPurchaseProcessCompletedPayload(saleOrderData);
        sendDataToGTM(purchaseProcessCompletedPayload);

        sendDataToGTM({ ecommerce: null });
        sendDataToGTM({
          event: 'purchase',
          ecommerce: { ...purchaseProcessCompletedPayload?.ecommerce },
        });
      }

      setIsOrderIdValidating(false);
    },
    onTimeout: (saleOrderData) => {
      if (saleOrderData) {
        const purchaseProcessCompletedPayload = mapPurchaseProcessCompletedPayload(saleOrderData);
        sendDataToGTM(purchaseProcessCompletedPayload);
      }

      navigate('/purchase-history');
    },
  });

  const { customerEmail, customerType, items, paymentAmount } = mySalesOrderData || {};
  const { title, product, SKUCode } = items?.[0] || {};
  const { SKUCategory, baseReferSKUId, eventTime } = product || {};
  const { startDate } = eventTime || {};
  const isFreeCourse = paymentAmount === 0;

  const { data: workShopBatchData, loading: loadWorkshopBatchData } = useWorkshopBatchData({
    SKUCode: SKUCode,
    skip: loading || storefrontType !== STOREFRONT_TYPE.LMS,
  });

  const { data: myCourseData } = useMyCourseData(
    {
      courseCode: baseReferSKUId,
    },
    { skip: SKUCategory !== SKU_CATEGORY.ONLINE_COURSE || storefrontType !== STOREFRONT_TYPE.LMS }
  );

  const courseId = myCourseData?.courseInfo?.courseId;
  const shouldUserCreatePassword = !userInfo?.isPassportUser && !userInfo?.isPasswordCreatedByUser;

  const goToNextPage = () => {
    switch (SKUCategory) {
      case SKU_CATEGORY.ONLINE_COURSE:
        const classroomFullURL = classroomURL?.replace(':courseId', courseId);
        window.location.replace(classroomFullURL ?? '');
        return;
      case SKU_CATEGORY.WORKSHOP_BATCH:
        window.location.replace(urlJoin(storeURL, '/browse'));
        return;
      case SKU_CATEGORY.COLLECTION:
      default:
        window.location.replace(myCourseURL);
        return;
    }
  };

  const handleNextButtonClick = () => {
    if (storefrontType === STOREFRONT_TYPE.LMS) {
      if (shouldUserCreatePassword && SKUCategory !== SKU_CATEGORY.WORKSHOP_BATCH) {
        openModal();
      } else {
        goToNextPage();
      }
      return;
    }

    if (storefrontType === STOREFRONT_TYPE.E_COMMERCE) {
      // TODO: Create Password UI for E-Commerce
      // if (shouldUserCreatePassword && SKUCategory !== SKU_CATEGORY.WORKSHOP_BATCH) {
      //   openModal();
      //   return;
      // }

      if (paymentSuccessContinueUrl) {
        window.open(paymentSuccessContinueUrl, '_blank');
      } else if (storeURL) {
        window.location.replace(storeURL);
      } else {
        window.close();
      }
      return;
    }
  };

  const handleOnModalClose = () => {
    window.location.replace(myCourseURL);
  };

  if (isOrderIdValidating || loadWorkshopBatchData) {
    return <LoadingModal isOpen={isOrderIdValidating} />;
  }

  if (SKUCategory === SKU_CATEGORY.WORKSHOP_BATCH && workShopBatchData) {
    const coverImageURL = items?.[0]?.parentProduct?.coverImageUrl;

    const {
      salePriceInclVAT_value,
      venueDisplayName,
      eventTime,
      title: workshopTitle,
    } = workShopBatchData;

    const workshopEventTime = eventTime ? (eventTime as EventTime) : undefined;
    const isFreeWorkshop = salePriceInclVAT_value === 0;

    return (
      <ThankYouWorkshop
        imageUrl={coverImageURL}
        email={customerEmail ?? ''}
        isFreeWorkshop={isFreeWorkshop}
        workshopName={title ?? workshopTitle}
        workshopEventime={workshopEventTime}
        workshopLocation={venueDisplayName}
        onNext={handleNextButtonClick}
      />
    );
  }

  const isCourseEnrolling =
    storefrontType === STOREFRONT_TYPE.LMS &&
    SKUCategory === SKU_CATEGORY.ONLINE_COURSE &&
    !courseId;

  return (
    <Box pb={8}>
      {thankYouPageVariant === THANKYOU_VARIANT.PRODUCT_INFO ? (
        <ThankYouWithProductInfoView
          orderNo={orderId}
          email={customerEmail}
          productItem={items?.[0]}
          shouldUserCreatePassword={shouldUserCreatePassword}
          onNext={handleNextButtonClick}
        />
      ) : (
        <>
          <ThankYouView
            isFreeCourse={isFreeCourse}
            isCourseEnrolling={isCourseEnrolling}
            shouldUserCreatePassword={shouldUserCreatePassword}
            imageUrl={'/static/images/payment-success.png'}
            productType={SKUCategory}
            courseName={title}
            email={customerEmail}
            workshopDate={startDate}
            customerType={customerType}
            onNext={handleNextButtonClick}
          />
          <CreatePasswordModal
            open={isModalOpen}
            email={customerEmail}
            onModalClose={handleOnModalClose}
          />
        </>
      )}
    </Box>
  );
};

export default ThankYou;
