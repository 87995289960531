const PlayOutline = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.10001 9.1331C0.933343 9.24421 0.763899 9.24976 0.591677 9.14976C0.419454 9.04976 0.333344 8.89976 0.333344 8.69976V1.19976C0.333344 0.999764 0.419454 0.849764 0.591677 0.749764C0.763899 0.649764 0.933343 0.655319 1.10001 0.76643L7.00001 4.5331C7.15557 4.6331 7.23334 4.77199 7.23334 4.94976C7.23334 5.12754 7.15557 5.26643 7.00001 5.36643L1.10001 9.1331ZM1.33334 7.79976L5.81668 4.94976L1.33334 2.09976V7.79976Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlayOutline;
