export enum SALES_ORDER_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  PAID = 'PAID',
  VOID = 'VOID',
}

export enum SHIPMENT_METHOD {
  ONLINE = 'ONLINE',
  DELIVER_BY_SUPPLIER = 'DELIVER_BY_SUPPLIER',
}

export enum SHIPMENT_STATUS {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
  VOIDED = 'VOIDED',
  RETURN = 'RETURN',
}

type OrderInput = {
  field: string;
  type: string;
};

type PageInput = {
  pageSize: number;
  currentPage: number;
};
export interface SFFoPaymentMySalesOrdersVariables {
  order?: OrderInput | null;
  paging?: PagingInput | null;
  search?: any;
}
