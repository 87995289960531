// TODO: should make this some kind of a base component?
import styled from 'styled-components';

import getImageSrcSetString from '../../Utils/getImageSrcSetString';

import { SiteLogoProps } from './types';

const LogoLink = styled.a`
  justify-self: self-start;
`;

const LogoImage = styled.img`
  object-fit: contain;
`

const SiteLogo = (props: SiteLogoProps): JSX.Element => {
  const {
    logoImageSrcSet,
    logoImageWidth = 145,
    logoImageHeight = 50,
    siteTitle = 'Tutor Marketplace',
    logoHref = '/',
  } = props;
  const imageSrcSet = getImageSrcSetString(logoImageSrcSet);

  return (
    <LogoLink href={logoHref}>
      <LogoImage
        src={logoImageSrcSet?.['1x']}
        srcSet={imageSrcSet}
        width={logoImageWidth}
        height={logoImageHeight}
        alt={siteTitle}
      />
    </LogoLink>
  );
};

export default SiteLogo;
