import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PaymentMethodView from '../../Components/PaymentMethod';
import paymentMethodData, {
  PaymentMethodInfo,
} from '../../Components/PaymentMethod/paymentMethodData';
import { useConfig } from '../../Contexts/ConfigContext';
import { PAYMENT_METHOD } from '../../Contexts/PaymentProvider/PaymentProvider.d';

const mapPaymentMethodOptions = (paymentMethodOptionsConfig, t) => {
  if (!paymentMethodOptionsConfig || paymentMethodOptionsConfig.length === 0) {
    return paymentMethodData;
  }
  return paymentMethodOptionsConfig.map((paymentMethodOption: PAYMENT_METHOD) => {
    const methodData: PaymentMethodInfo | undefined = paymentMethodData[paymentMethodOption];

    if (methodData) {
      return { ...paymentMethodData[paymentMethodOption], text: t(methodData.textTranslationKey) };
    } else {
      return null;
    }
  });
};

const PaymentMethod = (): JSX.Element => {
  const { t } = useTranslation();
  const { payment: paymentConfig } = useConfig();
  const paymentMethodOptions = mapPaymentMethodOptions(paymentConfig?.methodOptions, t);

  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid: isPaymentMethodInputInValid },
  } = useController({
    name: 'paymentMethod',
    rules: { required: true },
    defaultValue: '',
  });

  return (
    <PaymentMethodView
      inputProps={{ inputRef: ref, isInValid: isPaymentMethodInputInValid, ...inputProps }}
      selectedMethod={value}
      paymentMethodOptions={paymentMethodOptions}
    />
  );
};

export default PaymentMethod;
