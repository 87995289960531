// import defaultsDeep from 'lodash/defaultsDeep';
import merge from 'lodash/merge';
import isObjectLike from 'lodash/isObjectLike';
import { DefaultTheme } from 'styled-components';

import { DeepPartial } from '../../Utils/utilityTypes';

import {
  fontSizes,
  fontWeights,
  letterSpacings,
  spaces as space,
  breakpoints,
  radii,
  variants,
  lineHeights,
  textStyles,
  getLightColors,
  getDarkColors,
} from './theme';
import { GetColorInput } from './types';

function getColors(isDarkTheme: boolean, staticColor?: GetColorInput) {
  return isDarkTheme ? getDarkColors(staticColor) : getLightColors(staticColor);
}

export function getDefaultTheme(isDarkTheme = false): DefaultTheme {
  return {
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    space,
    breakpoints,
    radii,
    variants,
    textStyles,
    colors: getColors(isDarkTheme),
  };
}

function mergeTheme(
  overrideTheme: DeepPartial<DefaultTheme>,
  baseTheme: DefaultTheme,
  isDarkTheme
): DefaultTheme {
  const mergedTheme = merge({}, baseTheme, overrideTheme);

  // might try this if merge failed to deep merge
  // result = defaultsDeep({}, overrideTheme, baseTheme, );

  return {
    ...mergedTheme,
    colors: getColors(isDarkTheme, mergedTheme.colors),
  };
}

export function extendTheme(
  isDarkTheme = false,
  overrideTheme: DeepPartial<DefaultTheme> = {},
  baseTheme?: DefaultTheme
): DefaultTheme {
  if (!baseTheme) baseTheme = getDefaultTheme(isDarkTheme);

  if (!isObjectLike(overrideTheme)) {
    return baseTheme;
  } else {
    return mergeTheme(overrideTheme, baseTheme, isDarkTheme);
  }
}
