import { MenuSideNav as MenuSideNavView } from '@skooldio/paas-shared-react-components';

import { useAuth } from '../../Contexts/AuthContext';
import AccountMenu from './AccountMenu';
import AppMenu from './AppMenu';

const MenuSideNav = (): JSX.Element => {
  const { userInfo, isLoggedIn } = useAuth();

  const userFullName = isLoggedIn
    ? `${userInfo?.firstname} ${userInfo?.lastname}`
    : 'สวัสดี, Guest';

  return (
    <MenuSideNavView
      accountName={userFullName}
      upperMenu={({ onClose }) => <AccountMenu onMenuItemClick={onClose} />}
      lowerMenu={({ onClose }) => <AppMenu onMenuItemClick={onClose} />}
    />
  );
};

export default MenuSideNav;
