import { MenuProps } from '@skooldio/paas-shared-react-components/lib/Components/MenuItem';

import { useConfig } from '../../Contexts/ConfigContext';

import { MenuItem } from './types';

export function mapMenuProps(menuItems: MenuItem[]): MenuProps[] {
  return menuItems.map((menuItem) => ({
    ...menuItem,
    onClick: () => {
      window.location.href = menuItem.link;
    },
  }));
}

const mapAppMenuData = (appMenuConfig): MenuItem[] => {
  if (Array.isArray(appMenuConfig)) {
    return appMenuConfig.map((menu) => ({
      label: menu?.label,
      icon: menu?.iconName,
      link: menu?.url,
    }));
  }
  return [];
};

const useAppMenu = (): { appMenu: MenuItem[] } => {
  const { appShell } = useConfig();
  const mainNavMenuItems = appShell?.mainNavMenuItems;
  const appMenu: MenuItem[] = mapAppMenuData(mainNavMenuItems);

  return { appMenu };
};

export default useAppMenu;
