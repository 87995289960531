import { REDIRECTION_MESSAGE } from '../Domain/useQuickRegister/types';

const goToLoginPage = ({
  loginUrl,
  discountCode,
  discountCodeParamKey,
  redirectionMessage,
}: {
  loginUrl?: string;
  discountCode?: string;
  discountCodeParamKey?: string;
  redirectionMessage?: REDIRECTION_MESSAGE;
}): void => {
  if (!loginUrl) {
    console.error('No login url provided');
    return;
  }

  const continuePath = encodeURIComponent(window.location.href);
  const discountCodeValue = encodeURIComponent(discountCode ?? '');

  let destinationURL = `${loginUrl}?continue=${continuePath}`;

  if (discountCode) {
    if (!!redirectionMessage) {
      destinationURL = `${loginUrl}?info=${redirectionMessage}&continue=${continuePath}?${discountCodeParamKey}=${discountCodeValue}`;
    } else {
      destinationURL = `${loginUrl}?continue=${continuePath}?${discountCodeParamKey}=${discountCodeValue}`;
    }
  } else {
    if (!!redirectionMessage) {
      destinationURL = `${loginUrl}?info=${redirectionMessage}&continue=${continuePath}`;
    }
  }

  window.location.replace(destinationURL);
};

export default goToLoginPage;
