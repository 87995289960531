import { useTranslation } from 'react-i18next';
import { Flex, Text } from '../Base';

const FreeCoursePaymentSummaryDetails = () => {
  const { t } = useTranslation();
  return (
    <>
      <Flex fontWeight="400" justifyContent="space-between" alignItems="center" mb="8px">
        <span>{t('free_course_payment_summary_product_name')}</span>
        <p>{t('free')}</p>
      </Flex>
      <Flex fontWeight="600" justifyContent="space-between" alignItems="center" mb="8px">
        <Text textStyle="body1Medium">{t('purchase_summary_final_price')}</Text>
        <Text as="span" textStyle="subtitle1">
          {t('free')}
        </Text>
      </Flex>
    </>
  );
};

export default FreeCoursePaymentSummaryDetails;
