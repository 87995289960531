import { Text, Box } from '../Base';

const FooterMainContent = (props: { title?: string; content?: string }): JSX.Element => {
  const { title = '', content = '' } = props;
  return (
    <Box>
      <Text
        textStyle={{ _: 'body2', md: 'body1Medium' }}
        fontWeight="semibold"
        color="text.darkText"
      >
        {title}
      </Text>
      <Text
        textStyle={{ _: 'body2', md: 'body1' }}
        color="text.darkTextAlt"
        style={{ whiteSpace: 'pre-line' }}
      >
        {content}
      </Text>
    </Box>
  );
};

export default FooterMainContent;
