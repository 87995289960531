import { usePayment } from '../../Contexts/PaymentProvider';

import { CUSTOMER_TYPE } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import PaymentCorporateAddress from '../PaymentCorporateAddress';
import PaymentIndividualAddress from '../PaymentIndividualAddress';
import PaymentTaxInvoiceDeliveryAddress from '../PaymentTaxInvoiceDeliveryAddress';
import { Box, Text } from '@skooldio/paas-shared-react-components';

const TaxInvoiceInfo = () => {
  const { taxInvoiceRequested, customerType } = usePayment();

  if (!taxInvoiceRequested) return null;

  return (
    <Box>
      <Text textStyle="heading5" mb={4}>
        ใบกำกับภาษี/ใบเสร็จรับเงิน
      </Text>
      {customerType === CUSTOMER_TYPE.INDIVIDUAL ? (
        <PaymentIndividualAddress />
      ) : (
        <PaymentCorporateAddress />
      )}
      <PaymentTaxInvoiceDeliveryAddress />
    </Box>
  );
};

export default TaxInvoiceInfo;
