import styled from 'styled-components';

import {
  PaymentMethodOptionLabelProps,
  PaymentMethodOptionLabelImageListProps,
} from './PaymentMethod.d';

import Text from '../../Components/Base/Text';
import Flex from '../../Components/Base/Flex';
import Box from '../../Components/Base/Box';

const ImageItem = styled.img`
  max-width: ${({ width }) => width}px;
  width: 100%;
  margin-right: 10px;
`;

const PaymentMethodOptionLabel = (props: PaymentMethodOptionLabelProps): JSX.Element => {
  const { text = '', imageList = [] } = props;

  return (
    <Box pl={3}>
      <Text mb={1} textStyle="body2" color="text.darkText" fontWeight="600">
        {text}
      </Text>
      {Array.isArray(imageList) && imageList.length > 0 && (
        <Flex alignItems="center">
          {imageList.map(
            ({ imageUrl, alt, width }: PaymentMethodOptionLabelImageListProps): JSX.Element => {
              return <ImageItem key={alt} src={imageUrl} alt={alt} width={width} />;
            }
          )}
        </Flex>
      )}
    </Box>
  );
};

export default PaymentMethodOptionLabel;
