import styled, { css } from 'styled-components';

import { WorkshopCardCategoryData } from './types';
import { Flex, Text } from '../../Base';
import SVGIcon, { ICON_LIST } from '../../SVGIcon';
import { LineEllipsis } from '../../Mixins/LineEllipsis';

interface WorkshopCategoryProps {
  categoryData: WorkshopCardCategoryData;
}

const WorkshopCategoryWrapper = styled(Flex)`
  align-items: center;
  overflow: hidden;

  ${({ theme }) => css`
    font-size: 10px;
    gap: ${theme.space[1]}px;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      gap: ${theme.space[2]}px;
      font-size: ${theme.fontSizes.caption}px;
    }
  `};
`;

const SmallText = styled((props) => <Text textStyle="caption" as="span" {...props} />)`
  font-size: inherit;
  ${() => LineEllipsis(1, true)}
`;

const WorkshopCategory = ({ categoryData }: WorkshopCategoryProps): JSX.Element => {
  const { parentCategoryTitle } = categoryData ?? {};

  return (
    <WorkshopCategoryWrapper color="text.darkTextAlt">
      <Flex color="primary.500" alignItems="center" style={{ flexWrap: 'wrap', gap: '4px' }}>
        <SVGIcon name={ICON_LIST.EXTENSION_OUTLINED} />
        <SmallText>Workshop</SmallText>
      </Flex>
      {!!parentCategoryTitle && (
        <>
          <SmallText>•</SmallText>
          <SmallText>{parentCategoryTitle}</SmallText>
        </>
      )}
    </WorkshopCategoryWrapper>
  );
};

export { WorkshopCategory };
