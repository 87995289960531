import { PAYMENT_METHOD } from '../../../Contexts/PaymentProvider/PaymentProvider.d';

const mapGTMPaymentMethodValue = (paymentMethod?: string) => {
  switch (paymentMethod) {
    case PAYMENT_METHOD.CREDIT_CARD:
      return 'card';
    case PAYMENT_METHOD.QR_CODE:
      return 'promptpay';
    case PAYMENT_METHOD.ATM:
      return 'bank-transfer';
    default:
      return 'N/A';
  }
};

export default mapGTMPaymentMethodValue;
