import { GuestMenu, MemberMenu } from '@skooldio/paas-shared-react-components';
import urlJoin from 'url-join';

import { useConfig } from '../../Contexts/ConfigContext';
import { useAuth } from '../../Contexts/AuthContext';
import {
  useAccountMenu,
  ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION,
} from '../../Contexts/AccountMenuContext';

const AccountMenu = ({ onMenuItemClick }: { onMenuItemClick: () => void }): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const { userAccountMenu, storeURL, classroomURL, appShell } = useConfig();
  const { availableAccountMenuList } = useAccountMenu();
  const {
    enableSecondaryButton: ENABLE_SECONDARY_BUTTON,
    secondaryButtonURL: SECONDARY_BUTTON_URL,
  } = appShell?.header ?? {};

  const handleEnterClassroomButtonClick = () => {
    switch (userAccountMenu?.enterClassroomDestination) {
      case ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION.MY_COURSES:
        if (classroomURL) {
          window.location.href = classroomURL?.replace('/classroom/:courseId', '/my-courses');
        }
        break;
      case ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION.GET_APP:
      default:
        if (storeURL) {
          window.location.href = urlJoin(storeURL, '/get-app');
        }
    }
    onMenuItemClick();
  };

  if (isLoggedIn) {
    return (
      <MemberMenu
        menus={availableAccountMenuList}
        onMenuItemClick={onMenuItemClick}
        onEnterClassroomButtonClick={handleEnterClassroomButtonClick}
        isHiddenEnterClassroomButton={!userAccountMenu?.enableEnterClassroom}
        isHiddenSecondaryButton={!ENABLE_SECONDARY_BUTTON}
        onSecondaryButtonClick={() => (window.location.href = SECONDARY_BUTTON_URL ?? '')}
      />
    );
  }

  return (
    <GuestMenu
      login={() => {
        /* TODO */
      }}
      register={() => {
        /* TODO */
      }}
    />
  );
};

export default AccountMenu;
