import { useEffect } from 'react';

import { useConfig } from '../../Contexts/ConfigContext';
import { useAuth } from '../../Contexts/AuthContext';

const UserConsentValidation = () => {
  const { storeURL, userConsent: userConsentConfig, enableAnonymousUser } = useConfig();
  const { userInfo, isLoggedIn } = useAuth();

  const enablePDPAConsent = userConsentConfig?.enablePDPAConsent;
  const PDPAConsentVersion = userConsentConfig?.PDPAConsentVersion;
  const redirectURL = userConsentConfig?.redirectURL ?? storeURL;

  useEffect(() => {
    if (!enablePDPAConsent) return;
    if (!isLoggedIn || !userInfo) return;

    const { userConsent, isPassportUser, isPasswordCreatedByUser } = userInfo;
    const isThankYouPage = window && window.location.pathname.split('/').includes('thank-you');
    const isQuickRegisterUser = enableAnonymousUser && !isPassportUser && !isPasswordCreatedByUser;

    if (isThankYouPage || isQuickRegisterUser) return;

    const redirectToDashboard = () => {
      const destinationURL = new URL(redirectURL as string);
      destinationURL.searchParams.set('continue', window.location.href);
      window.location.href = destinationURL.toString();
    };

    if (userConsent) {
      const allUserConsentFields = Object.keys(userConsent);

      if (allUserConsentFields.length < 2) {
        redirectToDashboard();
        return;
      }

      const shouldShowPDPAModal = allUserConsentFields.some(
        (consentKey) =>
          !userConsent[consentKey] || userConsent[consentKey].acceptedVersion !== PDPAConsentVersion
      );

      if (shouldShowPDPAModal) {
        redirectToDashboard();
        return;
      }
    } else {
      redirectToDashboard();
    }
  }, [
    PDPAConsentVersion,
    enablePDPAConsent,
    redirectURL,
    isLoggedIn,
    userInfo,
    enableAnonymousUser,
  ]);

  return null;
};

export default UserConsentValidation;
