const Tiktok = (props: any): JSX.Element => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3944 8.15248C15.6845 8.15657 14.0166 7.62333 12.6262 6.62809V13.5692C12.6258 14.8548 12.2328 16.1096 11.5 17.1658C10.7671 18.222 9.72924 19.0293 8.52517 19.4798C7.32109 19.9302 6.0082 20.0023 4.76203 19.6865C3.51587 19.3706 2.39583 18.6819 1.55168 17.7123C0.70754 16.7427 0.179526 15.5385 0.0382446 14.2607C-0.103037 12.9829 0.149149 11.6924 0.761081 10.5618C1.37301 9.43126 2.31552 8.51443 3.46259 7.93396C4.60965 7.3535 5.90659 7.13705 7.17998 7.31358V10.8047C6.59727 10.6214 5.97155 10.627 5.39217 10.8205C4.81278 11.014 4.30937 11.3857 3.95382 11.8824C3.59827 12.3791 3.40876 12.9755 3.41236 13.5863C3.41596 14.1972 3.61248 14.7913 3.97386 15.2838C4.33523 15.7763 4.84299 16.142 5.42461 16.3287C6.00623 16.5154 6.63198 16.5135 7.21248 16.3234C7.79299 16.1332 8.29858 15.7645 8.65704 15.2699C9.0155 14.7753 9.2085 14.1801 9.20849 13.5692V0H12.6262C12.6239 0.28862 12.648 0.576847 12.6985 0.861038C12.8172 1.49545 13.0642 2.09896 13.4242 2.63466C13.7842 3.17037 14.2497 3.62699 14.7922 3.97662C15.5641 4.48699 16.469 4.75901 17.3944 4.75881V8.15248Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Tiktok;
