const CurrentUser = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_13094_249507)">
        <g clip-path="url(#clip1_13094_249507)">
          <path
            d="M16.001 21V19C16.001 17.9391 15.5795 16.9217 14.8294 16.1716C14.0793 15.4214 13.0618 15 12.001 15H5.00098C3.94011 15 2.92269 15.4214 2.17255 16.1716C1.4224 16.9217 1.00098 17.9391 1.00098 19V21"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.50098 11C10.7101 11 12.501 9.20914 12.501 7C12.501 4.79086 10.7101 3 8.50098 3C6.29184 3 4.50098 4.79086 4.50098 7C4.50098 9.20914 6.29184 11 8.50098 11Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.001 11L19.001 13L23.001 9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_13094_249507">
          <rect width="24" height="24" fill="white" transform="translate(0.000976562)" />
        </clipPath>
        <clipPath id="clip1_13094_249507">
          <rect width="24" height="24" fill="white" transform="translate(0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CurrentUser;
