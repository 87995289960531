const Geometry = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.35 6.23301L6.01667 0.333008L9.68333 6.23301H2.35ZM9.76667 13.6663C8.94444 13.6663 8.25556 13.3886 7.7 12.833C7.14444 12.2775 6.86667 11.5886 6.86667 10.7663C6.86667 9.94412 7.14444 9.25523 7.7 8.69967C8.25556 8.14412 8.94444 7.86634 9.76667 7.86634C10.5889 7.86634 11.2778 8.14412 11.8333 8.69967C12.3889 9.25523 12.6667 9.94412 12.6667 10.7663C12.6667 11.5886 12.3889 12.2775 11.8333 12.833C11.2778 13.3886 10.5889 13.6663 9.76667 13.6663ZM0 13.2497V8.18301H5.06667V13.2497H0ZM9.76667 12.6663C10.3 12.6663 10.75 12.483 11.1167 12.1163C11.4833 11.7497 11.6667 11.2997 11.6667 10.7663C11.6667 10.233 11.4833 9.78301 11.1167 9.41634C10.75 9.04967 10.3 8.86634 9.76667 8.86634C9.23333 8.86634 8.78333 9.04967 8.41667 9.41634C8.05 9.78301 7.86667 10.233 7.86667 10.7663C7.86667 11.2997 8.05 11.7497 8.41667 12.1163C8.78333 12.483 9.23333 12.6663 9.76667 12.6663ZM1 12.2497H4.06667V9.18301H1V12.2497ZM4.15 5.23301H7.88333L6.01667 2.21634L4.15 5.23301Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Geometry;
