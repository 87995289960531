import { BranchInfoOptionsType } from '../PaymentCorporateAddress/types';

const validateBranchInfo = ({
  value,
  validator,
}: {
  value: string;
  validator: BranchInfoOptionsType['branchNameValidation'];
}) => {
  if (!value) {
    return 'กรุณากรอกสำนักงาน/สาขาเลขที่';
  }

  if (!Array.isArray(validator) || validator.length === 0) {
    return true;
  }

  let validationError = '';

  for (const { regex, errorMessage } of validator) {
    if (!regex) continue;

    const isValid = new RegExp(regex).test(value);

    if (isValid) {
      validationError = '';
      break;
    }

    if (!isValid && !!validationError) {
      continue;
    }

    validationError = errorMessage;
  }

  return !!validationError ? validationError : true;
};

export default validateBranchInfo;
