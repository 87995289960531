import { SocialMedia } from './types';
import { ICON_LIST } from '../SVGIcon';

const facebookChannel: SocialMedia = {
  iconName: ICON_LIST.FACEBOOK_CIRCLE,
  width: 20,
  height: 20,
  socialMediaUrl: 'https://www.facebook.com/skooldio',
};

const twitterChannel: SocialMedia = {
  iconName: ICON_LIST.TWITTER,
  width: 20,
  height: 20,
  socialMediaUrl: 'https://www.twitter.com/skooldio',
};

const youtubeChannel: SocialMedia = {
  iconName: ICON_LIST.YOUTUBE,
  width: 24,
  height: 20,
  socialMediaUrl: 'https://www.youtube.com/skooldio',
};

const tiktokChannel: SocialMedia = {
  iconName: ICON_LIST.TIKTOK,
  width: 17,
  height: 20,
  socialMediaUrl: 'https://www.tiktok.com/@skooldio',
};

const instagramChannel: SocialMedia = {
  iconName: ICON_LIST.INSTAGRAM,
  width: 24,
  height: 24,
  socialMediaUrl: 'https://www.instagram.com/skooldio',
};

const lineChannel: SocialMedia = {
  iconName: ICON_LIST.LINE_APP,
  width: 21,
  height: 21,
  socialMediaUrl: 'https://page.line.me/skooldio',
};

export const DEFAULT_SOCIAL_MEDIA_CHANNELS = {
  facebookChannel,
  twitterChannel,
  youtubeChannel,
  tiktokChannel,
  instagramChannel,
  lineChannel,
};
