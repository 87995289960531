import { useTheme } from 'styled-components';

import { Flex, Text } from '../../Base';
import SVGIcon from '../../SVGIcon';

interface ModalContentDetailProps {
  iconName?: string;
  title: string;
  description?: string | Element;
}

const ModalContentDetail = (props: ModalContentDetailProps) => {
  const { title, description, iconName, ...restProps } = props;
  const { colors } = useTheme();
  return (
    <Flex flexDirection={'column'} alignItems={'center'} {...restProps}>
      {iconName && (
        <SVGIcon name={iconName} width={'72px'} height={'72px'} color={colors.primary[500]} />
      )}
      <Text
        textStyle={{ _: 'heading4', md: 'heading3' }}
        textAlign={'center'}
        mt={iconName ? 7 : 9}
      >
        {title}
      </Text>
      <Text
        textStyle={'body1'}
        mt={4}
        color={'grayscale.700'}
        textAlign={'center'}
        style={{ whiteSpace: 'pre-line' }}
      >
        {description}
      </Text>
    </Flex>
  );
};

export default ModalContentDetail;
