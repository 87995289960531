import { mapGTMProductTypeValue, mapGTMPaymentMethodValue, mapGAEventCategoryValue } from './index';
import { MySalesOrder } from './../../../Domain/useMySalesOrder/types';
import { CUSTOMER_TYPE } from '../../../Contexts/PaymentProvider/PaymentProvider.d';

const mapPurchaseProcessCompletedPayload = (salesOrder: MySalesOrder) => {
  const orderItem = salesOrder.items?.[0];

  const conversionValue =
    salesOrder?.customerType === CUSTOMER_TYPE.CORPORATE
      ? salesOrder?.paymentAmount
      : salesOrder?.totalAmountExclVAT;

  return {
    event: 'purchase-process-completed',
    skuCode: orderItem?.SKUCode,
    customerType: salesOrder?.customerType,
    paymentStatus: salesOrder?.status,
    productTitle: orderItem?.title,
    VAT: salesOrder?.VAT,
    priceInclVAT: salesOrder?.priceInclVAT,
    paymentAmount: salesOrder?.paymentAmount,
    conversionValue,
    productType: mapGTMProductTypeValue(orderItem?.product?.SKUCategory),
    paymentMethod: mapGTMPaymentMethodValue(salesOrder?.paymentMethod),
    salesOrderNumber: salesOrder.orderNo,
    gaEventCategory: mapGAEventCategoryValue({
      productType: orderItem?.product?.SKUCategory,
      productTitle: orderItem?.title,
      skuCode: orderItem?.SKUCode,
    }),
    ecommerce: {
      currency: 'THB',
      transaction_id: salesOrder.orderNo,
      value: conversionValue,
      tax: salesOrder?.VAT,
      items: [
        {
          item_id: orderItem?.SKUCode,
          item_name: orderItem?.title,
          discount: salesOrder?.discountInclVAT,
          item_category: mapGTMProductTypeValue(orderItem?.product?.SKUCategory),
          price: conversionValue,
        },
      ],
    },
  };
};

export default mapPurchaseProcessCompletedPayload;
