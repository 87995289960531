import React, { createContext, useContext } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { ICON_LIST } from '@skooldio/paas-shared-react-components';
import urlJoin from 'url-join';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../Contexts/AuthContext';
import { useConfig } from '../ConfigContext';

enum AccountMenuName {
  PROFILE = 'PROFILE',
  HISTORY = 'HISTORY',
  MY_CERTIFICATES = 'MY_CERTIFICATES',
  CREDIT_BANK_CONNECTIONS = 'CREDIT_BANK_CONNECTIONS',
  LOGOUT = 'LOGOUT',
}

enum ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION {
  GET_APP = 'GET_APP',
  MY_COURSES = 'MY_COURSES',
}

interface Menu {
  label: string;
  onClick: () => void;
  icon: ICON_LIST;
}

type AccountMenu = {
  [key in AccountMenuName]: Menu;
};

interface IAccountMenuProductsContext {
  accountMenu: AccountMenu;
  availableAccountMenuList: Menu[];
}

const AccountMenuContext = createContext<IAccountMenuProductsContext | undefined>(undefined);

const AccountMenuProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const match = useMatch({ path: '/purchase/:skuCode' });
  const { logout } = useAuth();
  const { storeURL, profileURL, userAccountMenu, disableUserAccountMenuInPurchasePage } =
    useConfig();

  const disableUserAccountMenu = disableUserAccountMenuInPurchasePage && match;

  const accountMenu: Readonly<AccountMenu> = {
    [AccountMenuName.PROFILE]: {
      label: t('account_menu_item_profile'),
      icon: ICON_LIST.ACCOUNT,
      onClick: () => {
        if (profileURL) {
          window.location.href = profileURL;
        }
      },
    },
    [AccountMenuName.MY_CERTIFICATES]: {
      label: t('account_menu_item_my_certificates'),
      icon: ICON_LIST.MORTAR_BOARD,
      onClick: () => {
        window.location.href = urlJoin(storeURL, '/account/my-certificates');
      },
    },
    [AccountMenuName.HISTORY]: {
      label: t('account_menu_item_purchase_history'),
      icon: ICON_LIST.HISTORY,
      onClick: () => {
        navigate('/purchase-history');
      },
    },
    [AccountMenuName.CREDIT_BANK_CONNECTIONS]: {
      label: t('account_menu_item_credit_bank_connection'),
      icon: ICON_LIST.CREDIT_BANK_CONNECTION,
      onClick: () => {
        window.location.href = urlJoin(storeURL, '/account/credit-bank-connections');
      },
    },
    [AccountMenuName.LOGOUT]: {
      label: t('account_menu_item_logout'),
      icon: ICON_LIST.EXIT,
      onClick: () => {
        logout();
        if (storeURL) {
          window.location.href = storeURL;
        }
      },
    },
  };

  let disabledAccountMenuList: any[] = [];

  if (disableUserAccountMenu) {
    disabledAccountMenuList = [
      AccountMenuName.PROFILE,
      AccountMenuName.HISTORY,
      AccountMenuName.MY_CERTIFICATES,
      AccountMenuName.CREDIT_BANK_CONNECTIONS,
    ];
  } else {
    if (userAccountMenu?.disableEnterProfileAccount) {
      disabledAccountMenuList.push(AccountMenuName.PROFILE);
    }

    if (userAccountMenu?.disableEnterPurchaseHistory) {
      disabledAccountMenuList.push(AccountMenuName.HISTORY);
    }

    if (!userAccountMenu?.enableEnterMyCertificates) {
      disabledAccountMenuList.push(AccountMenuName.MY_CERTIFICATES);
    }

    if (!userAccountMenu?.enableEnterCreditBankConnections) {
      disabledAccountMenuList.push(AccountMenuName.CREDIT_BANK_CONNECTIONS);
    }
  }

  const availableAccountMenuList: Menu[] = Object.values(
    omit(accountMenu, disabledAccountMenuList)
  );

  return (
    <AccountMenuContext.Provider value={{ accountMenu, availableAccountMenuList }}>
      {children}
    </AccountMenuContext.Provider>
  );
};

const useAccountMenu = () => {
  const context = useContext(AccountMenuContext);
  if (context === undefined) {
    throw new Error('useAccountMenu must be used within AccountMenuProvider');
  }
  return context;
};

export { AccountMenuProvider, useAccountMenu, ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION };
