import { useQuery } from '@apollo/client';

import {
  WorkshopBatchBySKUCodeResponse,
  WorkshopBatchBySKUCodeVariables,
  WorkshopBatchHookResponse,
} from './types';
import { getWorkshopBatchesBySKUCode } from '../../GraphQL/query/Product.query';

const useWorkshopBatchData = ({
  SKUCode,
  ...props
}: WorkshopBatchBySKUCodeVariables): WorkshopBatchHookResponse => {
  const { data, loading, error, ...apolloUtils } = useQuery<
    WorkshopBatchBySKUCodeResponse,
    WorkshopBatchBySKUCodeVariables
  >(getWorkshopBatchesBySKUCode, {
    variables: { SKUCode },
    ...props,
  });

  return {
    data: data?.sfFoProductWorkshopBatchBySKUCode,
    error,
    loading,
    ...apolloUtils,
  };
};

export { useWorkshopBatchData };
