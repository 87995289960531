import { useMutation } from '@apollo/client';

import { paymentUpsertPreSalesOrder } from '../GraphQL/mutation/Product.mutation';
import {
  PaymentUpsertMutation,
  PaymentUpsertMutationOptionsProps,
  SFFoPaymentUpsertPreSalesOrderInputProps,
} from './usePaymentPreSalesOrder.d';

const usePaymentPreSalesOrder = (mutationOptions?: PaymentUpsertMutationOptionsProps) => {
  const [mutate, { data, error, loading, ...apolloUtils }] = useMutation<
    PaymentUpsertMutation,
    { input: SFFoPaymentUpsertPreSalesOrderInputProps }
  >(paymentUpsertPreSalesOrder, mutationOptions);

  const handlePaymentPreSalesOrder = (
    {
      preSalesOrderItems,
      customerType,
      paymentMethod,
      customerFullName,
      customerAddress,
      customerMobile,
      customerEmail,
      discountCode,
    }: SFFoPaymentUpsertPreSalesOrderInputProps,
    // TODO: resolve apolloProps type
    apolloProps: any = {}
  ): any => {
    return mutate({
      variables: {
        input: {
          preSalesOrderItems,
          customerType,
          paymentMethod,
          customerFullName,
          customerAddress,
          customerMobile,
          customerEmail,
          discountCode,
        },
      },
      ...apolloProps,
    });
  };

  return {
    loading,
    error,
    data,
    paymentPreSalesOrder: handlePaymentPreSalesOrder,
    ...apolloUtils,
  };
};

export default usePaymentPreSalesOrder;
