import { Text } from '@skooldio/paas-shared-react-components';

import { useConfig } from '../../Contexts/ConfigContext';

const PaymentPolicyAcceptance = () => {
  const { external } = useConfig();

  return (
    <Text textStyle="caption" pb={3}>
      เมื่อชำระเงิน ถือว่าท่านได้ยอมรับ{' '}
      <a href={external?.termsUrl} target="_blank" rel="noreferrer">
        <Text color="system.info.500" display="inline">
          ข้อตกลงและเงื่อนไขการใช้บริการ
        </Text>
      </a>
      ,{' '}
      <a
        href={external?.dataProtectionPolicyUrl ?? external?.privacyUrl}
        target="_blank"
        rel="noreferrer"
      >
        <Text color="system.info.500" display="inline">
          ประกาศความเป็นส่วนตัวสำหรับลูกค้าและผู้รับบริการ
        </Text>
      </a>
      {external?.refundPolicyUrl && (
        <>
          {' '}
          และ{' '}
          <a href={external?.refundPolicyUrl} target="_blank" rel="noreferrer">
            <Text color="system.info.500" display="inline">
              นโยบายการคืนเงิน
            </Text>
          </a>{' '}
          เรียบร้อยแล้ว
        </>
      )}
    </Text>
  );
};

export default PaymentPolicyAcceptance;
