import BaseModal from '../BaseModal';
import { Box } from '../../Base';

import { BaseModalProps } from '../BaseModal/BaseModal';

/**
 * A modal with top stripe.
 * From the design system, Modal width should be fixed to 480px.
 */
const TopStripeModal = ({ open, children, ...props }: BaseModalProps) => {
  return (
    <BaseModal open={open} {...props}>
      <Box height={10} minHeight={10} bg={'primary.500'} />
      <Box width="480px" maxWidth="100%" px={{ _: 4, sm: 7 }} py={7} bg={'background.surface'}>
        {children}
      </Box>
    </BaseModal>
  );
};

export default TopStripeModal;
