import { useTranslation } from 'react-i18next';
import { ButtonOutlined, Text } from '../Base';
import MenuList from '../MenuList';

import { MemberMenuProps } from './types';

const MemberMenu = ({
  isHiddenEnterClassroomButton = true,
  onEnterClassroomButtonClick,
  menus,
  onMenuItemClick,
  isHiddenSecondaryButton = true,
  onSecondaryButtonClick,
}: MemberMenuProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {!isHiddenEnterClassroomButton && (
        <ButtonOutlined
          width="calc(100% - 32px)"
          mx={4}
          mb={4}
          py={2}
          color="button.outlined"
          fontSize="600"
          style={{ border: '2px solid' }}
          onClick={onEnterClassroomButtonClick}
        >
          <Text as="span" textStyle="body1Medium">
            {t('member_menu_my_courses_button')}
          </Text>
        </ButtonOutlined>
      )}
      {!isHiddenSecondaryButton && (
        <ButtonOutlined
          width="calc(100% - 32px)"
          mx={4}
          py={2}
          color="button.outlined"
          fontSize="600"
          style={{ border: '2px solid' }}
          onClick={onSecondaryButtonClick}
        >
          <Text as="span" textStyle="body1Medium">
            {t('member_menu_secondary_button')}
          </Text>
        </ButtonOutlined>
      )}
      <MenuList menus={menus} onClickMenuItem={onMenuItemClick} />
    </>
  );
};

export default MemberMenu;
