import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import Flex from '../Base/Flex';
import Text from '../Base/Text';
import { DotLoader } from '../Loader';

const useStyle = (): ClassNameMap<'paper'> => {
  const { colors } = useTheme();
  const classes = makeStyles({
    paper: () => ({
      width: '240px',
      height: '200px',
      borderRadius: '24px',
      backgroundColor: colors.background.surface,
    }),
  })();
  return classes;
};

const LoadingModal = ({ isOpen }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Dialog classes={classes} open={isOpen}>
      <Flex
        flexDirection={'column'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <DotLoader />
        <Text textStyle={'body1'} color={'text.darkTextAlt'}>
          {t('loading')}
        </Text>
      </Flex>
    </Dialog>
  );
};

export default LoadingModal;
