import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { CURRENCY_TYPE } from '../constants';

type PriceWithCurrencyProps = {
  price: number;
  currency?: CURRENCY_TYPE;
  isShowSymbol?: boolean;
};

const PriceWithCurrency = ({ price, currency, isShowSymbol }: PriceWithCurrencyProps) => {
  const { t } = useTranslation('common');

  // Display up to 2 decimal places. If there are no decimal places, do not display any.
  const priceText = numeral(price).format('0,0[.]00');

  let result;
  if (currency === CURRENCY_TYPE.THB) {
    result = isShowSymbol ? `${priceText}.-` : `${priceText} ${t('currency_thb')}`;
  } else if (currency === CURRENCY_TYPE.USD) {
    result = isShowSymbol ? `$${priceText}` : `${priceText} ${t('currency_usd')}`;
  }

  return <>{result}</>;
};

export default PriceWithCurrency;
export { PriceWithCurrency };
export type { PriceWithCurrencyProps };
