import {
  Account,
  UserButton as UserButtonView,
  MenuList,
} from '@skooldio/paas-shared-react-components';
import { useTranslation } from 'react-i18next';
import urlJoin from 'url-join';

import { useConfig } from '../../Contexts/ConfigContext';
import { useAuth } from '../../Contexts/AuthContext';
import {
  useAccountMenu,
  ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION,
} from '../../Contexts/AccountMenuContext';
import { Text, Box, ButtonOutlined } from '../../Components/Base';
import goToLoginPage from '../../Utils/goToLoginPage';

const UserButton = () => {
  const { userInfo, isLoggedIn, loading } = useAuth();
  const { userAccountMenu, loginUrl, storeURL, classroomURL, appShell } = useConfig();
  const { availableAccountMenuList } = useAccountMenu();
  const { t } = useTranslation();

  const handleEnterClassroomButtonClick = () => {
    switch (userAccountMenu?.enterClassroomDestination) {
      case ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION.MY_COURSES:
        if (classroomURL) {
          window.location.href = classroomURL?.replace('/classroom/:courseId', '/my-courses');
        }
        break;
      case ACCOUNT_MENU_ENTER_CLASSROOM_DESTINATION.GET_APP:
      default:
        if (storeURL) {
          window.location.href = urlJoin(storeURL, '/get-app');
        }
    }
  };

  if (!isLoggedIn) {
    return (
      <Box width={1} display={{ _: 'none', sm: 'block' }}>
        <UserButtonView
          buttonText={t('login')}
          disabled={loading}
          onClick={() => goToLoginPage({ loginUrl })}
        ></UserButtonView>
      </Box>
    );
  }

  const userFullName = `${userInfo?.firstname} ${userInfo?.lastname}`;

  return (
    <Box width={1} display={{ _: 'none', sm: 'block' }}>
      <UserButtonView buttonText={userInfo.firstname} disabled={loading}>
        {({ closePopper }) => (
          <>
            <Box>
              <Account name={userFullName} />
              {userAccountMenu?.enableEnterClassroom && (
                <ButtonOutlined
                  width="calc(100% - 32px)"
                  mx={4}
                  mb={4}
                  py={2}
                  color="primary"
                  style={{ border: '2px solid' }}
                  onClick={() => {
                    handleEnterClassroomButtonClick();
                    closePopper();
                  }}
                >
                  <Text as="span" textStyle="body1Medium">
                    {t('member_menu_my_courses_button')}
                  </Text>
                </ButtonOutlined>
              )}
              {appShell?.header?.enableSecondaryButton && (
                <ButtonOutlined
                  width="calc(100% - 32px)"
                  mx={4}
                  mb={4}
                  py={2}
                  color="primary"
                  style={{ border: '2px solid' }}
                  onClick={() => {
                    window.location.href = appShell?.header?.secondaryButtonURL ?? '';
                    closePopper();
                  }}
                >
                  <Text as="span" textStyle="body1Medium">
                    {t('header_secondary_button')}
                  </Text>
                </ButtonOutlined>
              )}
            </Box>
            <MenuList menus={availableAccountMenuList} onClickMenuItem={closePopper} />
          </>
        )}
      </UserButtonView>
    </Box>
  );
};

export default UserButton;
