export type Address = {
  address: string;
  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;
};

export const formatAddress = (address: Address): string => {
  if (!address) return '';
  return [
    address.address,
    address.subDistrict,
    address.district,
    address.province,
    address.postalCode,
  ]
    .join(' ')
    .trim();
};
