import { Popper as BasePopper, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import styled from 'styled-components';
import { PopperProps } from './types';

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    background-color: transparent;
  }
`

const Popper = ({
  open,
  anchorEl,
  placement,
  transformOrigin,
  onClickAway,
  children,
}: PopperProps) => {
  return (
    <BasePopper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      disablePortal={true}
      style={{ zIndex: 5 }}
    >
      {({ TransitionProps }) => {
        return (
          <Grow {...TransitionProps} style={{ transformOrigin }}>
            <StyledPaper>
              <ClickAwayListener onClickAway={onClickAway}>
                {/* use <div> to forward ref for any children */}
                <div>{children}</div>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        );
      }}
    </BasePopper>
  );
};

export default Popper;
