import { useEffect, useCallback } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SVGIcon } from '@skooldio/paas-shared-react-components';
import styled, { css } from 'styled-components';

import TextInput from '../Base/TextInput';
import Box from '../Base/Box';
import Grid from '../Base/Grid';

import useAddressData from '../../Domain/useAddressData';
import { PaymentUserAddressProps } from './types';

// TODO: delete after useAddressData is typeScript
type IAddressData = [
  {
    province?: string;
    amphur?: string;
    district?: string;
    zipcode?: string;
  },
  {
    setProvince: (updateProvince?: string) => void;
    setAmphur: (updateAmphur?: string) => void;
    setDistrict: (updateDistrict?: string) => void;
    setZipcode: (updateZipcode?: string) => void;
  },
  {
    provinces: string[];
    amphurs: string[];
    districts: string[];
    zipcodes: string[];
  },
  { status: string; address: any }
];

export const PAYMENT_USER_ADDRESS_FIELDS = {
  ADDRESS: 'address',
  PROVINCE: 'province',
  AMPHUR: 'amphur',
  DISTRICT: 'district',
  ZIP_CODE: 'zipcode',
};

const PaymentUserAddressWrapper = styled(Grid)`
  ${({ theme }) => css`
    width: 100%;
    grid-gap: ${theme.space[4]}px;

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(2, auto);
    }
  `}
`;

const PaymentUserAddress = ({
  addressInputProps,
  provinceInputProps,
  amphurInputProps,
  districtInputProps,
  zipcodeInputProps,
}: PaymentUserAddressProps): JSX.Element => {
  const [data, mutate, options] = useAddressData() as IAddressData;
  const { setProvince, setAmphur, setDistrict, setZipcode } = mutate;

  useEffect(() => {
    const { province, amphur, district, zipcode } = data;

    typeof provinceInputProps.onChange === 'function' && provinceInputProps.onChange(province);
    typeof amphurInputProps.onChange === 'function' && amphurInputProps.onChange(amphur);
    typeof districtInputProps.onChange === 'function' && districtInputProps.onChange(district);
    typeof zipcodeInputProps.onChange === 'function' && zipcodeInputProps.onChange(zipcode);
  }, [data, provinceInputProps, amphurInputProps, districtInputProps, zipcodeInputProps]);

  const handleProvinceChange = useCallback(
    (e, value) => {
      setProvince(value);
    },
    [setProvince]
  );

  const handleAmphurChange = useCallback(
    (e, value) => {
      setAmphur(value);
    },
    [setAmphur]
  );

  const handleDistrictChange = useCallback(
    (e, value) => {
      setDistrict(value);
    },
    [setDistrict]
  );

  const handleZipcodeChange = useCallback(
    (e, value) => {
      setZipcode(value);
    },
    [setZipcode]
  );

  return (
    <>
      <Box pb={4}>
        <TextInput
          label="อาคาร, บ้านเลขที่, ถนน"
          placeholder="อาคาร, บ้านเลขที่, ถนน"
          fullWidth={true}
          {...addressInputProps}
        />
      </Box>
      <PaymentUserAddressWrapper>
        <Autocomplete
          value={data?.province}
          options={options?.provinces}
          popupIcon={<SVGIcon name={SVGIcon.ICON_LIST.DROPDOWN_ARROW} />}
          disableClearable={!data?.province}
          renderInput={(params) => {
            const { InputLabelProps, ...inputProps } = params;
            const { error, helperText } = provinceInputProps;
            return (
              <TextInput
                {...inputProps}
                label="จังหวัด"
                fullWidth={true}
                error={error}
                helperText={helperText}
              />
            );
          }}
          {...provinceInputProps}
          onChange={handleProvinceChange}
        />
        <Autocomplete
          value={data?.amphur}
          options={options?.amphurs}
          disabled={!data?.province}
          popupIcon={<SVGIcon name={SVGIcon.ICON_LIST.DROPDOWN_ARROW} />}
          disableClearable={!data?.amphur}
          renderInput={(params) => {
            const { InputLabelProps, ...inputProps } = params;
            const { error, helperText } = amphurInputProps;
            return (
              <TextInput
                {...inputProps}
                label="เขต/อำเภอ"
                fullWidth={true}
                error={error}
                helperText={helperText}
              />
            );
          }}
          {...amphurInputProps}
          onChange={handleAmphurChange}
        />
        <Autocomplete
          value={data?.district}
          options={options?.districts}
          disabled={!data?.amphur}
          popupIcon={<SVGIcon name={SVGIcon.ICON_LIST.DROPDOWN_ARROW} />}
          disableClearable={!data?.district}
          renderInput={(params) => {
            const { InputLabelProps, ...inputProps } = params;
            const { error, helperText } = districtInputProps;
            return (
              <TextInput
                {...inputProps}
                label="แขวง/ตำบล"
                fullWidth={true}
                error={error}
                helperText={helperText}
              />
            );
          }}
          {...districtInputProps}
          onChange={handleDistrictChange}
        />
        <Autocomplete
          value={data?.zipcode}
          options={options?.zipcodes}
          disabled={!data?.district}
          popupIcon={<SVGIcon name={SVGIcon.ICON_LIST.DROPDOWN_ARROW} />}
          disableClearable={!data?.zipcode}
          renderInput={(params) => {
            const { InputLabelProps, ...inputProps } = params;
            const { error, helperText } = zipcodeInputProps;
            return (
              <TextInput
                {...inputProps}
                label="รหัสไปรษณีย์"
                fullWidth={true}
                error={error}
                helperText={helperText}
              />
            );
          }}
          {...zipcodeInputProps}
          onChange={handleZipcodeChange}
        />
      </PaymentUserAddressWrapper>
    </>
  );
};

export type { PaymentUserAddressProps };
export default PaymentUserAddress;
