import { useEffect } from 'react';

interface Props {
  to: string;
}

const ExternalRedirect = ({ to }: Props) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);
  return null;
};

export default ExternalRedirect;
