const PlayCircleOutline = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.38325 10.833L10.8333 7.99967L6.38325 5.16634V10.833ZM7.99992 14.6663C7.08881 14.6663 6.2277 14.4913 5.41658 14.1413C4.60547 13.7913 3.89714 13.3136 3.29159 12.708C2.68603 12.1025 2.20825 11.3941 1.85825 10.583C1.50825 9.7719 1.33325 8.91079 1.33325 7.99967C1.33325 7.08856 1.50825 6.22745 1.85825 5.41634C2.20825 4.60523 2.68603 3.8969 3.29159 3.29134C3.89714 2.68579 4.60547 2.20801 5.41658 1.85801C6.2277 1.50801 7.08881 1.33301 7.99992 1.33301C8.91103 1.33301 9.77214 1.50801 10.5833 1.85801C11.3944 2.20801 12.1027 2.68579 12.7083 3.29134C13.3138 3.8969 13.7916 4.60523 14.1416 5.41634C14.4916 6.22745 14.6666 7.08856 14.6666 7.99967C14.6666 8.91079 14.4916 9.7719 14.1416 10.583C13.7916 11.3941 13.3138 12.1025 12.7083 12.708C12.1027 13.3136 11.3944 13.7913 10.5833 14.1413C9.77214 14.4913 8.91103 14.6663 7.99992 14.6663ZM7.99992 13.6663C9.55547 13.6663 10.8888 13.1108 11.9999 11.9997C13.111 10.8886 13.6666 9.55523 13.6666 7.99967C13.6666 6.44412 13.111 5.11079 11.9999 3.99967C10.8888 2.88856 9.55547 2.33301 7.99992 2.33301C6.44436 2.33301 5.11103 2.88856 3.99992 3.99967C2.88881 5.11079 2.33325 6.44412 2.33325 7.99967C2.33325 9.55523 2.88881 10.8886 3.99992 11.9997C5.11103 13.1108 6.44436 13.6663 7.99992 13.6663Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlayCircleOutline;
