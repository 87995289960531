import styled from 'styled-components';
import { PriceData } from '../types';
import numeral from 'numeral';
import { Flex, Text, Grid } from '../../../Base';
import SVGIcon, { ICON_LIST } from '../../../SVGIcon';

interface WorkshopCardFooterProps {
  priceDetail: PriceData;
  isBought: boolean;
}

const WorkshopCardFooterWrapper = styled(Grid)`
  grid-template-columns: repeat(2, auto);
  grid-template-rows: minmax(auto, 25px);
  border-top: 1px solid ${({ theme }) => theme.colors.border.divider};
`;

const SellingPriceText = styled((props) => (
  <Text textStyle="body2" mr={1} color="text.darkTextAlt" as="span" {...props} />
))`
  text-decoration: line-through;
`;

const FreeText = styled((props) => (
  <Text textStyle="subtitle1" color="hsla(173, 67%, 40%, 1)" as="span" {...props} />
))`
  grid-column: 2 / 3;
  justify-self: flex-end;
`;

const PriceTextWrapper = styled((props) => (
  <Flex justifySelf="flex-end" alignItems="center" {...props} />
))`
  grid-column: 2 / 3;
`;

const EnrolledText = styled((props) => (
  <Flex color="system.success.700" alignItems="center" flex="0 0 auto" {...props} />
))`
  grid-column: 2 / 3;
  justify-self: flex-end;
`;

const getPriceLabel = (basePrice: number, salePrice: number): JSX.Element => {
  if (salePrice === null) {
    // in case of price is null will shows empty text
    return null;
  }

  if (salePrice === 0) {
    return <FreeText>ฟรี</FreeText>;
  }

  const hasDiscount = salePrice < basePrice;

  return (
    <PriceTextWrapper>
      {hasDiscount && (
        <SellingPriceText display={{ xs: 'none', sm: 'block' }}>
          {numeral(basePrice).format('0,0')}.-
        </SellingPriceText>
      )}
      <Text textStyle="subtitle1" color={hasDiscount ? 'text.redText' : undefined}>
        {numeral(salePrice).format('0,0')}.-
      </Text>
    </PriceTextWrapper>
  );
};

const WorkshopCardFooter = ({ priceDetail, isBought }: WorkshopCardFooterProps): JSX.Element => {
  const { basePrice, salePrice } = priceDetail || {};

  const shouldShowPriceText =
    basePrice !== null || basePrice !== undefined || salePrice !== undefined;

  if (isBought) {
    return (
      <WorkshopCardFooterWrapper py={2} px={{ _: 2, md: 4 }}>
        <EnrolledText>
          <SVGIcon name={ICON_LIST.CHECK_CIRCLE} width={14} height={14} />
          <Text textStyle="caption" as="span" pl={1} overflow="hidden" fontSize={14}>
            สมัครแล้ว
          </Text>
        </EnrolledText>
      </WorkshopCardFooterWrapper>
    );
  }

  return (
    <WorkshopCardFooterWrapper py={2} px={{ _: 2, md: 4 }}>
      {shouldShowPriceText && getPriceLabel(basePrice, salePrice)}
    </WorkshopCardFooterWrapper>
  );
};

export { WorkshopCardFooter };
