import { Helmet } from 'react-helmet';
import urlJoin from 'url-join';

import { useConfig } from '../../Contexts/ConfigContext';

const Meta = (): JSX.Element | null => {
  const { HTMLMeta } = useConfig();

  const resolveFavicon = (filename: string) =>
    HTMLMeta?.faviconPath ? urlJoin(HTMLMeta.faviconPath, filename) : filename;

  return (
    <Helmet>
      <title>{HTMLMeta?.title}</title>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="description" content={HTMLMeta?.description} />
      <meta name="twitter:card" content={HTMLMeta?.twitterCard} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={HTMLMeta?.OGTitle} />
      <meta property="og:description" content={HTMLMeta?.OGDescription} />
      <meta property="og:image" content={HTMLMeta?.OGImage} />
      <link rel="icon" href={resolveFavicon('favicon-16x16.png')} sizes="16x16" />
      <link rel="icon" href={resolveFavicon('favicon-32x32.png')} sizes="32x32" />
      <link rel="shortcut icon" href={resolveFavicon('favicon.ico')} />
      <link rel="apple-touch-icon" href={resolveFavicon('apple-touch-icon.png')} sizes="180x180" />
      <link rel="mask-icon" href={resolveFavicon('safari-pinned-tab.svg')} sizes="180x180" />
      <link rel="manifest" href={resolveFavicon('site.webmanifest')} />
      <meta name="msapplication-config" content={resolveFavicon('browserconfig.xml')} />
    </Helmet>
  );
};

export default Meta;
