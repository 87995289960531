import { Container } from '@skooldio/paas-shared-react-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { setPageTitle } from '../Utils/page';
import { PaymentProvider } from '../Contexts/PaymentProvider';
import PaymentFormProvider from '../Contexts/PaymentFormProvider';
import PurchaseDetails from '../Containers/PurchaseDetails';
import PaymentSummary from '../Containers/PaymentSummary';
import Grid from '../Components/Base/Grid';
import Text from '../Components/Base/Text';

const Purchase = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{setPageTitle(t('purchase_title'))}</title>
      </Helmet>
      <PaymentProvider>
        <PaymentFormProvider>
          <Container pt={7} px={{ _: 0, sm: 4 }}>
            <Text textStyle={'heading3'} pb={3} px={{ _: 4, sm: 0 }}>
              {t('purchase_title')}
            </Text>
            <Grid
              width={1}
              m="0 auto"
              justifyContent="space-between"
              gridTemplateColumns={{ _: '1fr', lg: 'minmax(450px, 780px) minmax(280px, 380px)' }}
              gridGap={5}
            >
              <PurchaseDetails />
              <PaymentSummary />
            </Grid>
          </Container>
        </PaymentFormProvider>
      </PaymentProvider>
    </>
  );
};

export default Purchase;
