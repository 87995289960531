const GoogleIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00094 3.48C10.6909 3.48 11.8309 4.21 12.4809 4.82L15.0209 2.34C13.4609 0.89 11.4309 0 9.00094 0C5.48094 0 2.44094 2.02 0.960938 4.96L3.87094 7.22C4.60094 5.05 6.62094 3.48 9.00094 3.48Z"
        fill="#EA4335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.64 9.20011C17.64 8.46011 17.58 7.92011 17.45 7.36011H9V10.7001H13.96C13.86 11.5301 13.32 12.7801 12.12 13.6201L14.96 15.8201C16.66 14.2501 17.64 11.9401 17.64 9.20011Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88 10.78C3.68336 10.207 3.58201 9.60571 3.58 8.99996C3.58 8.37996 3.69 7.77996 3.87 7.21996L0.96 4.95996C0.329739 6.21349 0.00100539 7.59691 0 8.99996C0 10.45 0.35 11.82 0.96 13.04L3.88 10.78Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99875 18C11.4288 18 13.4688 17.2 14.9587 15.82L12.1187 13.62C11.3587 14.15 10.3387 14.52 8.99875 14.52C6.61875 14.52 4.59875 12.95 3.87875 10.78L0.96875 13.04C2.44875 15.98 5.47875 18 8.99875 18Z"
        fill="#34A853"
      />
    </svg>
  );
};

export default GoogleIcon;
