import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { getFullThaiDateWithDay } from '@skooldio/paas-shared-react-components/lib/Utils/dateUtils';

import { ButtonFilled, Flex, Text } from '../Base';
import { CUSTOMER_TYPE, SKU_CATEGORY } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import { useConfig } from '../../Contexts/ConfigContext';
import { STOREFRONT_TYPE } from '../../Utils/constant';

import { ThankYouProps } from './types';

const StyledCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: white;
  }
`;

const getNextButtonText = (storefrontType: string, productType: string) => {
  // E_COMMERCE
  if (storefrontType === STOREFRONT_TYPE.E_COMMERCE) {
    return 'กลับสู่หน้าหลัก';
  }

  // LMS
  switch (productType) {
    case SKU_CATEGORY.ONLINE_COURSE:
      return 'เข้าสู่ห้องเรียน';
    case SKU_CATEGORY.COLLECTION:
    default:
      return 'ดูคอร์สของฉัน';
  }
};

const getDescriptionText = (productType, thaiDateString) => {
  switch (productType) {
    case SKU_CATEGORY.ONLINE_COURSE:
    case SKU_CATEGORY.COLLECTION:
    default:
      return 'คุณสามารถเริ่มเรียนคอร์สนี้ได้เลย';
  }
};

const getCheckPaymentDetailText = (customerType) => {
  switch (customerType) {
    case CUSTOMER_TYPE.CORPORATE:
      return 'กรุณาตรวจสอบการชำระและรายละเอียดการรับใบกำกับภาษีที่อีเมล';
    case CUSTOMER_TYPE.INDIVIDUAL:
    default:
      return 'กรุณาตรวจสอบการชำระที่อีเมล';
  }
};

const getEmailDetailText = (
  checkPaymentDetailText,
  customerType,
  isFreeCourse,
  shouldUserCreatePassword
) => {
  const createPasswordCorporateText =
    'กรุณาตั้งรหัสผ่านสำหรับบัญชีใหม่ของคุณ\nตรวจสอบการชำระและรายละเอียดการรับใบกำกับภาษีที่อีเมล';

  const createPasswordIndividualText = `กรุณาตั้งรหัสผ่านสำหรับบัญชีใหม่ของคุณ และตรวจสอบการชำระที่อีเมล`;
  const createPasswordFreeCourseText = `กรุณาตั้งรหัสผ่านสำหรับบัญชีใหม่ของคุณที่อีเมล`;

  if (shouldUserCreatePassword) {
    if (isFreeCourse) return createPasswordFreeCourseText;
    if (customerType === CUSTOMER_TYPE.CORPORATE) return createPasswordCorporateText;
    if (customerType === CUSTOMER_TYPE.INDIVIDUAL) return createPasswordIndividualText;
  }

  if (!isFreeCourse) {
    return checkPaymentDetailText;
  }

  return null;
};

// TODO: Update component naming Ref: https://github.com/Skooldio/paas-web-monorepo/pull/529#discussion_r788371941
const ThankYou = (props: ThankYouProps): JSX.Element => {
  const {
    isFreeCourse,
    isCourseEnrolling,
    shouldUserCreatePassword,
    productType = SKU_CATEGORY.ONLINE_COURSE,
    imageUrl,
    courseName,
    email,
    workshopDate,
    customerType = CUSTOMER_TYPE.INDIVIDUAL,
    onNext,
  } = props;

  const { storefrontType = STOREFRONT_TYPE.LMS } = useConfig();

  const thaiDateString = workshopDate ? getFullThaiDateWithDay(workshopDate) : '';
  const nextButtonText = getNextButtonText(storefrontType, productType);
  const description = getDescriptionText(productType, thaiDateString);

  const checkPaymentDetailText = getCheckPaymentDetailText(customerType);
  const checkEmailDetailText = getEmailDetailText(
    checkPaymentDetailText,
    customerType,
    isFreeCourse,
    shouldUserCreatePassword
  );

  return (
    <Flex
      maxWidth={'712px'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {imageUrl && (
        <img src={imageUrl} width={'100%'} style={{ maxWidth: '425px' }} alt={'Thank you'} />
      )}
      <Text mt={'42px'} textStyle="heading3" color="text.darkText">
        ชำระเงินสำเร็จ!
      </Text>
      <Text mt={4} textStyle="subtitle1" color="text.darkText" textAlign="center">
        {courseName}
      </Text>
      <Text mt={1} textStyle="body1" textAlign="center">
        {description}
      </Text>
      {checkEmailDetailText && (
        <>
          <Text mt={4} textStyle="body1" textAlign="center" style={{ 'white-space': 'pre-line' }}>
            {checkEmailDetailText}
          </Text>
          <Text
            textStyle="body1"
            fontWeight={600}
            textAlign="center"
            style={{ 'word-break': 'break-all' }}
          >
            {email}
          </Text>
        </>
      )}
      <ButtonFilled
        mt={'36px'}
        // TODO: Padding should be replaced by size variant
        py={'6px'}
        px={6}
        onClick={onNext}
        disabled={isCourseEnrolling}
      >
        {isCourseEnrolling ? (
          <Flex width={'94px'} justifyContent={'center'}>
            <StyledCircularProgress size={24} />
          </Flex>
        ) : (
          <Text textStyle="body1Medium">{nextButtonText}</Text>
        )}
      </ButtonFilled>
    </Flex>
  );
};

export { ThankYou };
