import { MenuList } from '@skooldio/paas-shared-react-components';

import useAppMenu, { mapMenuProps } from '../../Domain/useAppMenu';

const AppMenu = ({ onMenuItemClick }: { onMenuItemClick: () => void }): JSX.Element => {
  const { appMenu } = useAppMenu();

  return <MenuList menus={mapMenuProps(appMenu)} onClickMenuItem={onMenuItemClick} />;
};

export default AppMenu;
