import styled from 'styled-components';
import { EventTime } from './types';
import { Flex, Text } from '../../Base';
import SVGIcon, { ICON_LIST } from '../../SVGIcon';
import { getFormattedWorkshopDate } from '../../../Utils/Product';

const IconWrapper = styled(Flex)`
  flex: 0 0 auto;
  align-items: center;
`;

interface WorkshopDetailSectionProps {
  location?: string;
  eventTime?: EventTime;
  isWorkshopCard?: boolean;
}

const WorkshopDetailSection = (props: WorkshopDetailSectionProps): JSX.Element => {
  const { location, eventTime, isWorkshopCard } = props;
  const shouldShowLocation = location !== undefined && location !== null;

  const locationSection = shouldShowLocation ? (
    <Flex alignItems="center" color="text.darkText">
      <IconWrapper>
        <SVGIcon name={ICON_LIST.LOCATION} opacity="0.5" />
      </IconWrapper>
      <Text mx={2} as="span" overflow="hidden" textStyle="caption" sx={{ whiteSpace: 'nowrap' }}>
        {location}
      </Text>
    </Flex>
  ) : null;

  if (!eventTime) return locationSection;

  const dateString = getFormattedWorkshopDate(eventTime, isWorkshopCard);

  return (
    <Flex flexDirection={'column'} style={{ gap: `${!isWorkshopCard && '8px'}` }}>
      {locationSection}
      <Flex alignItems="center" color="text.darkText">
        <IconWrapper>
          <SVGIcon name={ICON_LIST.CALENDAR_OUTLINED} opacity="0.5" />
        </IconWrapper>
        <Text mx={2} as="span" overflow="hidden" textStyle="caption" sx={{ whiteSpace: 'nowrap' }}>
          {dateString}
        </Text>
      </Flex>
    </Flex>
  );
};

export { WorkshopDetailSection };
