import { Pagination } from '@skooldio/paas-shared-react-components';
import { usePurchaseHistoryPaging } from '../../Contexts/PurchaseHistoryPagingProvider';

const PurchaseHistoryPagination = (): JSX.Element | null => {
  const { totalPages, currentPage, updateCurrentPage } = usePurchaseHistoryPaging();
  if (totalPages < 2) {
    return null;
  }
  return (
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      updateCurrentPage={updateCurrentPage}
    />
  );
};

export default PurchaseHistoryPagination;
