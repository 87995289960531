const Success = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      stroke="#FAA919"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6016 25L18.7216 32.06L34.3816 16.5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 2C35.9167 2 46 13 46 24C46 35 37.75 46 24 46C10.25 46 2 35 2 24C2 13 12.0833 2 23.8167 2"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Success;
