import dayjs from 'dayjs';

import { formatDateRange } from '@skooldio/paas-shared-react-components/lib/Utils/dateUtils';

const getWorkshopEventTimeText = (eventTime) => {
  const workshopDateText = formatDateRange(eventTime?.startDate, eventTime?.endDate, 'Coming soon');
  const workshopTimeText = `${dayjs(eventTime?.startTime).format('HH.mm')}-${dayjs(
    eventTime?.endTime
  ).format('HH.mm')}`;

  return `${workshopDateText}, ${workshopTimeText}`;
};

export default getWorkshopEventTimeText;
