import styled, { css, useTheme } from 'styled-components';
import ReactPaginate from 'react-paginate';
import { variant } from 'styled-system';

import { Box } from '../Base';

import SVGIcon from '../SVGIcon';
import { PaginationProps, ArrowProps } from './types';

const paginationItemBackground = css`
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const PaginationWrapper = styled((props) => <Box my={9} mx="auto" {...props} />)`
  ul {
    display: grid;
    place-items: center;
    place-content: center;
    grid: 100% / repeat(auto-fit, minmax(20px, 40px));
    gap: ${({ theme }) => theme.space['3']}px;
    width: 100%;
    list-style: none;
    padding-left: 0;

    ${({ theme }) => css`
      @media screen and (min-width: ${theme.breakpoints.md}) {
        gap: ${({ theme }) => theme.space['6']}px;
      }
    `}
  }

  li {
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 0;
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    color: ${({ theme }) => theme.colors.text.pagination};
    cursor: pointer;
    a {
      width: 100%;
      padding: 0.5em 0;
      text-align: center;
      color: ${({ theme }) => theme.colors.text.pagination};
    }

    &.active {
      position: relative;

      a {
        ${() =>
          variant({
            variants: {
              default: { color: 'white' },
              textColorSecondary: { color: 'secondary.500' },
            },
          })}
      }

      &:after {
        ${paginationItemBackground};
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primary['700']};
      }
    }

    &:not(.active, .disabled):hover {
      position: relative;

      &:after {
        ${paginationItemBackground};
        background: ${({ theme }) => theme.colors.background.uiState.hover};
        color: ${({ theme }) => theme.colors.text.pagination};
      }
    }
    &.disabled {
      cursor: default;
    }
  }
`;

const Arrow = styled.div<ArrowProps>`
  transform: ${({ direction }) => (direction === 'previous' ? `rotate(180deg)` : `rotate(0deg)`)};
`;

const Pagination = (props: PaginationProps): JSX.Element => {
  const { totalPages = 0, currentPage, updateCurrentPage } = props;
  const handlePageClick = ({ selected }) => updateCurrentPage(selected);
  const theme = useTheme();

  if (totalPages === 0) return null;

  return (
    <PaginationWrapper variant={theme.variants.activePaginationText}>
      <ReactPaginate
        previousLabel={
          <Arrow direction="previous">
            <SVGIcon
              name="CHEVRON"
              stroke={
                currentPage === 0
                  ? theme.colors.icon.uiState.disabled
                  : theme.colors.icon.uiState.default
              }
            />
          </Arrow>
        }
        nextLabel={
          <Arrow>
            <SVGIcon
              name="CHEVRON"
              stroke={
                currentPage === totalPages - 1
                  ? theme.colors.icon.uiState.disabled
                  : theme.colors.icon.uiState.default
              }
            />
          </Arrow>
        }
        pageCount={totalPages}
        forcePage={currentPage}
        onPageChange={handlePageClick}
        activeClassName="active"
      />
    </PaginationWrapper>
  );
};

export default Pagination;
