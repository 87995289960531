const CloseIcon = (props: any): JSX.Element => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="#5EB7EF" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.707 5.293a1 1 0 010 1.414l-12 12a1 1 0 01-1.414-1.414l12-12a1 1 0 011.414 0z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.293 5.293a1 1 0 011.414 0l12 12a1 1 0 01-1.414 1.414l-12-12a1 1 0 010-1.414z"
      />
    </svg>
  );
};

export default CloseIcon;
