import styled from 'styled-components';
import NextImage from 'next/image';
import { Box } from '../../../Base';
import SVGIcon, { ICON_LIST } from '../../../SVGIcon';

const HeaderImage = styled(NextImage)`
  border-radius: 8px 8px 0 0;
  object-fit: cover;
`;

const EmptyBox = styled(Box)`
  border-radius: 8px 8px 0 0;
  object-fit: cover;
`;

interface WorkshopCardHeaderProps {
  coverImageUrl?: string;
  coverImageAltText?: string;
}

const WorkshopCardHeader = ({
  coverImageUrl,
  coverImageAltText,
}: WorkshopCardHeaderProps): JSX.Element => {
  if (!coverImageUrl)
    return (
      <EmptyBox width={278} height={157} bg="grayscale.100">
        <SVGIcon name={ICON_LIST.LIBRARY_BOOK} color="grayscale.300" />
      </EmptyBox>
    );

  return (
    <HeaderImage
      width={278}
      height={157}
      layout="responsive"
      alt={coverImageAltText}
      src={coverImageUrl}
      priority={true}
    />
  );
};

export { WorkshopCardHeader };
