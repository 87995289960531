import { useQuery } from '@apollo/client';

import { getMySalesOrders } from '../GraphQL/query/Purchase.query';
import { SFFoPaymentMySalesOrdersVariables } from './useMySalesOrderData.d';

const useMySalesOrderData = ({
  search = null,
  order = { field: 'orderNo', type: 'DESC' },
  paging = null,
  ...props
}: SFFoPaymentMySalesOrdersVariables = {}) => {
  const { data, error, loading, ...apolloUtils } = useQuery(getMySalesOrders, {
    variables: {
      search,
      order,
      paging,
    },
    ...props,
  });

  return {
    loading,
    error,
    data: data?.sfFoPaymentMySalesOrders,
    ...apolloUtils,
  };
};

export default useMySalesOrderData;
