import { forwardRef, RefObject } from 'react';
import SelectionInput from '../../Base/SelectionInput';

import { useController } from 'react-hook-form';
import { FormSelectionInputProps, FieldValues } from './types';

const BaseFormSelectionInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormSelectionInputProps<TFieldValues>,
  ref: RefObject<HTMLDivElement>
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    ...props,
    name: props.name,
  });
  return (
    <SelectionInput
      {...field}
      {...props}
      type={props?.type ?? 'text'}
      error={invalid}
      helperText={error?.message ?? null}
      ref={ref}
    />
  );
};

const FormSelectionInput = forwardRef(BaseFormSelectionInput);
export type { FormSelectionInputProps };
export default FormSelectionInput;
