import styled, { css } from 'styled-components';

import { useConfig } from '../../Contexts/ConfigContext';
import FormTextInput from '../../Components/FormInput/FormTextInput';
import Text from '../../Components/Base/Text';
import Grid from '../../Components/Base/Grid';
import Box from '../../Components/Base/Box';

// import { REGEX_TAX_IDENTIFICATION_NUMBER_FORMAT } from '../Utils/constants';
import PaymentAddress from '../PaymentAddress';
import { replacePatternsWithLinks } from '../Utils/replaceTextWithLinks';
import taxIdValidation from '../Utils/taxIdValidation';
import validateBranchInfo from '../Utils/validateBranchInfo';
import { BranchInfoOptionsType } from './types';

/* #region STYLING */
const PaymentCorporateAddressWrapper = styled(Grid)`
  ${({ theme }) => css`
    width: 100%;
    padding-top: ${theme.space[4]}px;
    padding-bottom: ${theme.space[4]}px;
    grid-gap: ${theme.space[4]}px;

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(2, auto);
    }
  `};
`;

const TaxInvoiceInfo = styled(Text)`
  ${({ theme }) => css`
    a: {
      color: ${theme.colors.system.info[500]};
      display: inline;
    }
  `}
`;

TaxInvoiceInfo.defaultProps = {
  textStyle: 'body2',
  color: 'text.darkText',
};
/* #endregion */

const PaymentCorporateAddress = () => {
  const { corporateNamePlaceholder, payment: paymentConfig } = useConfig();

  const taxInvoiceInfoTemplate = paymentConfig?.corporate?.taxInvoiceInfo ?? '';
  const taxInvoiceInfoOptions = paymentConfig?.corporate?.taxInvoiceInfoOptions ?? [];
  const taxInvoiceInfo = replacePatternsWithLinks(taxInvoiceInfoTemplate, taxInvoiceInfoOptions);

  const branchInfoOptions: BranchInfoOptionsType =
    paymentConfig?.corporate?.branchInfoOptions ?? {};

  const branchInputLabel = branchInfoOptions?.branchInputLabel ?? 'สำนักงาน/สาขาเลขที่';
  const branchInputPlaceholder = branchInfoOptions?.branchInputPlaceholder ?? 'สำนักงานใหญ่';
  const validator = branchInfoOptions?.branchNameValidation ?? [];

  return (
    <Box>
      {taxInvoiceInfo && (
        <Box px={4} py={2} mb={4} bg="grayscale.100" style={{ borderRadius: '8px' }}>
          <TaxInvoiceInfo dangerouslySetInnerHTML={{ __html: taxInvoiceInfo }} />
        </Box>
      )}
      <FormTextInput
        name="companyInfo.name"
        label="ชื่อบริษัท"
        placeholder={corporateNamePlaceholder}
        rules={{
          required: 'กรุณากรอกชื่อบริษัท',
        }}
      />
      <PaymentCorporateAddressWrapper>
        <FormTextInput
          name="companyInfo.taxId"
          label="เลขประจำตัวผู้เสียภาษี"
          placeholder="กรอกเลขประจำตัวผู้เสียภาษี 13 หลัก"
          rules={{
            required: 'กรุณากรอกเลขประจำตัวผู้เสียภาษี',
            validate: {
              checkSum: (value: string) => taxIdValidation(value),
            },
          }}
          inputProps={{ maxLength: 13 }}
        />
        <FormTextInput
          name="companyInfo.branch"
          label={branchInputLabel}
          defaultValue="สำนักงานใหญ่"
          placeholder={branchInputPlaceholder}
          rules={{
            required: 'กรุณากรอกสำนักงาน/สาขาเลขที่',
            validate: (branchValue: string) =>
              validateBranchInfo({ value: branchValue, validator }),
          }}
        />
      </PaymentCorporateAddressWrapper>
      <PaymentAddress
        scope="companyInfo"
        addressInputProps={{ label: 'ที่อยู่ (สำหรับออกใบกำกับภาษี)' }}
      />
      <Text textStyle="body2" color="text.darkText" pt={2}>
        กรุณาตรวจสอบข้อมูลเพื่อความถูกต้องสมบูรณ์ ก่อนชำระเงิน
      </Text>
    </Box>
  );
};

export default PaymentCorporateAddress;
