const PeopleGroup = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.334 10a1.61 1.61 0 0 1-1.183-.483 1.61 1.61 0 0 1-.484-1.184c0-.466.161-.86.484-1.183a1.61 1.61 0 0 1 1.183-.483c.467 0 .861.16 1.183.483.322.322.484.717.484 1.183 0 .467-.162.861-.484 1.184a1.61 1.61 0 0 1-1.183.483Zm-3.333 3.333V12.4c0-.267.069-.514.208-.741.139-.228.336-.392.592-.492.4-.167.814-.292 1.242-.375a6.713 6.713 0 0 1 2.583 0c.428.083.841.208 1.241.375.256.1.453.264.592.492.139.227.208.474.208.741v.933H8.001ZM6.667 8a2.568 2.568 0 0 1-1.883-.783 2.568 2.568 0 0 1-.783-1.884c0-.733.26-1.36.783-1.883a2.568 2.568 0 0 1 1.883-.783c.734 0 1.361.26 1.884.783.522.522.783 1.15.783 1.883 0 .734-.261 1.361-.783 1.884A2.568 2.568 0 0 1 6.667 8Zm-5.333 5.333v-1.866c0-.378.094-.726.283-1.042.19-.317.45-.558.784-.725a10.021 10.021 0 0 1 2.075-.767 9.065 9.065 0 0 1 2.191-.266c.39 0 .778.033 1.167.1.389.066.778.144 1.167.233l-1.134 1.133c-.2-.055-.4-.091-.6-.108a7.698 7.698 0 0 0-2.491.209 8.437 8.437 0 0 0-1.775.666.566.566 0 0 0-.25.233.675.675 0 0 0-.084.334V12h4v1.333H1.334Zm5.333-6.666c.367 0 .681-.131.942-.392.261-.261.392-.575.392-.942 0-.366-.13-.68-.392-.941A1.284 1.284 0 0 0 6.667 4c-.366 0-.68.13-.941.392-.261.26-.392.575-.392.941 0 .367.13.68.392.942.26.261.575.392.941.392Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PeopleGroup;
