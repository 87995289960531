import { LoadingModal } from '@skooldio/paas-shared-react-components';
import styled from 'styled-components';

import { useConfig } from '../../Contexts/ConfigContext/ConfigProvider';
import PurchaseOrderList from '../PurchaseOrderList';
import PaymentMethod from '../PaymentMethod';
import PaymentUserInfo from '../PaymentUserInfo';

import { usePayment } from '../../Contexts/PaymentProvider';
import { useAuth } from '../../Contexts/AuthContext';

import Grid from '../../Components/Base/Grid';
import TaxInvoice from '../TaxInvoice';
import TaxInvoiceInfo from '../TaxInvoiceInfo/TaxInvoiceInfo';
import PaymentType from '../PaymentType';

const PurchaseDetailsContainer = styled((props) => (
  <Grid
    w={1}
    bg="hsla(0, 0%, 100%, 1)"
    p={{ _: 6, sm: 2, md: 7 }}
    borderRadius={{ _: 0, sm: '8px' }}
    gridTemplateRows="auto"
    gridRowGap={7}
    {...props}
  />
))``;

const PurchaseDetails = () => {
  const { priceInclVAT, paymentAmount, isPreSalesOrderProcessing } = usePayment();
  const { enableAnonymousUser } = useConfig();
  const { isLoggedIn } = useAuth();
  const isFreeCourse = typeof priceInclVAT === 'number' && priceInclVAT === 0;

  if (isPreSalesOrderProcessing) {
    return <LoadingModal isOpen={true} />;
  }

  if (isFreeCourse) {
    return (
      <PurchaseDetailsContainer>
        <PurchaseOrderList />
        {enableAnonymousUser && !isLoggedIn && <PaymentUserInfo />}
      </PurchaseDetailsContainer>
    );
  }

  if (paymentAmount === 0) {
    return (
      <PurchaseDetailsContainer>
        <PurchaseOrderList />
        <Grid as="section" gridGap={6} gridTemplateColumns="1fr">
          <PaymentUserInfo />
        </Grid>
      </PurchaseDetailsContainer>
    );
  }

  return (
    <PurchaseDetailsContainer>
      <PurchaseOrderList />
      <Grid as="section" gridGap={6} gridTemplateColumns="1fr">
        <PaymentUserInfo>
          <PaymentType />
        </PaymentUserInfo>
        <TaxInvoice />
        <TaxInvoiceInfo />
      </Grid>
      <PaymentMethod />
    </PurchaseDetailsContainer>
  );
};

export default PurchaseDetails;
