import styled from 'styled-components';

import { WorkshopCategory } from '../WorkshopCategory';
import { WorkshopInformation } from '../WorkshopInformation';
import { WorkshopInstructor } from '../WorkshopInstructor';
import { EventTime, WorkshopCardCategoryData, WorkshopCardInstructorData } from '../types';
import { WorkshopDetailSection } from '../WorkshopDetailSection';
import { Box, Grid } from '../../../Base';

interface WorkshopCardBodyProps {
  title: string;
  subTitle: string;
  instructors: WorkshopCardInstructorData[];
  category: WorkshopCardCategoryData;
  location?: string;
  eventTime?: EventTime;
  isWorkshopCard?: boolean;
}

const WorkshopCardInstructorMobile = styled(Box)`
  align-self: flex-end;
`;

const WorkshopCardDetailMobile = styled(Box)`
  align-self: flex-end;
  padding-bottom: 8px;
`;

const WorkshopCardBody = ({
  title,
  subTitle,
  instructors,
  category,
  location,
  eventTime,
  isWorkshopCard = true,
}: WorkshopCardBodyProps): JSX.Element => {
  const shouldShowDetail = location !== undefined || eventTime !== undefined;

  return (
    <Grid
      gridTemplateRows={{
        sm: 'minmax(auto, 20px) minmax(auto, 120px) minmax(auto, 32px)',
      }}
      gridTemplateColumns="100%"
      gridGap={1}
      px={{ _: 3, sm: 4 }}
      py={{ _: 2, sm: 4 }}
    >
      <WorkshopCategory categoryData={category} />
      <WorkshopInformation title={title} subTitle={subTitle} />
      {shouldShowDetail ? (
        <WorkshopCardDetailMobile>
          <WorkshopDetailSection
            location={location}
            eventTime={eventTime}
            isWorkshopCard={isWorkshopCard}
          />
        </WorkshopCardDetailMobile>
      ) : null}
      <WorkshopCardInstructorMobile>
        <WorkshopInstructor instructorData={instructors} />
      </WorkshopCardInstructorMobile>
    </Grid>
  );
};

export { WorkshopCardBody };
