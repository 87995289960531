export enum CLASSROOM_MODE {
  TRIAL = 'TRIAL',
  FULL = 'FULL',
  ALL = 'ALL',
}

export interface MyCourseDataInputProps {
  courseCode?: string;
}

interface CourseInfo {
  courseId: string;
  courseCode: string;
}

interface MyCourse {
  courseInfo: CourseInfo;
}
export interface MyCoursesQuery {
  myCourses: MyCourse[];
}
export interface MyCoursesInput {
  mode: CLASSROOM_MODE;
}

export type MyCourseByCourseCode = (myCoursesData: MyCoursesQuery, courseCode: string) => MyCourse;
