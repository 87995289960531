const Location = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 7.99984C8.4125 7.99984 8.76575 7.8561 9.05975 7.56864C9.35325 7.28166 9.5 6.9365 9.5 6.53317C9.5 6.12984 9.35325 5.78444 9.05975 5.49697C8.76575 5.20999 8.4125 5.0665 8 5.0665C7.5875 5.0665 7.2345 5.20999 6.941 5.49697C6.647 5.78444 6.5 6.12984 6.5 6.53317C6.5 6.9365 6.647 7.28166 6.941 7.56864C7.2345 7.8561 7.5875 7.99984 8 7.99984ZM8 13.3898C9.525 12.0209 10.6562 10.7772 11.3937 9.65864C12.1312 8.54055 12.5 7.54761 12.5 6.67984C12.5 5.34761 12.0655 4.25666 11.1965 3.40697C10.328 2.55777 9.2625 2.13317 8 2.13317C6.7375 2.13317 5.67175 2.55777 4.80275 3.40697C3.93425 4.25666 3.5 5.34761 3.5 6.67984C3.5 7.54761 3.86875 8.54055 4.60625 9.65864C5.34375 10.7772 6.475 12.0209 8 13.3898ZM8 15.3332C5.9875 13.6587 4.4845 12.1033 3.491 10.667C2.497 9.2311 2 7.90206 2 6.67984C2 4.8465 2.60325 3.38595 3.80975 2.29817C5.01575 1.21039 6.4125 0.666504 8 0.666504C9.5875 0.666504 10.9843 1.21039 12.1903 2.29817C13.3967 3.38595 14 4.8465 14 6.67984C14 7.90206 13.5033 9.2311 12.5098 10.667C11.5157 12.1033 10.0125 13.6587 8 15.3332Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Location;
