import { useMemo } from 'react';
import styled from 'styled-components';
import { DividerWithText } from '@skooldio/paas-shared-react-components';

import { ButtonFilled, Flex, Text } from '../Base';
import { useConfig } from '../../Contexts/ConfigContext';
import { Category } from '../../Domain/useMySalesOrder/types';

import { ThankYouWithProductInfoProps } from './types';
import { useTranslation } from 'react-i18next';

const Divider = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.formDivider};
`;

const CategoryList = styled(Flex)`
  flex-wrap: wrap;
  span:not(:last-child)::after {
    content: '•';
    margin: 0 8px;
  }
`;

const getTitles = (data: Category[]): string[] => {
  const validPermalinks = ['category1', 'category2'];

  return data
    .filter((item) => validPermalinks.includes(item.parent.permalink))
    .flatMap((item) => item.categories.map((category) => category.title));
};

const ThankYouWithProductInfo = (props: ThankYouWithProductInfoProps): JSX.Element => {
  const { orderNo, productItem, shouldUserCreatePassword, email, onNext } = props;

  const { t } = useTranslation();
  const { defaultPaymentSuccessImageUrl } = useConfig();

  const { title, product } = productItem ?? {};
  const { categories, cardImageUrl } = product ?? {};

  const categoryTitles = useMemo(() => getTitles(categories ?? []), [categories]);

  return (
    <Flex
      width="100%"
      p={{ _: '32px 16px', sm: '60px 108px' }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg={{ _: 'transparent', sm: 'white' }}
      borderRadius="16px"
      border={{ _: 'none', sm: '1px solid #EAEAEA' }}
    >
      <CheckCircleGreenIcon />
      <Text mt="28px" textStyle="heading5" color="text.darkText">
        {t('payment_successful')}
      </Text>

      {orderNo && (
        <Text mt="20px" textStyle="body2" color="grayscale.700">
          {t('order_number')}:{' '}
          <Text as="span" color="text.darkText" fontWeight="bold">
            {orderNo}
          </Text>
        </Text>
      )}

      <Flex flexDirection="column" width="100%" maxWidth="360px">
        <img
          src={cardImageUrl ?? defaultPaymentSuccessImageUrl}
          style={{ width: '100%', marginTop: '20px', borderRadius: '8px' }}
          alt={title}
        />
        <CategoryList mt={4}>
          {categoryTitles.map((categoryTitle) => (
            <Text as="span" key={categoryTitle} textStyle="caption" color="grayscale.700">
              {categoryTitle}
            </Text>
          ))}
        </CategoryList>

        <Text mt={1} fontWeight="bold">
          {title}
        </Text>

        <Divider mt={5} mb="28px" />

        <Text textStyle="body2" color="grayscale.700" textAlign="center">
          {shouldUserCreatePassword
            ? t('payment_set_password_and_review_payment')
            : t('payment_check_email_payment_confirmation')}
          <br />
          <Text as="strong" color="text.darkText">
            {email}
          </Text>
        </Text>

        {shouldUserCreatePassword && <DividerWithText text="or" mt={4} />}

        <ButtonFilled
          onClick={onNext}
          marginTop={shouldUserCreatePassword ? '12px' : '28px'}
          fontWeight="600"
        >
          Start Practicing
        </ButtonFilled>
      </Flex>
    </Flex>
  );
};

const CheckCircleGreenIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill="#00C558"
      fillRule="evenodd"
      d="M32 58.667C17.272 58.667 5.333 46.727 5.333 32 5.333 17.272 17.273 5.333 32 5.333c14.728 0 26.667 11.94 26.667 26.667 0 14.728-11.94 26.667-26.667 26.667ZM0 32C0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32Zm47.664-8.114a2.667 2.667 0 1 0-3.771-3.772L25 39.007l-7.558-7.56a2.667 2.667 0 1 0-3.772 3.772l9.445 9.444a2.667 2.667 0 0 0 3.771 0l20.778-20.777Z"
      clipRule="evenodd"
    />
  </svg>
);

export { ThankYouWithProductInfo };
