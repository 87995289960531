const SadFace = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width={48} height={48} fill="#2A65FE" {...props}>
      <path d="M31 22a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM17 22a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
      <path d="M24 28c-4.66 0-8.64 2.9-10.24 7h3.34c1.38-2.38 3.94-4 6.9-4 2.96 0 5.5 1.62 6.9 4h3.34c-1.6-4.1-5.58-7-10.24-7Zm-.02-24C12.94 4 4 12.96 4 24s8.94 20 19.98 20C35.04 44 44 35.04 44 24S35.04 4 23.98 4ZM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16Z" />
    </svg>
  );
};

export default SadFace;
