import styled, { useTheme, css } from 'styled-components';
import { variant } from 'styled-system';

// import SearchBox from 'Containers/SearchBox';
import { AccountMenuProvider } from '../../Contexts/AccountMenuContext';
import { useConfig } from '../../Contexts/ConfigContext';
import SiteLogo from '../../Components/SiteLogo';
import Grid from '../../Components/Base/Grid';
import Box from '../../Components/Base/Box';

import UserButton from '../UserButton';
import MenuSideNav from '../MenuSideNav';

import Navigation from './Navigation';

const HeaderContainer = styled((props) => (
  <Grid
    maxWidth={{ _: 'none', md: 1200 }}
    width={1}
    m="0 auto"
    py={2}
    px={4}
    gridTemplateColumns={{
      _: 'minmax(100px,1fr) 50px min-content',
      sm: 'minmax(120px, 200px) 1fr minmax(auto, 120px)',
    }}
    alignItems="center"
    gridGap={{ _: 2, md: 4 }}
    {...props}
  />
))`
  justify-items: flex-start;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.md}) {
      place-items: center;
      justify-items: center;
    }
  `}
`;

const HeaderBox = styled(Box)`
  ${() =>
    variant({
      variants: {
        default: { backgroundColor: 'white' },
        backgroundColorSecondary: { backgroundColor: 'secondary.500' },
      },
    })}
`;

const Header = ({
  disabledNavigationAppMenu,
}: {
  disabledNavigationAppMenu: boolean;
}): JSX.Element => {
  const theme = useTheme();
  const { brandLogoHref, brandLogoSrcSet } = useConfig();
  return (
    <header>
      <HeaderBox variant={theme.variants.navigationBar}>
        <HeaderContainer>
          <SiteLogo
            logoImageSrcSet={brandLogoSrcSet}
            logoImageHeight={56}
            logoImageWidth={142}
            logoHref={brandLogoHref}
          />
          {/* <SearchBox /> */}
          <span></span>
          <AccountMenuProvider>
            {/* mobile */}
            <MenuSideNav />
            {/* desktop */}
            <UserButton />
          </AccountMenuProvider>
        </HeaderContainer>
      </HeaderBox>
      <Navigation disabledAppMenu={disabledNavigationAppMenu} />
    </header>
  );
};

export default Header;
