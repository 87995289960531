import ReactGA from 'react-ga';

const initialize = (gaTrackingIds) => {
  ReactGA.initialize(gaTrackingIds);
};

const sendEvent = (payload) => {
  ReactGA.event(payload, ['PaaSTracker', 'CustomerTracker']);
};

const sendPageview = (path) => {
  ReactGA.pageview(path, ['PaaSTracker', 'CustomerTracker']);
};

export { initialize, sendEvent, sendPageview };
