import styled from 'styled-components';

import { WorkshopCardBody } from './WorkshopCardBody';
import { WorkshopCardData } from '../types';
import { WorkshopCardHeader } from './WorkshopCardHeader';
import { WorkshopCardFooter } from './WorkshopCardFooter';
import { Link } from '../../../Base';

const WorkshopCardWrapper = styled(Link)`
  filter: drop-shadow(0px 4px 24px rgba(17, 17, 26, 0.025));
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 8px, rgba(17, 17, 26, 0.025) 0px 4px 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text.darkText};
  background-color: ${({ theme }) => theme.colors.background.card};
  transition: all 200ms ease-in-out;

  & > div:first-child {
    display: block !important;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text.darkText};
    box-shadow: 0 16px 16px 0 rgba(32, 32, 32, 0.03), 0 8px 8px 0 rgba(42, 42, 42, 0.03),
      0 4px 4px 0 rgba(49, 49, 49, 0.03), 0 2px 2px 0 rgba(45, 45, 45, 0.03),
      0 64px 64px 0 rgba(35, 35, 35, 0.03), 0 32px 32px 0 rgba(49, 49, 49, 0.03);
    transform: translateY(-7px);
  }
  &:active {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  }
`;

interface WorkshopCardProps {
  workshopData?: WorkshopCardData;
  shouldShowPriceText?: boolean;
  href: string;
}

const WorkshopCard = ({
  workshopData,
  shouldShowPriceText = true,
  href,
}: WorkshopCardProps): JSX.Element => {
  const {
    title,
    cardImageUrl,
    subTitle,
    instructorDetail,
    category,
    price,
    isBought = false,
    location,
    eventTime,
    ...props
  } = workshopData;

  return (
    <WorkshopCardWrapper href={href} passHref {...props}>
      <WorkshopCardHeader coverImageAltText={title} coverImageUrl={cardImageUrl} />
      <WorkshopCardBody
        title={title}
        subTitle={subTitle}
        instructors={instructorDetail}
        category={category}
        location={location}
        eventTime={eventTime}
      />
      {shouldShowPriceText ? <WorkshopCardFooter priceDetail={price} isBought={isBought} /> : null}
    </WorkshopCardWrapper>
  );
};

export type { WorkshopCardProps };
export { WorkshopCard };
