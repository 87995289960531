import gql from 'graphql-tag';

const SFFoPaymentGet2C2PRedirectURL = gql`
  mutation sfFoPaymentGet2C2PRedirectURL($input: SFFoPaymentGet2C2PRedirectUrlInput!) {
    sfFoPaymentGet2C2PRedirectURL(input: $input) {
      redirectUrl
    }
  }
`;

export { SFFoPaymentGet2C2PRedirectURL };
