import { ButtonFilled, ButtonText } from '../../Base/Button';
import { Box, Flex } from '../../Base';

interface ModalActionButtonsProps {
  primaryButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  isPrimaryDisabled?: boolean;
}

const ModalActionButtons = (props: ModalActionButtonsProps) => {
  const {
    primaryButtonLabel,
    onPrimaryButtonClick,
    secondaryButtonLabel,
    onSecondaryButtonClick,
    isPrimaryDisabled,
    ...restProps
  } = props;

  return (
    <Flex
      flexDirection={{ _: 'column-reverse', md: 'row' }}
      justifyContent={'center'}
      {...restProps}
    >
      {secondaryButtonLabel && (
        <ButtonText
          minWidth={160}
          borderRadius={25}
          color="primary.500"
          textColor="secondary.500"
          onClick={onSecondaryButtonClick}
          fontWeight={500}
        >
          {secondaryButtonLabel}
        </ButtonText>
      )}
      {secondaryButtonLabel && primaryButtonLabel && (
        <Box pl={{ _: 0, md: 4 }} pt={{ _: 4, md: 0 }} />
      )}
      {primaryButtonLabel && (
        <ButtonFilled
          minWidth={160}
          borderRadius={25}
          onClick={onPrimaryButtonClick}
          color="primary"
          textColor="white"
          disabled={isPrimaryDisabled}
          fontWeight={600}
        >
          {primaryButtonLabel}
        </ButtonFilled>
      )}
    </Flex>
  );
};

export default ModalActionButtons;
