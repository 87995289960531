import { FooterMenuList } from '@skooldio/paas-shared-react-components';
import { useTranslation } from 'react-i18next';

import { useConfig } from '../../Contexts/ConfigContext';

const useFooterMenu = (): FooterMenuList => {
  const { footerNavigation, external } = useConfig();
  const { t } = useTranslation();

  const footerMenuList: FooterMenuList = [];
  if (footerNavigation?.contactUsURL) {
    footerMenuList.push({ menuTitle: 'ติดต่อเรา', permalink: footerNavigation?.contactUsURL });
  }
  if (footerNavigation?.aboutURL) {
    footerMenuList.push({ menuTitle: 'เกี่ยวกับเรา', permalink: footerNavigation?.aboutURL });
  }
  if (footerNavigation?.faqsURL) {
    footerMenuList.push({ menuTitle: 'คำถามที่พบบ่อย', permalink: footerNavigation?.faqsURL });
  }
  if (external?.termsUrl) {
    footerMenuList.push({
      menuTitle: t('footer_menu_list_terms_and_conditions'),
      permalink: external?.termsUrl,
    });
  }
  if (external?.privacyUrl) {
    footerMenuList.push({
      menuTitle: t('footer_menu_list_privacy_policy'),
      permalink: external?.privacyUrl,
    });
  }
  return footerMenuList;
};

export default useFooterMenu;
