import { useTheme } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import MuiLoading from '@material-ui/core/CircularProgress';

import { Flex, Text, Grid, Box, ButtonOutlined } from '../../Components/Base';
import SVGIcon, { ICON_LIST } from '../SVGIcon';

import { DownloadAppMethodProps, SupportedPlatformProps } from './types';
import { APPLICATION_STORE_BADGE_SVG_NAME } from './constants';
import DividerWithText from '../DividerWithText';

const DownloadAppMethod = ({
  title,
  description: inputDescription,
  appImageUrl,
  supportedPlatforms = [],
  classroomUrl,
  isCourseEnrolling,
  onClose,
}: DownloadAppMethodProps): JSX.Element => {
  const theme = useTheme();

  const isAppMethodAvailable = supportedPlatforms.length !== 0;
  const isWebMethodAvailable = !!classroomUrl || isCourseEnrolling;

  let imageUrl = '/static/images/skooldio-app-icon.png';
  let description = '';
  let shouldShowAppOptions = false;
  let shouldShowClassroomButton = false;

  if (isAppMethodAvailable && isWebMethodAvailable) {
    // app + web
    imageUrl = appImageUrl || '/static/images/skooldio-app-icon.png';
    description =
      inputDescription ??
      'เราได้รองรับทุกระบบเพื่อให้ผู้เรียนสะดวกกับการเรียน ทุกที่ ทุกเวลา ดาวน์โหลดแอปได้เลยวันนี้';
    shouldShowAppOptions = true;
    shouldShowClassroomButton = true;
  } else if (isAppMethodAvailable) {
    // app only
    imageUrl = appImageUrl || '/static/images/skooldio-app-icon.png';
    description =
      inputDescription ??
      'เราได้รองรับทุกระบบเพื่อให้ผู้เรียนสะดวกกับการเรียน ทุกที่ ทุกเวลา ดาวน์โหลดแอปได้เลยวันนี้';
    shouldShowAppOptions = true;
  } else if (isWebMethodAvailable) {
    // web only
    imageUrl = '/static/images/webAvialable.png';
    description = '';
    shouldShowClassroomButton = true;
  }

  return (
    <Flex
      width={1}
      maxWidth="560px"
      flexDirection="column"
      justifyContent="column"
      alignItems="center"
      px={{ _: 4, sm: 11 }}
      py={7}
      border="1px solid"
      borderColor="border.formDivider"
      borderRadius="16px"
      backgroundColor="background.card"
    >
      {onClose && (
        <IconButton onClick={onClose} style={{ position: 'absolute', top: 5, right: 5 }}>
          <SVGIcon name={ICON_LIST.CLOSE} fill={theme.colors.icon.uiState.default} />
        </IconButton>
      )}
      {title && (
        <Text textStyle="body1" color="text.darkText" pb={1}>
          {title}
        </Text>
      )}
      <Text textStyle="body1Medium" color="text.darkText" pb={7}>
        เริ่มเรียนกันเลย!
      </Text>
      <img src={imageUrl} alt={'app-icon'} />
      {description && (
        <Text
          textStyle="body2"
          color={'text.darkTextAlt'}
          pt={4}
          textAlign={'center'}
          style={{ whiteSpace: 'pre-line' }}
        >
          {description}
        </Text>
      )}
      <Box pb={6} />
      {shouldShowAppOptions && (
        <Grid
          width={'100%'}
          gridTemplateRows="repeat(2, auto)"
          gridTemplateColumns="repeat(2, auto)"
          gridGap="8px"
          justifyContent="center"
        >
          {supportedPlatforms?.map(({ name: platformName, url }) => (
            <a
              href={url}
              target="_blank"
              key={APPLICATION_STORE_BADGE_SVG_NAME[platformName]}
              rel="noreferrer"
            >
              <SVGIcon name={APPLICATION_STORE_BADGE_SVG_NAME[platformName]} />
            </a>
          ))}
        </Grid>
      )}
      {shouldShowAppOptions && shouldShowClassroomButton && (
        <DividerWithText text="หรือ" mt={3} mb={5} />
      )}
      {shouldShowClassroomButton &&
        (isCourseEnrolling ? (
          <MuiLoading size="36px" style={{ color: theme.colors.primary[500] }} />
        ) : (
          <ButtonOutlined
            width={'100%'}
            color="button.outlined"
            style={{ border: '2px solid' }}
            onClick={() => {
              if (classroomUrl) {
                window.location.href = classroomUrl;
              }
            }}
          >
            <Text textStyle="body1Medium">เริ่มเรียนผ่านเว็บ</Text>
          </ButtonOutlined>
        ))}
    </Flex>
  );
};

export type { SupportedPlatformProps };
export default DownloadAppMethod;
