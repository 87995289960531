import gql from 'graphql-tag';

export const SalesOrderFragment = gql`
  fragment SalesOrderFragment on SFFoPaymentSalesOrder {
    id
    orderNo
    status
    paymentAmount
    discountCode
    discountInclVAT
    VAT
    priceInclVAT
    totalAmountExclVAT
    unit
    paymentMethod
    customerEmail
    customerType
    items {
      id
      SKUCode
      SKUId
      title
      product {
        SKUCategory
        cardImageUrl
        permalink
        baseReferSKUId
        cardImage {
          key
          bucket
        }
        eventTime {
          startDate
        }
        categories {
          SKUCategory
          parent {
            title
            itemNo
            permalink
          }
          categories {
            permalink
            status
            itemNo
            title
          }
        }
      }
      parentProduct {
        cardImage {
          key
          bucket
        }
        cardImageUrl
        coverImage {
          key
          bucket
        }
        coverImageUrl
      }
    }
    createdAt
    expirationDate
    paidAt
    updatedAt
    shipments {
      id
      SKUCode
      shipmentMethod
      status
      delivery {
        shipmentProvider
        trackingNumber
        trackingURL
      }
    }
  }
`;

export const getMySalesOrders = gql`
  query sfFoPaymentMySalesOrders(
    $order: OrderInput
    $paging: PageInput
    $search: SFFoPaymentSearchSalesOrdersInput
  ) {
    sfFoPaymentMySalesOrders(order: $order, paging: $paging, search: $search) {
      total
      salesOrders {
        ...SalesOrderFragment
      }
    }
  }
  ${SalesOrderFragment}
`;

export const getMySalesOrder = gql`
  query sfFoPaymentMySalesOrder($orderNo: String!) {
    sfFoPaymentMySalesOrder(orderNo: $orderNo) {
      items {
        parentProduct {
          permalink
        }
      }
      ...SalesOrderFragment
    }
  }
  ${SalesOrderFragment}
`;

export const getMySalesOrderStatus = gql`
  query sfFoPaymentMySalesOrder($orderNo: String!) {
    sfFoPaymentMySalesOrder(orderNo: $orderNo) {
      id
      status
    }
  }
`;
