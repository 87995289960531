import { useTheme } from 'styled-components';

import { usePayment } from '../../Contexts/PaymentProvider';
import { useConfig } from '../../Contexts/ConfigContext';

import { CUSTOMER_TYPE } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import { FormControlLabel, RadioGroup, Radio, makeStyles, BoxProps } from '@material-ui/core';
import { Box, Text } from '@skooldio/paas-shared-react-components';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = (): ClassNameMap<'radio'> => {
  const theme = useTheme();
  const classes = makeStyles({
    radio: {
      '&&': { color: theme.colors.primary[500] },
    },
  })();

  return classes;
};

const PaymentType = (props: BoxProps) => {
  const { enablePurchaseAsCorporate } = useConfig();
  const { customerType, updateCustomerType, updateTaxInvoiceRequested } = usePayment();
  const classes = useStyles();

  if (!enablePurchaseAsCorporate) return null;

  return (
    <Box {...props}>
      <Text textStyle="subtitle1">ประเภท</Text>
      <RadioGroup
        row
        value={customerType}
        onChange={(e) => {
          updateTaxInvoiceRequested(false);
          updateCustomerType(e.target.value as CUSTOMER_TYPE);
        }}
      >
        <FormControlLabel
          value={CUSTOMER_TYPE.INDIVIDUAL}
          control={<Radio classes={{ checked: classes.radio }} />}
          label={
            <Text textStyle="body2" color="text.darkText">
              บุคคลทั่วไป
            </Text>
          }
        />
        <Box ml={5} />
        <FormControlLabel
          value={CUSTOMER_TYPE.CORPORATE}
          control={<Radio classes={{ checked: classes.radio }} />}
          label={
            <Text textStyle="body2" color="text.darkText">
              นิติบุคคล/บริษัท
            </Text>
          }
        />
      </RadioGroup>
    </Box>
  );
};

export default PaymentType;
