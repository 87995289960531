import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

const DEFAULT_API_URL = 'https://api.soylatte.skooldio.com/store/graphql';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const persistedQueriesLink = createPersistedQueryLink({ sha256 });

export function createApolloClient(apiUrl) {
  const httpLink = new HttpLink({
    uri: apiUrl ?? DEFAULT_API_URL, // Server URL (must be absolute)
    credentials: 'include', // Additional fetch() options like `credentials` or `headers`
  });

  return new ApolloClient({
    link: from([errorLink, persistedQueriesLink, httpLink]),
    cache: new InMemoryCache(),
  });
}
