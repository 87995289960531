import { SKU_CATEGORY, SKUStatus } from '../PaymentProvider.d';

const validateSKUStatus = ({
  productStatus,
  productType,
  storeURL,
  permalink,
  SKUCode,
}: {
  productStatus: SKUStatus;
  productType: SKU_CATEGORY;
  storeURL: string | undefined;
  permalink: string;
  SKUCode: string;
}): void => {
  if (productStatus === SKUStatus.SELLING) {
    return;
  }
  switch (productType) {
    case SKU_CATEGORY.ONLINE_COURSE:
      window.location.href = `${storeURL}/courses/${permalink}`;
      return;
    case SKU_CATEGORY.COLLECTION:
      window.location.href = `${storeURL}/bundles/${permalink}`;
      return;
    case SKU_CATEGORY.WORKSHOP_BATCH:
      window.location.href = `${storeURL}/workshops/${permalink}?skuCode=${SKUCode}`;
      return;
    default:
      return;
  }
};

export default validateSKUStatus;
