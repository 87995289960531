const Youtube = (props: any): JSX.Element => {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.4985 2.64036C23.2225 1.60106 22.4092 0.782533 21.3766 0.504755C19.505 0 12 0 12 0C12 0 4.49503 0 2.62336 0.504755C1.59077 0.782577 0.777523 1.60106 0.501503 2.64036C0 4.52416 0 8.45454 0 8.45454C0 8.45454 0 12.3849 0.501503 14.2687C0.777523 15.308 1.59077 16.0924 2.62336 16.3702C4.49503 16.875 12 16.875 12 16.875C12 16.875 19.505 16.875 21.3766 16.3702C22.4092 16.0924 23.2225 15.308 23.4985 14.2687C24 12.3849 24 8.45454 24 8.45454C24 8.45454 24 4.52416 23.4985 2.64036ZM9.54544 12.023V4.88605L15.8181 8.45463L9.54544 12.023Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Youtube;
