import styled, { css } from 'styled-components';
import { Text, Flex, Box, Grid, SVGIcon } from '@skooldio/paas-shared-react-components';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ClarificationTextListProps } from './types';
import { useConfig } from '../../Contexts/ConfigContext';
import goToLoginPage from '../../Utils/goToLoginPage';

const clarificationTextList: ClarificationTextListProps[] = [
  {
    iconName: SVGIcon.ICON_LIST.CURRENT_USER,
    headingText: 'quick_register_already_have_account',
    descriptionText: 'quick_register_already_have_account_description',
    inlineButton: {
      text: 'login',
      clickAction: (loginUrl: string) => goToLoginPage({ loginUrl }),
    },
  },
  {
    iconName: SVGIcon.ICON_LIST.ADD_NEW_USER,
    headingText: 'quick_register_no_account',
    descriptionText: 'quick_register_no_account_description',
    inlineButton: null,
  },
];

const SectionWrapperGrid = styled((props) => (
  <Grid alignItems="flex-start" gridTemplateColumns="24px 1fr" gridGap={3} {...props} />
))`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale[300]};
    padding-bottom: ${({ theme }) => theme.space[6]}px;
    margin-bottom: ${({ theme }) => theme.space[6]}px;
  }
`;

const InlineButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  ${({ theme }) => css`
    margin-left: ${theme.space[0]}px;
    margin-top: ${theme.space[2]}px;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      margin-left: ${theme.space[2]}px;
      margin-top: ${theme.space[0]}px;
    }
  `}
`;

const AccountClarificationText = (): JSX.Element => {
  const { loginUrl } = useConfig();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      {clarificationTextList.map(
        ({
          iconName,
          headingText,
          descriptionText,
          inlineButton,
        }: ClarificationTextListProps): JSX.Element => {
          return (
            <SectionWrapperGrid key={headingText}>
              <SVGIcon color={theme.colors.primary['500']} width={24} height={24} name={iconName} />
              <Box>
                <Text textStyle="body1Medium" mb={2} color="text.darkText">
                  {t(headingText)}
                </Text>
                <Flex
                  alignItems={{ _: 'flex-start', sm: 'center' }}
                  flexDirection={{ _: 'column', sm: 'row' }}
                >
                  <Text textStyle="body2" color="text.darkText">
                    {t(descriptionText)}
                  </Text>
                  {inlineButton && (
                    <InlineButton
                      type={'button'}
                      onClick={() => inlineButton?.clickAction(loginUrl)}
                    >
                      <Text textStyle="body2" fontWeight="medium" color="system.info.500">
                        {t(inlineButton.text)}
                      </Text>
                    </InlineButton>
                  )}
                </Flex>
              </Box>
            </SectionWrapperGrid>
          );
        }
      )}
    </Box>
  );
};

export default AccountClarificationText;
