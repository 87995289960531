import { useTranslation } from 'react-i18next';

import { Text, Box } from '../Base';

const PurchaseHistoryShell = ({
  total,
  children,
}: {
  total?: number;
  children: React.ReactNode;
}): JSX.Element => {
  const { t } = useTranslation('purchase-history');

  return (
    <Box>
      <Text textStyle="heading3" color="text.darkText">
        {t('purchase_history_title')}
      </Text>
      <Text textStyle="body1" mt={4} color="text.darkTextAlt">
        {t('purchase_history_order_count', {
          count: total ?? 0,
        })}
      </Text>
      {children}
    </Box>
  );
};

export default PurchaseHistoryShell;
