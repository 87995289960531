const AccountFilled = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00016 1.33398C4.32016 1.33398 1.3335 4.32065 1.3335 8.00065C1.3335 11.6807 4.32016 14.6673 8.00016 14.6673C11.6802 14.6673 14.6668 11.6807 14.6668 8.00065C14.6668 4.32065 11.6802 1.33398 8.00016 1.33398ZM8.00016 3.33398C9.10683 3.33398 10.0002 4.22732 10.0002 5.33398C10.0002 6.44065 9.10683 7.33398 8.00016 7.33398C6.8935 7.33398 6.00016 6.44065 6.00016 5.33398C6.00016 4.22732 6.8935 3.33398 8.00016 3.33398ZM8.00016 12.8007C6.3335 12.8007 4.86016 11.9473 4.00016 10.654C4.02016 9.32732 6.66683 8.60065 8.00016 8.60065C9.32683 8.60065 11.9802 9.32732 12.0002 10.654C11.1402 11.9473 9.66683 12.8007 8.00016 12.8007Z"
        fill="#A6A6A6"
      />
    </svg>
  );
};
export default AccountFilled;
