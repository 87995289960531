import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import SVGIcon from '../SVGIcon';

import {
  PAYMENT_METHOD,
  SHIPMENT_PROVIDER,
  SALES_ORDER_STATUS,
} from '../../Domain/Payment/constants';
import { Box, Text, Grid } from '../Base';
import { intlDateLocale } from '../../Utils/dateUtils';

import { PurchaseHistoryProps, ShipmentDelivery, PaymentInfoProps } from './types';

const paymentMethodTextMapper: { [key: string]: string } = {
  [PAYMENT_METHOD.QR_CODE]: 'purchase_history_card_payment_method_prompt_pay',
  [PAYMENT_METHOD.CREDIT_CARD]: 'purchase_history_card_payment_method_credit_card',
  [PAYMENT_METHOD.ATM]: 'purchase_history_card_payment_method_atm',
};

const shipmentProviderTextMapper: { [key: string]: string } = {
  [SHIPMENT_PROVIDER.FLASH_EXPRESS]: 'Flash Express',
};

const TitleText = styled((props) => <Text textStyle="body1" color="text.darkTextAlt" {...props} />)`
  font-size: 10px;
`;

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

function toDateString(date: Date, locale = 'th') {
  // TODO: use lib to support multi browser
  const dateFormat: string = locale in intlDateLocale ? intlDateLocale[locale] : 'th-TH';

  return Intl.DateTimeFormat(dateFormat, dateFormatOptions).format(date);
}

function getPaidAtString(
  t: ReturnType<typeof useTranslation>['t'],
  status: SALES_ORDER_STATUS,
  paidAt?: Date | null,
  expirationDate?: Date | null,
  locale = 'th'
) {
  if (paidAt) {
    return toDateString(paidAt, locale);
  }
  if (status === SALES_ORDER_STATUS.PENDING && expirationDate) {
    return t('purchase_history_order_complete_within', {
      time: toDateString(expirationDate, locale),
    });
  }
  return '-';
}

function getShipmentProviderText(provider: string): string {
  return provider in shipmentProviderTextMapper ? shipmentProviderTextMapper[provider] : provider;
}

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.system.info[500]};
  cursor: pointer;
`;

const Link = ({ children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const theme = useTheme();

  return (
    <ExternalLink {...props}>
      {children}
      <Box display="inline-block" ml={2}>
        <SVGIcon name="CHEVRON" stroke={theme.colors.icon.uiState.default} />
      </Box>
    </ExternalLink>
  );
};

const PaymentInfo = ({ status, text, paymentAction }: PaymentInfoProps) => {
  return status === SALES_ORDER_STATUS.PENDING && paymentAction ? (
    typeof paymentAction === 'function' ? (
      <Link
        href="#"
        onClick={(e) => {
          e.preventDefault();
          paymentAction();
        }}
      >
        {text}
      </Link>
    ) : (
      <Link href={paymentAction} target="_blank">
        {text}
      </Link>
    )
  ) : (
    <>{text}</>
  );
};

const ShipmentDeliveryInfo = ({ delivery }: { delivery: ShipmentDelivery }) => {
  return delivery.trackingURL ? (
    <Link href={delivery.trackingURL} target="_blank">
      <Text as="span" textStyle="body1" color="text.darkText">
        {delivery.trackingNumber} ({getShipmentProviderText(delivery.shipmentProvider)})
      </Text>
    </Link>
  ) : (
    <>
      {delivery.trackingNumber} ({getShipmentProviderText(delivery.shipmentProvider)})
    </>
  );
};

export type PurchaseHistoryDetailProp = Omit<PurchaseHistoryProps, 'orderNo' | 'price'>;

const PurchaseHistoryDetail = (props: PurchaseHistoryDetailProp): JSX.Element => {
  const { createdAt, status, expirationDate, paidAt, payment, product, shipment } = props;
  const { t, i18n } = useTranslation('purchase-history');
  const locale = i18n.language;

  return (
    <>
      <Text textStyle="body1Medium" display={{ _: 'none', sm: 'block' }}>
        {product.title}
      </Text>
      <Grid gridTemplateColumns={'repeat(auto-fill, minmax(240px, 1fr))'} gridGap={4} pt={3}>
        <Box>
          <TitleText>{t('purchase_history_card_detail_title')}</TitleText>
          <Text textStyle="body1">{createdAt ? toDateString(createdAt, locale) : '-'}</Text>
        </Box>
        <Box>
          <TitleText>{t('purchase_history_card_payment_method_title')}</TitleText>
          <Text textStyle="body1">
            {payment.method in paymentMethodTextMapper ? (
              <PaymentInfo
                status={props.status}
                text={t(paymentMethodTextMapper[payment.method])}
                paymentAction={props.payment.action}
              />
            ) : (
              '-'
            )}
          </Text>
        </Box>
        <Box>
          <TitleText>{t('purchase_history_card_payment_date_title')}</TitleText>
          <Text textStyle="body1">
            {getPaidAtString(t, status, paidAt, expirationDate, locale)}
          </Text>
        </Box>
        {shipment && (
          <Box>
            <TitleText>{t('purchase_history_card_tracking_number')}</TitleText>
            <Text textStyle="body1">
              {shipment.delivery?.trackingNumber ? (
                <ShipmentDeliveryInfo delivery={shipment.delivery} />
              ) : (
                '-'
              )}
            </Text>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default PurchaseHistoryDetail;
