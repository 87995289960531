import { Footer as FooterView } from '@skooldio/paas-shared-react-components';

import useFooterMenu from './useFooterMenu';
import useSocialMedia from './useSocialMedia';
import { useConfig } from '../../Contexts/ConfigContext';

const Footer = () => {
  const socialChannel = useSocialMedia();
  const footerMenuList = useFooterMenu();
  const { appShell } = useConfig();
  const footer = appShell?.footer;
  const { title, subtitle, showBadge } = footer ?? {};
  return (
    <FooterView
      title={title}
      subTitle={subtitle}
      socialChannel={socialChannel}
      footerMenuList={footerMenuList}
      showBadge={showBadge}
    />
  );
};

export default Footer;
