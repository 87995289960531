import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { setPageTitle } from '../Utils/page';
import { Flex } from '../Components/Base';
import { useConfig } from '../Contexts/ConfigContext';
import ThankYou from '../Containers/ThankYou';
import DownloadApp from '../Containers/DownloadApp';

export enum THANKYOU_VARIANT {
  DEFAULT = 'default',
  DOWNLOAD_APP = 'downloadApp',
  PRODUCT_INFO = 'productInfo',
}

const ThankYouRoute = () => {
  const { t } = useTranslation();
  const { thankYouPageVariant } = useConfig();

  return (
    <>
      <Helmet>
        <title>{setPageTitle(t('purchase_history_title'))}</title>
      </Helmet>
      <Flex justifyContent="center" pt={7}>
        {thankYouPageVariant === THANKYOU_VARIANT.DOWNLOAD_APP ? <DownloadApp /> : <ThankYou />}
      </Flex>
    </>
  );
};

export default ThankYouRoute;
