import styled, { css } from 'styled-components';

import FormTextInput from '../../Components/FormInput/FormTextInput';
import Text from '../../Components/Base/Text';
import Grid from '../../Components/Base/Grid';
import Box from '../../Components/Base/Box';

import { REGEX_TAX_IDENTIFICATION_NUMBER_FORMAT } from '../Utils/constants';
import PaymentUserAddress from '../PaymentAddress';
import { useConfig } from '../../Contexts/ConfigContext';
import { replacePatternsWithLinks } from '../Utils/replaceTextWithLinks';
import taxIdValidation from '../Utils/taxIdValidation';

const PaymentCorporateAddressWrapper = styled(Grid)`
  ${({ theme }) => css`
    width: 100%;
    padding-bottom: ${theme.space[4]}px;
    grid-gap: ${theme.space[4]}px;

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(2, auto);
    }
  `};
`;

const TaxInvoiceInfo = styled(Text)`
  ${({ theme }) => css`
    a: {
      color: ${theme.colors.system.info[500]};
      display: inline;
    }
  `}
`;

TaxInvoiceInfo.defaultProps = {
  textStyle: 'body2',
  color: 'text.darkText',
};

const PaymentIndividualAddress = () => {
  const { payment: paymentConfig } = useConfig();

  const taxInvoiceInfoTemplate = paymentConfig?.individual?.taxInvoiceInfo ?? '';
  const taxInvoiceInfoOptions = paymentConfig?.individual?.taxInvoiceInfoOptions ?? [];
  const taxInvoiceInfo = replacePatternsWithLinks(taxInvoiceInfoTemplate, taxInvoiceInfoOptions);

  return (
    <Box>
      {taxInvoiceInfo && (
        <Box px={4} py={2} mb={4} bg="grayscale.100" style={{ borderRadius: '8px' }}>
          <TaxInvoiceInfo dangerouslySetInnerHTML={{ __html: taxInvoiceInfo }} />
        </Box>
      )}
      <PaymentCorporateAddressWrapper>
        <FormTextInput
          name="customerInfo.firstName"
          label="ชื่อ"
          rules={{
            required: 'กรุณากรอกชื่อ',
          }}
        />
        <FormTextInput
          name="customerInfo.lastName"
          label="นามสกุล"
          rules={{
            required: 'กรุณากรอกนามสกุล',
          }}
        />
      </PaymentCorporateAddressWrapper>
      <FormTextInput
        name="customerInfo.taxId"
        label="เลขประจำตัวผู้เสียภาษี"
        placeholder="กรอกเลขประจำตัวผู้เสียภาษี 13 หลัก"
        rules={{
          required: 'กรุณากรอกเลขประจำตัวผู้เสียภาษี',
          validate: {
            checkSum: (value: string) => taxIdValidation(value),
          },
        }}
        inputProps={{ maxLength: 13 }}
      />
      <Box pt={4} />
      <PaymentUserAddress
        scope="customerInfo"
        addressInputProps={{ label: 'ที่อยู่ (สำหรับออกใบกำกับภาษี)' }}
      />
      <Text textStyle="body2" color="text.darkText" pt={2}>
        กรุณาตรวจสอบข้อมูลเพื่อความถูกต้องสมบูรณ์ ก่อนชำระเงิน
      </Text>
    </Box>
  );
};

export default PaymentIndividualAddress;
