import { Box, ButtonOutlined, Flex, Text } from '../Base';

import styled, { useTheme } from 'styled-components';
import { Image as ImageBase } from '@skooldio/skooldio-ui-components-core';
import { Divider } from '@material-ui/core';
import { EventTime, WorkshopDetailSection, WorkshopInformation } from '../ProductCard';
import SVGIcon, { ICON_LIST } from '../SVGIcon';

export interface ThankYouWorkshopProps {
  email: string;
  workshopName: string;
  isFreeWorkshop: boolean;
  imageUrl?: string;
  workshopLocation?: string;
  workshopEventime?: EventTime;

  onNext: () => void;
}

const WorkshopCardDetailMobile = styled(Box)`
  align-self: flex-start;
  padding-bottom: 8px;
`;

const EmptyBox = styled(Flex)`
  border-radius: 8px 8px 8px 8px;
  object-fit: cover;
  margin: 16px 0 16px 0;
  justify-content: center;
  align-items: center;
`;

const ThankYouWorkshop = (props: ThankYouWorkshopProps): JSX.Element => {
  const {
    isFreeWorkshop,
    imageUrl,
    workshopName: courseName,
    email,
    workshopLocation,
    workshopEventime,
    onNext,
  } = props;

  const theme = useTheme();
  const nextButtonText = 'ดูคอร์สอื่นเพิ่มเติม';
  const successText = isFreeWorkshop ? 'สมัครเรียนเรียบร้อยแล้ว' : 'ชำระเงินสำเร็จ';

  return (
    <Flex maxWidth={'425px'} flexDirection={'column'}>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <SVGIcon name={ICON_LIST.SUCCESS} stroke="hsla(147, 100%, 35%, 1)" />
        <Text mt={'28px'} textStyle="heading4" color="text.darkText">
          {successText}
        </Text>
      </Flex>
      <Flex flexDirection={'column'} alignItems={'flex-start'}>
        {imageUrl ? (
          <ImageBase src={imageUrl} alt={'Thank you'} width={425} paddingY={4} />
        ) : (
          <EmptyBox width={425} height={224} bg="grayscale.100">
            <SVGIcon
              name={ICON_LIST.LIBRARY_BOOK}
              color={theme.colors.grayscale[300]}
              width={'77px'}
              height={'77px'}
            />
          </EmptyBox>
        )}
        <Flex
          mb={2}
          color="primary.500"
          alignItems="center"
          style={{ flexWrap: 'wrap', gap: '4px' }}
        >
          <SVGIcon name={ICON_LIST.EXTENSION_OUTLINED} />
          <Text textStyle="caption" as="span" {...props}>
            Workshop
          </Text>
        </Flex>
        <WorkshopInformation title={courseName} />
        <WorkshopCardDetailMobile>
          <WorkshopDetailSection
            location={workshopLocation}
            eventTime={workshopEventime}
            isWorkshopCard={false}
          />
        </WorkshopCardDetailMobile>
      </Flex>
      <Divider style={{ marginTop: '8px' }} />
      <Text mt={4} textStyle="body1" textAlign="center" color="grey" whiteSpace={'pre-line'}>
        ตรวจสอบอีเมลยืนยันการสมัครเรียนได้ที่
      </Text>
      <Text
        textStyle="body1"
        fontWeight={600}
        textAlign="center"
        style={{ 'word-break': 'break-all' }}
      >
        {email}
      </Text>

      <ButtonOutlined mt={'36px'} py={'6px'} px={6} color="primary" onClick={onNext}>
        <Text textStyle="body1Medium">{nextButtonText}</Text>
      </ButtonOutlined>
    </Flex>
  );
};

export { ThankYouWorkshop };
