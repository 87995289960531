import promptpayLogo from './images/promptpay.jpg';
import scbLogo from './images/scb.png';
import bayLogo from './images/bay.png';
import kbankLogo from './images/kbank.png';
import ktbLogo from './images/ktb.png';
import bangkokBankLogo from './images/bangkokbank.png';
import visaLogo from './images/visa.png';
import mastercardLogo from './images/mastercard.png';
import jcbLogo from './images/jcb.png';
import unionPayLogo from './images/unionpay.png';
import { PAYMENT_METHOD } from '../../Contexts/PaymentProvider/PaymentProvider.d';

export type PaymentMethodInfo = {
  method: PAYMENT_METHOD;
  textTranslationKey: string;
  imageList: {
    imageUrl: string;
    alt: string;
    width: number;
  }[];
};

const paymentMethodData: Partial<Record<keyof typeof PAYMENT_METHOD, PaymentMethodInfo>> = {
  QR_CODE: {
    method: PAYMENT_METHOD.QR_CODE,
    textTranslationKey: 'purchase_payment_method_qr_code',
    imageList: [{ imageUrl: promptpayLogo, alt: 'PromptPay', width: 85 }],
  },
  ATM: {
    method: PAYMENT_METHOD.ATM,
    textTranslationKey: 'purchase_payment_method_atm',
    imageList: [
      { imageUrl: scbLogo, alt: 'SCB', width: 30 },
      { imageUrl: bayLogo, alt: 'BAY', width: 30 },
      { imageUrl: kbankLogo, alt: 'KBANK', width: 30 },
      { imageUrl: ktbLogo, alt: 'KTB', width: 30 },
      { imageUrl: bangkokBankLogo, alt: 'BANGKOK BANK', width: 30 },
    ],
  },
  CREDIT_CARD: {
    method: PAYMENT_METHOD.CREDIT_CARD,
    textTranslationKey: 'purchase_payment_method_credit_card',
    imageList: [
      { imageUrl: visaLogo, alt: 'VISA', width: 35 },
      { imageUrl: mastercardLogo, alt: 'MasterCard', width: 35 },
      { imageUrl: jcbLogo, alt: 'JCB', width: 35 },
      { imageUrl: unionPayLogo, alt: 'UnionPay', width: 35 },
    ],
  },
};

export default paymentMethodData;
