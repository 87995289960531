import CloseIcon from './Icons/CloseIcon';
import ClockIcon from './Icons/ClockIcon';
import ClockMobile from './Icons/ClockMobile';
import MagnifyingGlass from './Icons/MagnifyingGlass';
import LargeMagnifyingGlass from './Icons/LargeMagnifyingGlass';
import Chevron from './Icons/Chevron';
import CheckCircle from './Icons/CheckCircle';
import Circle from './Icons/Circle';
import Info from './Icons/Info';
import Error from './Icons/Error';
import Warning from './Icons/Warning';
import GoogleIcon from './Icons/GoogleIcon';
import MobileIcon from './Icons/Mobile';
import BackIcon from './Icons/Back';
import Account from './Icons/Account';
import Exit from './Icons/Exit';
import History from './Icons/History';
import InfoOutline from './Icons/InfoOutline';
import SentimentDissatisfied from './Icons/SentimentDissatisfied';
import User from './Icons/User';
import DropdownArrow from './Icons/DropdownArrow';
import Home from './Icons/Home';
import LibraryBook from './Icons/LibraryBook';
import Tag from './Icons/Tag';
import DownloadMacOSApp from './Icons/DownloadMacOSApp';
import DownloadWindowsOSApp from './Icons/DownloadWindowsOSApp';
import DownloadiOSApp from './Icons/DownloadiOSApp';
import DownloadAndroidOSApp from './Icons/DownloadAndroidOSApp';
import PlayCircle from './Icons/PlayCircle';
import Tune from './Icons/Tune';
import LibraryBooks from './Icons/LibraryBooks';
import AccountFilled from './Icons/AccountFilled';
import Mail from './Icons/Mail';
import Success from './Icons/Success';
import SkooldioLogo from './Icons/SkooldioLogo';
import Zoom from './Icons/Zoom';
import Facebook from './Icons/Facebook';
import LinkedIn from './Icons/LinkedIn';
import Twitter from './Icons/Twitter';
import Download from './Icons/Download';
import SadFace from './Icons/SadFace';
import MortarBoard from './Icons/MortarBoard';
import CertificateCardBadge from './Icons/CertificateCardBadge';
import FacebookCircle from './Icons/FacebookCircle';
import Tiktok from './Icons/Tiktok';
import Youtube from './Icons/Youtube';
import Sun from './Icons/Sun';
import Moon from './Icons/Moon';
import Instagram from './Icons/Instagram';
import GreenLineApp from './Icons/GreenLineApp';
import LineApp from './Icons/LineApp';
import CurrentUser from './Icons/CurrentUser';
import AddNewUser from './Icons/AddNewUser';
import AppleIcon from './Icons/AppleIcon';
import PlayCircleOutline from './Icons/PlayCircleOutline';
import DiamondCoin from './Icons/DiamondCoin';
import Lock from './Icons/Lock';
import Calendar from './Icons/Calendar';
import LiteCalendar from './Icons/LiteCalendar';
import PlayOutline from './Icons/PlayOutline';
import Geometry from './Icons/Geometry';
import DeleteIcon from './Icons/DeleteIcon';
import CheckIcon from './Icons/Check';
import ExtensionOutlined from './Icons/ExtensionOutlined';
import PeopleGroup from './Icons/PeopleGroup';
import PersonTalk from './Icons/PersonTalk';
import Play from './Icons/PlayIcon';
import View from './Icons/ViewIcon';
import CreditBankConnection from './Icons/CreditBankConnection';
import Eye from './Icons/Eye';
import EyeSlash from './Icons/EyeSlash';
import Location from './Icons/Location';
import CalendarOutlined from './Icons/CalendarOutlined';
import Certificate from './Icons/Certificate';

export enum ICON_LIST {
  ACCOUNT_FILLED = 'ACCOUNT_FILLED',
  CLOSE = 'CLOSE',
  CLOCK = 'CLOCK',
  CLOCK_MOBILE = 'CLOCK_MOBILE',
  MAGNIFYING_GLASS = 'MAGNIFYING_GLASS',
  LARGE_MAGNIFYING_GLASS = 'LARGE_MAGNIFYING_GLASS',
  CHEVRON = 'CHEVRON',
  CHECK_CIRCLE = 'CHECK_CIRCLE',
  CIRCLE = 'CIRCLE',
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  GOOGLE = 'GOOGLE',
  MOBILE = 'MOBILE',
  BACK = 'BACK',
  ACCOUNT = 'ACCOUNT',
  EXIT = 'EXIT',
  HISTORY = 'HISTORY',
  INFO_OUTLINE = 'INFO_OUTLINE',
  SENTIMENT_DISSATISFIED = 'SENTIMENT_DISSATISFIED',
  USER = 'USER',
  DROPDOWN_ARROW = 'DROPDOWN_ARROW',
  HOME = 'HOME',
  LIBRARY_BOOK = 'LIBRARY_BOOK',
  TAG = 'TAG',
  DOWNLOAD_MAC_OS_APP = 'DOWNLOAD_MAC_OS_APP',
  DOWNLOAD_WINDOWS_OS_APP = 'DOWNLOAD_WINDOWS_OS_APP',
  DOWNLOAD_IOS_APP = 'DOWNLOAD_MAC_IOS_APP',
  DOWNLOAD_ANDROID_OS_APP = 'DOWNLOAD_ANDROID_OS_APP',
  PLAY_CIRCLE = 'PLAY_CIRCLE',
  TUNE = 'TUNE',
  LIBRARY_BOOKS = 'LIBRARY_BOOKS',
  MAIL = 'MAIL',
  SUCCESS = 'SUCCESS',
  SKOOLDIO_LOGO = 'SKOOLDIO_LOGO',
  ZOOM = 'ZOOM',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_CIRCLE = 'FACEBOOK_CIRCLE',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  DOWNLOAD = 'DOWNLOAD',
  SAD_FACE = 'SAD_FACE',
  MORTAR_BOARD = 'MORTAR_BOARD',
  CERTIFICATE_CARD_BADGE = 'CERTIFICATE_CARD_BADGE',
  SUN = 'SUN',
  MOON = 'MOON',
  INSTAGRAM = 'INSTAGRAM',
  GREEN_LINE_APP = 'GREEN_LINE_APP',
  LINE_APP = 'LINE_APP',
  ADD_NEW_USER = 'ADD_NEW_USER',
  CURRENT_USER = 'CURRENT_USER',
  APPLE = 'APPLE',
  PLAY_CIRCLE_OUTLINE = 'PLAY_CIRCLE_OUTLINE',
  DIAMOND_COIN = 'DIAMOND_COIN',
  LOCK = 'LOCK',
  CALENDAR = 'CALENDAR',
  LITE_CALENDAR = 'LITE_CALENDAR',
  PLAY_OUTLINE = 'PLAY_OUTLINE',
  GEOMETRY = 'GEOMETRY',
  DELETE_ICON = 'DELETE_ICON',
  CHECK = 'CHECK',
  EXTENSION_OUTLINED = 'EXTENSION_OUTLINED',
  PEOPLE_GROUP = 'PEOPLE_GROUP',
  PERSON_TALK = 'PERSON_TALK',
  PLAY = 'PLAY',
  VIEW = 'VIEW',
  CREDIT_BANK_CONNECTION = 'CREDIT_BANK_CONNECTION',
  EYE = 'EYE',
  EYE_SLASH = 'EYE_SLASH',
  LOCATION = 'LOCATION',
  CALENDAR_OUTLINED = 'CALENDAR_OUTLINED',
  CERTIFICATE = 'CERTIFICATE',
}

const getIcon = (name: string, props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  switch (name) {
    case ICON_LIST.CLOSE:
      return <CloseIcon {...props} />;
    case ICON_LIST.CLOCK:
      return <ClockIcon {...props} />;
    case ICON_LIST.CLOCK_MOBILE:
      return <ClockMobile {...props} />;
    case ICON_LIST.MAGNIFYING_GLASS:
      return <MagnifyingGlass {...props} />;
    case ICON_LIST.LARGE_MAGNIFYING_GLASS:
      return <LargeMagnifyingGlass {...props} />;
    case ICON_LIST.CHEVRON:
      return <Chevron {...props} />;
    case ICON_LIST.CHECK_CIRCLE:
      return <CheckCircle {...props} />;
    case ICON_LIST.CIRCLE:
      return <Circle {...props} />;
    case ICON_LIST.INFO:
      return <Info {...props} />;
    case ICON_LIST.ERROR:
      return <Error {...props} />;
    case ICON_LIST.WARNING:
      return <Warning {...props} />;
    case ICON_LIST.GOOGLE:
      return <GoogleIcon {...props} />;
    case ICON_LIST.MOBILE:
      return <MobileIcon {...props} />;
    case ICON_LIST.BACK:
      return <BackIcon {...props} />;
    case ICON_LIST.ACCOUNT:
      return <Account {...props} />;
    case ICON_LIST.EXIT:
      return <Exit {...props} />;
    case ICON_LIST.HISTORY:
      return <History {...props} />;
    case ICON_LIST.INFO_OUTLINE:
      return <InfoOutline {...props} />;
    case ICON_LIST.SENTIMENT_DISSATISFIED:
      return <SentimentDissatisfied {...props} />;
    case ICON_LIST.USER:
      return <User {...props} />;
    case ICON_LIST.DROPDOWN_ARROW:
      return <DropdownArrow {...props} />;
    case ICON_LIST.HOME:
      return <Home {...props} />;
    case ICON_LIST.LIBRARY_BOOK:
      return <LibraryBook {...props} />;
    case ICON_LIST.TAG:
      return <Tag {...props} />;
    case ICON_LIST.DOWNLOAD_MAC_OS_APP:
      return <DownloadMacOSApp {...props} />;
    case ICON_LIST.DOWNLOAD_WINDOWS_OS_APP:
      return <DownloadWindowsOSApp {...props} />;
    case ICON_LIST.DOWNLOAD_IOS_APP:
      return <DownloadiOSApp {...props} />;
    case ICON_LIST.DOWNLOAD_ANDROID_OS_APP:
      return <DownloadAndroidOSApp {...props} />;
    case ICON_LIST.PLAY_CIRCLE:
      return <PlayCircle {...props} />;
    case ICON_LIST.TUNE:
      return <Tune {...props} />;
    case ICON_LIST.LIBRARY_BOOKS:
      return <LibraryBooks {...props} />;
    case ICON_LIST.ACCOUNT_FILLED:
      return <AccountFilled {...props} />;
    case ICON_LIST.MAIL:
      return <Mail {...props} />;
    case ICON_LIST.SUCCESS:
      return <Success {...props} />;
    case ICON_LIST.SKOOLDIO_LOGO:
      return <SkooldioLogo {...props} />;
    case ICON_LIST.ZOOM:
      return <Zoom {...props} />;
    case ICON_LIST.FACEBOOK:
      return <Facebook {...props} />;
    case ICON_LIST.FACEBOOK_CIRCLE:
      return <FacebookCircle {...props} />;
    case ICON_LIST.LINKEDIN:
      return <LinkedIn {...props} />;
    case ICON_LIST.TWITTER:
      return <Twitter {...props} />;
    case ICON_LIST.TIKTOK:
      return <Tiktok {...props} />;
    case ICON_LIST.YOUTUBE:
      return <Youtube {...props} />;
    case ICON_LIST.DOWNLOAD:
      return <Download {...props} />;
    case ICON_LIST.SAD_FACE:
      return <SadFace {...props} />;
    case ICON_LIST.MORTAR_BOARD:
      return <MortarBoard {...props} />;
    case ICON_LIST.CERTIFICATE_CARD_BADGE:
      return <CertificateCardBadge {...props} />;
    case ICON_LIST.SUN:
      return <Sun {...props} />;
    case ICON_LIST.MOON:
      return <Moon {...props} />;
    case ICON_LIST.INSTAGRAM:
      return <Instagram {...props} />;
    case ICON_LIST.GREEN_LINE_APP:
      return <GreenLineApp {...props} />;
    case ICON_LIST.LINE_APP:
      return <LineApp {...props} />;
    case ICON_LIST.ADD_NEW_USER:
      return <AddNewUser {...props} />;
    case ICON_LIST.CURRENT_USER:
      return <CurrentUser {...props} />;
    case ICON_LIST.APPLE:
      return <AppleIcon {...props} />;
    case ICON_LIST.PLAY_CIRCLE_OUTLINE:
      return <PlayCircleOutline {...props} />;
    case ICON_LIST.DIAMOND_COIN:
      return <DiamondCoin {...props} />;
    case ICON_LIST.LOCK:
      return <Lock {...props} />;
    case ICON_LIST.CALENDAR:
      return <Calendar {...props} />;
    case ICON_LIST.LITE_CALENDAR:
      return <LiteCalendar {...props} />;
    case ICON_LIST.PLAY_OUTLINE:
      return <PlayOutline {...props} />;
    case ICON_LIST.GEOMETRY:
      return <Geometry {...props} />;
    case ICON_LIST.DELETE_ICON:
      return <DeleteIcon {...props} />;
    case ICON_LIST.CHECK:
      return <CheckIcon {...props} />;
    case ICON_LIST.EXTENSION_OUTLINED:
      return <ExtensionOutlined {...props} />;
    case ICON_LIST.PEOPLE_GROUP:
      return <PeopleGroup {...props} />;
    case ICON_LIST.PERSON_TALK:
      return <PersonTalk {...props} />;
    case ICON_LIST.PLAY:
      return <Play {...props} />;
    case ICON_LIST.VIEW:
      return <View {...props} />;
    case ICON_LIST.CREDIT_BANK_CONNECTION:
      return <CreditBankConnection {...props} />;
    case ICON_LIST.EYE:
      return <Eye {...props} />;
    case ICON_LIST.EYE_SLASH:
      return <EyeSlash {...props} />;
    case ICON_LIST.LOCATION:
      return <Location {...props} />;
    case ICON_LIST.CALENDAR_OUTLINED:
      return <CalendarOutlined {...props} />;
    case ICON_LIST.CERTIFICATE:
      return <Certificate {...props} />;
    default:
      return null;
  }
};

// interface SVGIconProps extends SVGAttributes { name: string }
type SVGIconProps = React.SVGProps<SVGSVGElement>;
const SVGIcon = ({ name, ...props }: SVGIconProps): JSX.Element => getIcon(name, props);

SVGIcon.ICON_LIST = ICON_LIST;
export type { SVGIconProps };
export default SVGIcon;
