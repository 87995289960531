import { useEffect } from 'react';
import styled from 'styled-components';
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Text, Grid, Checkbox } from '@skooldio/paas-shared-react-components';

import { usePayment, CUSTOMER_TYPE } from '../../Contexts/PaymentProvider';
import { useConfig } from '../../Contexts/ConfigContext';

const StyledCheckbox = styled(Checkbox)`
  && {
    &.MuiCheckbox-colorSecondary {
      color: ${({ theme }) => theme.colors.grayscale[400]};
    }
  }
`;

const StyledFormControlLabel = styled(MUIFormControlLabel)`
  && {
    margin: 0;
  }
  .MuiFormControlLabel-label {
    margin-left: 16px;
  }
`;

const WrapperBox = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale[300]};
  padding-top: ${({ theme }) => theme.space['6']}px;
`;

const TaxInvoice = () => {
  const { payment } = useConfig();
  const { taxInvoiceRequested, updateTaxInvoiceRequested, customerType } = usePayment();

  const canIndividualRequestTaxInvoice = payment?.individual?.enableRequestTaxInvoice ?? false;
  const canCorporateRequestTaxInvoice = payment?.corporate?.enableRequestTaxInvoice ?? false;

  useEffect(() => {
    if (!canCorporateRequestTaxInvoice) {
      updateTaxInvoiceRequested(customerType === CUSTOMER_TYPE.CORPORATE);
    }
  }, [canCorporateRequestTaxInvoice, customerType, updateTaxInvoiceRequested]);

  if (!canIndividualRequestTaxInvoice && customerType === CUSTOMER_TYPE.INDIVIDUAL) return null;
  if (!canCorporateRequestTaxInvoice && customerType === CUSTOMER_TYPE.CORPORATE) return null;

  const instructionsURL = payment?.taxInvoice?.instructionsURL;
  const taxInvoiceDescription = payment?.taxInvoice?.description;
  const showTaxInvoiceInfo = instructionsURL || taxInvoiceDescription;

  return (
    <WrapperBox>
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            checked={taxInvoiceRequested}
            onChange={(e) => {
              const isRequested = e.target.checked;
              updateTaxInvoiceRequested(isRequested);
            }}
          />
        }
        label={
          <Text textStyle="body2" color="text.darkText" fontWeight="600">
            ต้องการออกใบกำกับภาษี/ใบเสร็จรับเงิน
          </Text>
        }
      />
      {showTaxInvoiceInfo && (
        <Grid
          gridTemplateRows="repeat(2,auto)"
          gridTemplateColumns="40px 1fr"
          gridRowGap={1}
          pt={1}
        >
          <Box />
          {taxInvoiceDescription && (
            <>
              <Text textStyle="body2" color="text.darkTextAlt">
                {taxInvoiceDescription}
              </Text>
              <Box />
            </>
          )}

          {instructionsURL && (
            <>
              <a href={instructionsURL} target="_blank" rel="noreferrer">
                <Text as={'span'} textStyle="caption" color="system.info.500" fontWeight="600">
                  ขั้นตอนการขอรับใบกำกับภาษี/ใบเสร็จรับเงิน สำหรับนิติบุคคล
                </Text>
              </a>
              <Box />
            </>
          )}
        </Grid>
      )}
    </WrapperBox>
  );
};

export default TaxInvoice;
