import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    google_tag_manager: any;
  }
}

interface GoogleTagManagerConfigProps {
  id: string;
  gtmAuthToken?: string;
  gtmPreviewId?: string;
}

const googleTagManagerInitialize = (
  platformGoogleTagManager?: GoogleTagManagerConfigProps,
  customerGoogleTagManager?: GoogleTagManagerConfigProps
) => {
  if (platformGoogleTagManager?.id && !window.google_tag_manager?.[platformGoogleTagManager?.id]) {
    TagManager.initialize({
      gtmId: platformGoogleTagManager?.id,
      auth: platformGoogleTagManager?.gtmAuthToken,
      preview: platformGoogleTagManager?.gtmPreviewId,
    });
  }

  if (customerGoogleTagManager?.id && !window.google_tag_manager?.[customerGoogleTagManager?.id]) {
    TagManager.initialize({
      gtmId: customerGoogleTagManager?.id,
      auth: customerGoogleTagManager?.gtmAuthToken,
      preview: customerGoogleTagManager?.gtmPreviewId,
    });
  }
};

const sendDataToGTM = (payload: Record<string, any>) => {
  TagManager.dataLayer({
    dataLayer: payload,
  });
};

export { googleTagManagerInitialize, sendDataToGTM };
