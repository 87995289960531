const DeleteIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 18C6.5 16.3431 7.84315 15 9.5 15H63.5C65.1569 15 66.5 16.3431 66.5 18C66.5 19.6569 65.1569 21 63.5 21H9.5C7.84315 21 6.5 19.6569 6.5 18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 9C29.7044 9 28.9413 9.31607 28.3787 9.87868C27.8161 10.4413 27.5 11.2044 27.5 12V15H45.5V12C45.5 11.2044 45.1839 10.4413 44.6213 9.87868C44.0587 9.31607 43.2957 9 42.5 9H30.5ZM51.5 15V12C51.5 9.61305 50.5518 7.32387 48.864 5.63604C47.1761 3.94821 44.8869 3 42.5 3H30.5C28.1131 3 25.8239 3.94821 24.136 5.63604C22.4482 7.32387 21.5 9.61305 21.5 12V15H15.5C13.8431 15 12.5 16.3431 12.5 18V60C12.5 62.387 13.4482 64.6761 15.136 66.364C16.8239 68.0518 19.1131 69 21.5 69H51.5C53.887 69 56.1761 68.0518 57.864 66.364C59.5518 64.6761 60.5 62.387 60.5 60V18C60.5 16.3431 59.1569 15 57.5 15H51.5ZM18.5 21V60C18.5 60.7957 18.8161 61.5587 19.3787 62.1213C19.9413 62.6839 20.7044 63 21.5 63H51.5C52.2956 63 53.0587 62.6839 53.6213 62.1213C54.1839 61.5587 54.5 60.7956 54.5 60V21H18.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 30C32.1569 30 33.5 31.3431 33.5 33V51C33.5 52.6569 32.1569 54 30.5 54C28.8431 54 27.5 52.6569 27.5 51V33C27.5 31.3431 28.8431 30 30.5 30Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5 30C44.1569 30 45.5 31.3431 45.5 33V51C45.5 52.6569 44.1569 54 42.5 54C40.8431 54 39.5 52.6569 39.5 51V33C39.5 31.3431 40.8431 30 42.5 30Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
