import {
  BackgroundColors,
  BorderColors,
  Breakpoint,
  ButtonColor,
  Color,
  FontSize,
  FontWeight,
  GrayscaleLevel,
  IconColor,
  LetterSpacing,
  LineHeight,
  PrimaryColor,
  Radii,
  SecondaryColor,
  SystemColor,
  TextColor,
  TextStyle,
  Variant,
  GetColorInput,
} from './types';

const fontSizes: Readonly<FontSize> = Object.freeze({
  heading1: 60,
  heading2: 48,
  heading3: 34,
  heading4: 24,
  heading5: 20,
  subtitle1: 18,
  body1: 16,
  body2: 14,
  caption: 12,
});

const fontWeights: Readonly<FontWeight> = Object.freeze({
  exLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
});

const letterSpacings: Readonly<LetterSpacing> = Object.freeze({
  thinner: '-0.35px',
  thin: '-0.25px',
  default: 'normal',
  normal: '0.1px',
  wide: '0.2px',
  wider: '0.5px',
});

const lineHeights: Readonly<LineHeight> = Object.freeze({
  default: 1,
  mainTitle: 1.17,
  elementTitle: 1.2,
  subElementTitle: 1.3,
  emphasisText: 1.43,
  normal: 1.5,
});

const blackColor = 'hsla(0, 0%, 0%, 1)';
const whiteColor = 'hsla(0, 0%, 100%, 1)';
const grayscaleLevel: Readonly<GrayscaleLevel> = Object.freeze({
  900: 'hsla(0, 0%, 15%, 1)',
  800: 'hsla(0, 0%, 25%, 1)',
  700: 'hsla(0, 0%, 35%, 1)',
  600: 'hsla(0, 0%, 65%, 1)',
  500: 'hsla(0, 0%, 75%, 1)',
  400: 'hsla(0, 0%, 81%, 1)',
  300: 'hsla(0, 0%, 85%, 1)',
  200: 'hsla(0, 0%, 92%, 1)',
  100: 'hsla(0, 0%, 95%, 1)',
});

const systemColor: Readonly<SystemColor> = Object.freeze({
  success: {
    900: 'hsla(147, 100%, 19%, 1)',
    700: 'hsla(147, 100%, 35%, 1)',
    500: 'hsla(147, 100%, 39%, 1)',
    300: 'hsla(127, 100%, 83%, 1)',
    100: 'hsla(127, 100%, 95%, 1)',
  },
  info: {
    900: 'hsla(223, 99%, 38%, 1)',
    700: 'hsla(223, 99%, 53%, 1)',
    500: 'hsla(223, 99%, 58%, 1)',
    300: 'hsla(223, 99%, 68%, 1)',
    100: 'hsla(223, 99%, 95%, 1)',
  },
  warning: {
    900: 'hsla(43, 94%, 44%, 1)',
    700: 'hsla(43, 94%, 59%, 1)',
    500: 'hsla(43, 94%, 64%, 1)',
    300: 'hsla(43, 94%, 74%, 1)',
    100: 'hsla(43, 94%, 95%, 1)',
  },
  danger: {
    900: 'hsla(360, 79%, 43%, 1)',
    700: 'hsla(0, 79%, 58%, 1)',
    500: 'hsla(360, 79%, 63%, 1)',
    300: 'hsla(360, 79%, 73%, 1)',
    100: 'hsla(0, 79%, 95%, 1)',
  },
});

const primaryColor: Readonly<PrimaryColor> = Object.freeze({
  // Skooldio Colors
  900: 'hsla(38, 96%, 44%, 1)',
  700: 'hsla(38, 96%, 49%, 1)',
  500: 'hsla(38, 96%, 54%, 1)',
  300: 'hsla(38, 96%, 64%, 1)',
  100: 'hsla(38, 92%, 95%, 1)',
});

const secondaryColor: SecondaryColor = {
  900: 'hsla(228, 36%, 38%, 1)',
  700: 'hsla(228, 36%, 43%, 1)',
  500: 'hsla(228, 36%, 48%, 1)',
  300: 'hsla(228, 36%, 58%, 1)',
  100: 'hsla(228, 36%, 95%, 1)',
};

const spaces = [0, 4, 8, 12, 16, 20, 24, 32, 48, 64, 96, 128, 256, 512];

const breakpoints: Breakpoint = [576, 768, 992, 1200].map((b) => `${b}px`);
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const radiiList = [0, 2, 4, 16, 9999, '100%'];
const radii: Readonly<Radii> = Object.freeze({
  none: radiiList[0],
  tiny: radiiList[1],
  small: radiiList[2],
  medium: radiiList[3],
  large: radiiList[4],
  maximum: radiiList[5],
});

const textStyles: TextStyle = {
  heading1: {
    fontSize: fontSizes.heading1,
    fontWeight: fontWeights.medium,
    letterSpacing: letterSpacings.thinner,
    lineHeight: lineHeights.mainTitle,
  },
  heading2: {
    fontSize: fontSizes.heading2,
    fontWeight: fontWeights.medium,
    letterSpacing: letterSpacings.thinner,
    lineHeight: lineHeights.mainTitle,
  },
  heading3: {
    fontSize: fontSizes.heading3,
    fontWeight: fontWeights.medium,
    letterSpacing: letterSpacings.thin,
    lineHeight: lineHeights.emphasisText,
  },
  heading4: {
    fontSize: fontSizes.heading4,
    fontWeight: fontWeights.medium,
    letterSpacing: letterSpacings.default,
    lineHeight: lineHeights.subElementTitle,
  },
  heading5: {
    fontSize: fontSizes.heading5,
    fontWeight: fontWeights.medium,
    letterSpacing: letterSpacings.default,
    lineHeight: lineHeights.emphasisText,
  },
  subtitle1: {
    fontSize: fontSizes.subtitle1,
    fontWeight: fontWeights.medium,
    letterSpacing: letterSpacings.normal,
    lineHeight: lineHeights.emphasisText,
  },
  body1: {
    fontSize: fontSizes.body1,
    fontWeight: fontWeights.normal,
    letterSpacing: letterSpacings.normal,
    lineHeight: lineHeights.normal,
  },
  body1Medium: {
    fontSize: fontSizes.body1,
    fontWeight: fontWeights.semibold,
    letterSpacing: letterSpacings.normal,
    lineHeight: lineHeights.normal,
  },
  body2: {
    fontSize: fontSizes.body2,
    fontWeight: fontWeights.normal,
    letterSpacing: letterSpacings.wide,
    lineHeight: lineHeights.emphasisText,
  },
  caption: {
    fontSize: fontSizes.caption,
    fontWeight: fontWeights.normal,
    letterSpacing: letterSpacings.wider,
    lineHeight: lineHeights.normal,
  },
};

// TODO: need more generic naming for variants once design requirements are made
const variants: Readonly<Variant> = Object.freeze({
  shadowBoxHeader: 'default',
  button: 'default',
  navigationBar: 'default',
  activePaginationText: 'default',
  activeFilterText: 'default',
});

function getLightColors({
  primary = primaryColor,
  secondary = secondaryColor,
  grayscale = grayscaleLevel,
  system = systemColor,
  white = whiteColor,
  black = blackColor,
}: GetColorInput = {}): Color {
  const backgroundColor: BackgroundColors = {
    // default background color state
    uiState: {
      default: 'transparent',
      hover: grayscale['100'],
      active: primary['100'],
    },

    // custom state
    courseDetailSectionSelection: {
      default: grayscale['200'],
      hover: grayscale['300'],
      active: 'white',
    },
    input: {
      default: 'white',
      disabled: grayscale['200'],
      hover: 'white',
    },

    // custom color
    surface: 'white',
    courseDetail: grayscale['100'],
    navigationBar: 'white',
    card: 'white',
    heroSection: primary['100'],
    previewCourseAnnouncementBox: grayscale['100'],
    authBoxHeader: 'white',
    authBoxHeaderSecondary: secondary['500'],
    purchaseHistoryCardHeader: {
      success: system.success['100'],
      pending: system.warning['100'],
      failed: system.danger['100'],
    },
    alert: {
      success: system.success['100'],
      error: system.danger['100'],
      warning: system.warning['100'],
      info: system.info['100'],
    },
    default: 'white',
    skeleton: grayscale['200'],
  };

  const borderColor: BorderColors = {
    // default border color state
    uiState: {
      default: grayscale['300'],
      hover: primary['700'],
    },

    // custom color
    divider: 'hsla(0, 0%, 96%, 1)',
    formDivider: grayscale['200'],
    courseDetailSectionSelection: primary[500],
    containerBox: 'none',
    purchaseHistoryCard: grayscale['100'],
    activeChoice: grayscale[900],
  };

  const textColor: TextColor = {
    darkText: grayscale['900'],
    darkTextAlt: grayscale['600'],
    lightTextAlt: grayscale['400'],
    lightText: grayscale['200'],
    redText: system.danger['700'],
    primaryText: primary['500'],

    // default text color state
    uiState: {
      active: primary['900'],
      disabled: grayscale['600'],
      error: system.danger['500'],
    },

    // custom color
    pagination: primary['500'],
    alert: {
      success: system.success['900'],
      error: system.danger['900'],
      warning: system.warning['900'],
      info: system.info['900'],
    },
  };

  const buttonColor: ButtonColor = {
    // default button color state
    uiState: {
      disabled: grayscale['500'],
    },

    // custom color
    outlined: primary['500'],
  };

  const iconColor: IconColor = {
    // default icon color state
    uiState: { default: primary['500'], disabled: grayscale['300'] },

    // custom color
    largeIcon: grayscale['200'],
  };

  return {
    black,
    white,
    grayscale,
    primary,
    secondary,
    system,

    icon: iconColor,
    background: backgroundColor,
    text: textColor,
    button: buttonColor,
    border: borderColor,
  };
}

function getDarkColors({
  primary = primaryColor,
  secondary = secondaryColor,
  grayscale = grayscaleLevel,
  system = systemColor,
  white = whiteColor,
  black = blackColor,
}: GetColorInput = {}): Color {
  const darkTextColor = 'hsla(0, 0%, 100%, 0.87)';
  const darkBgColor = 'hsla(0, 0%, 7%, 1)';
  const paper5 = 'hsla(0, 0%, 100%, 0.05)';
  const paper9 = 'hsla(0, 0%, 100%, 0.09)';

  const backgroundColor: BackgroundColors = {
    // default background color state
    uiState: {
      default: grayscale['900'],
      hover: grayscale['800'],
      active: primary['700'],
    },

    // custom state
    courseDetailSectionSelection: {
      default: paper5,
      active: paper9,
      hover: grayscale['800'],
    },
    input: {
      default: grayscale['900'],
      disabled: grayscale['900'],
      hover: grayscale['800'],
    },

    // custom color
    surface: darkBgColor,
    courseDetail: darkBgColor,
    navigationBar: paper9,
    card: paper5,
    heroSection: darkBgColor,
    previewCourseAnnouncementBox: paper5,
    authBoxHeader: paper9,
    authBoxHeaderSecondary: paper9,
    purchaseHistoryCardHeader: {
      success: grayscale['900'],
      pending: grayscale['900'],
      failed: grayscale['900'],
    },
    alert: { success: 'white', error: 'white', warning: 'white', info: 'white' },
    default: 'hsl(240, 1%, 15%)',
    skeleton: grayscale['800'],
  };

  const borderColor: BorderColors = {
    // default border color state
    uiState: {
      default: grayscale['800'],
      hover: grayscale['700'],
    },

    // custom color
    divider: grayscale['800'],
    formDivider: grayscale['800'],
    courseDetailSectionSelection: grayscale['200'],
    containerBox: grayscale['800'],
    purchaseHistoryCard: grayscale['800'],
    activeChoice: grayscale[700],
  };

  const textColor: TextColor = {
    darkText: darkTextColor,
    darkTextAlt: 'hsla(0, 0%, 100%, 0.6)',
    lightTextAlt: grayscale['400'],
    lightText: grayscale['200'],
    redText: system.danger['300'],
    primaryText: primary['300'],

    // default text color state
    uiState: {
      active: 'white',
      disabled: grayscale['700'],
      error: system.danger['300'],
    },

    // custom color
    pagination: darkTextColor,
    alert: {
      success: 'black',
      error: 'black',
      warning: 'black',
      info: 'black',
    },
  };

  const buttonColor: ButtonColor = {
    // default button color state
    uiState: {
      disabled: grayscale['800'],
    },

    // custom color
    outlined: grayscale['300'],
  };

  const iconColor: IconColor = {
    // default icon color state
    uiState: { default: darkTextColor, disabled: grayscale['800'] },

    // custom color
    largeIcon: grayscale['400'],
  };

  return {
    black,
    white,
    grayscale,
    primary,
    secondary,
    system,

    icon: iconColor,
    background: backgroundColor,
    text: textColor,
    button: buttonColor,
    border: borderColor,
  };
}

export {
  fontSizes,
  fontWeights,
  letterSpacings,
  spaces,
  breakpoints,
  radii,
  variants,
  lineHeights,
  blackColor,
  whiteColor,
  primaryColor,
  secondaryColor,
  systemColor,
  grayscaleLevel as grayscale,
  textStyles,
  getDarkColors,
  getLightColors,
};
