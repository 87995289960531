import { useTheme } from 'styled-components';

import SVGIcon, { ICON_LIST } from '../SVGIcon';
import { Text, Flex } from '../Base';
import { AccountProps } from './types';

const Account = ({ name, style = {} }: AccountProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" px={3} py={4} style={style}>
      <SVGIcon name={ICON_LIST.USER} color={theme.colors.text.darkTextAlt} />
      <Text textStyle="body1Medium" color="text.darkText" ml={4}>
        {name}
      </Text>
    </Flex>
  );
};

export default Account;
