const getImageSrcSetString = (imageSrcSet: Record<string, string> = {}) => {
  let srcSet = '';
  let keys = Object.keys(imageSrcSet);
  if (keys.length > 0) {
    let lastKey = Object.keys(imageSrcSet)[keys.length - 1];
    for (let pixelDensity in imageSrcSet) {
      srcSet += `${imageSrcSet[pixelDensity]} ${pixelDensity}`;
      if (pixelDensity !== lastKey) srcSet += ', ';
    }
  }
  return srcSet;
};

export default getImageSrcSetString;
