import styled, { css } from 'styled-components';

import Flex from '../Base/Flex';

import { NavigationProps, MenuItemProps } from './types';
import { isActivePath } from './isActivePath';

const Link = styled.a`
  ${({ theme }) => ({ ...theme.textStyles.body1Medium })}
`;

const MenuItem = styled.li<MenuItemProps>`
  ${({ theme, $isActive }) => css`
    list-style: none;
    padding: ${theme.space['4']}px;
    min-width: max-content;

    &::marker {
      display: none;
    }

    .link {
      color: ${theme.colors.text.darkText};
      cursor: pointer;
      &:hover {
        color: ${theme.colors.text.primaryText};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      &:first-child {
        padding-left: 0;
      }
    }

    ${$isActive &&
    css`
      .link {
        cursor: pointer;
        color: ${theme.colors.primary['500']};
      }

      /* position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: ${theme.colors.secondary['500']};
        border-bottom: 3px solid ${theme.colors.secondary['500']};
      } */
    `}
  `}
`;

const NavigationWrapper = styled.nav`
  box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
  background-color: ${({ theme }) => theme.colors.background.navigationBar};
`;

const Navigation = ({
  menuItems,
  currentPath,
  inputLinkComponent,
}: NavigationProps): JSX.Element => {
  const LinkComponent = inputLinkComponent ?? Link;
  return (
    <NavigationWrapper>
      <Flex
        maxWidth={1200}
        width={1}
        overflow="auto"
        alignItems="center"
        m="0 auto"
        pl={{ _: 0, md: 4 }}
        as="ul"
      >
        {menuItems?.map(({ permalink, menuText, target }) => {
          const isActive = isActivePath(currentPath, permalink);
          return (
            <MenuItem $isActive={isActive} key={menuText}>
              <LinkComponent href={permalink} className="link" target={target}>
                {menuText}
              </LinkComponent>
            </MenuItem>
          );
        })}
      </Flex>
    </NavigationWrapper>
  );
};

export default Navigation;
