import gql from 'graphql-tag';

export const getMyCourses = gql`
  query myCourses($mode: STUDENT_COURSE_QUERY_MODE = FULL) {
    myCourses(status: ACTIVE, language: "TH", mode: $mode) {
      id
      courseInfo {
        courseId
        courseCode
      }
    }
  }
`;

export const getWorkshopBatchesBySKUCode = gql`
  query sfFoProductWorkshopBatchBySKUCode($SKUCode: String!) {
    sfFoProductWorkshopBatchBySKUCode(SKUCode: $SKUCode) {
      id
      title
      priceInclVAT_value
      salePriceInclVAT_value
      priceInclVAT_unit
      salePriceInclVAT_unit
      eventTime {
        startDate
        endDate
        startTime
        endTime
      }
      venueDisplayName
      venueLocation {
        displayAddress
      }
    }
  }
`;
