import { FormProvider, useForm } from 'react-hook-form';

import { PurchaseInput, usePayment } from '../PaymentProvider';

const PaymentFormProvider = ({ children }): JSX.Element => {
  const { handlePurchaseOrder } = usePayment();
  const paymentFormMethods = useForm<PurchaseInput>({
    mode: 'onTouched',
  });

  const { handleSubmit } = paymentFormMethods;

  return (
    <FormProvider {...paymentFormMethods}>
      <form onSubmit={handleSubmit(handlePurchaseOrder)}>{children}</form>
    </FormProvider>
  );
};

export default PaymentFormProvider;
