import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DownloadApp as DownloadAppView,
  SupportedPlatformProps,
  LoadingModal,
} from '@skooldio/paas-shared-react-components';

import { useConfig } from '../../Contexts/ConfigContext';
import useMySalesOrder from '../../Domain/useMySalesOrder';
import useMyCourseData from '../../Domain/useMyCourseData';
import { sendDataToGTM } from '../../Utils/analytics/googleTagManager';
import { mapPurchaseProcessCompletedPayload } from '../../Utils/analytics/mapping';
import { STOREFRONT_TYPE } from '../../Utils/constant';

const getClassroomUrl = ({
  classroomURL,
  courseId,
}: {
  classroomURL?: string;
  courseId?: string;
}) => {
  if (classroomURL && courseId) {
    return classroomURL?.replace(':courseId', courseId);
  }
  return undefined;
};

const DownloadApp = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [isOrderIdValidating, setIsOrderIdValidating] = useState(true);
  const navigate = useNavigate();
  const {
    macStoreUrl,
    windowsStoreUrl,
    appStoreUrl,
    playStoreUrl,
    downloadApp: downloadAppConfigs,
    classroomURL,
    storefrontType,
  } = useConfig();
  const supportedPlatforms = [
    { name: 'MAC', url: macStoreUrl ?? '' },
    { name: 'WINDOWS', url: windowsStoreUrl ?? '' },
    { name: 'IOS', url: appStoreUrl ?? '' },
    { name: 'ANDROID', url: playStoreUrl ?? '' },
  ].filter((platform) => platform.url) as SupportedPlatformProps[];

  const { data: mySalesOrderData } = useMySalesOrder({
    orderNo: orderId as string,
    onCompleted: (saleOrderData) => {
      if (saleOrderData) {
        const purchaseProcessCompletedPayload = mapPurchaseProcessCompletedPayload(saleOrderData);
        sendDataToGTM(purchaseProcessCompletedPayload);
      }
      setIsOrderIdValidating(false);
    },
    onTimeout: (saleOrderData) => {
      if (saleOrderData) {
        const purchaseProcessCompletedPayload = mapPurchaseProcessCompletedPayload(saleOrderData);
        sendDataToGTM(purchaseProcessCompletedPayload);
      }
      navigate('/purchase-history');
    },
  });

  const baseReferSKUId = mySalesOrderData?.items?.[0]?.product?.baseReferSKUId;

  const { data: myCourseData } = useMyCourseData(
    {
      courseCode: baseReferSKUId,
    },
    {
      skip: storefrontType !== STOREFRONT_TYPE.LMS,
    }
  );

  const courseId = myCourseData?.courseInfo?.courseId;
  const classroomFullURL = getClassroomUrl({ classroomURL, courseId });

  if (isOrderIdValidating) {
    return <LoadingModal isOpen={isOrderIdValidating} />;
  }

  return (
    <DownloadAppView
      title={downloadAppConfigs?.title ?? 'ลงทะเบียนคอร์สนี้เรียบร้อยแล้ว'}
      description={downloadAppConfigs?.description ?? undefined}
      appImageUrl={downloadAppConfigs?.brandAppIconURL ?? '/static/images/upgrade-app-icon.png'}
      supportedPlatforms={supportedPlatforms}
      classroomUrl={classroomFullURL}
      isCourseEnrolling={!courseId}
    />
  );
};

export default DownloadApp;
