import { createRef, useState, useReducer, useMemo, useCallback } from 'react';
import { useConfig } from '../../Contexts/ConfigContext';
import useLoadScript from '../useLoadScript';
import TH_ADDRESS_DATA from './addressTH';

const addressRef = createRef();
const STATUS = {
  READY: 'READY',
  LOADING: 'LOADING',
};

const useAddressData = ({ province = '', amphur = '', district = '', zipcode = '' } = {}) => {
  const config = useConfig();
  const [status, setStatus] = useState(addressRef.current ? STATUS.READY : STATUS.LOADING);
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    province,
    amphur,
    district,
    zipcode,
  });

  useLoadScript({
    id: 'addressTH',
    src: config?.dataSources?.address,
    onLoad: () => {
      const remoteAddress = window?.Skooldio?.address;
      const addressData = remoteAddress ? remoteAddress : TH_ADDRESS_DATA;
      addressRef.current = new Map(addressData);
      setStatus(STATUS.READY);
    },
    onError: () => {
      addressRef.current = new Map(TH_ADDRESS_DATA);
      setStatus(STATUS.READY);
    },
  });

  const provinceOptions = useMemo(() => {
    if (status !== STATUS.READY) return [];
    return Array.from(addressRef.current.keys());
  }, [status]);

  const { amphurOptions, amphurMap } = useMemo(() => {
    if (status !== STATUS.READY || !state.province) {
      return {
        amphurOptions: [],
        amphurData: null,
      };
    }
    const provinceData = addressRef.current.get(state.province);
    const amphurMap = new Map(provinceData);
    return {
      amphurOptions: Array.from(amphurMap.keys()),
      amphurMap,
    };
  }, [status, state.province]);

  const { districtOptions, districtMap } = useMemo(() => {
    if (status !== STATUS.READY || !state.amphur || !amphurMap) {
      return {
        districtOptions: [],
        districtMap: null,
      };
    }
    const amphurData = amphurMap.get(state.amphur);
    const districtMap = new Map(amphurData);
    return {
      districtOptions: Array.from(districtMap.keys()),
      districtMap,
    };
  }, [status, state.amphur, amphurMap]);

  const zipcodeOptions = useMemo(() => {
    if (status !== STATUS.READY || !state.district || !districtMap) return [];
    return districtMap.get(state.district).map((code) => code.toString());
  }, [status, state.district, districtMap]);

  const handleSetProvince = useCallback(
    (updateProvince) =>
      setState({ province: updateProvince, amphur: '', district: '', zipcode: '' }),
    []
  );

  const handleSetAmphur = useCallback(
    (updateAmphur) =>
      setState({
        amphur: updateAmphur ?? '',
        district: '',
        zipcode: '',
      }),
    []
  );

  const handleSetDistrict = useCallback(
    (updateDistrict) => {
      let updateZipcode = '';
      const districts = districtMap?.get(updateDistrict);
      if (Array.isArray(districts) && districts.length) {
        const zipcodeOptions = districts.map((code) => code.toString());
        if (districts.length === 1) {
          updateZipcode = zipcodeOptions[0];
        }
      }
      setState({
        district: updateDistrict ?? '',
        zipcode: updateZipcode ?? '',
      });
    },
    [districtMap]
  );

  const handleSetZipcode = useCallback(
    (updateZipcode) =>
      setState({
        zipcode: updateZipcode ?? '',
      }),
    []
  );

  return [
    {
      province: state.province,
      amphur: state.amphur,
      district: state.district,
      zipcode: state.zipcode,
    },
    {
      setProvince: handleSetProvince,
      setAmphur: handleSetAmphur,
      setDistrict: handleSetDistrict,
      setZipcode: handleSetZipcode,
    },
    {
      provinces: provinceOptions,
      amphurs: amphurOptions,
      districts: districtOptions,
      zipcodes: zipcodeOptions,
    },
    { status, address: addressRef.current },
  ];
};

export { STATUS };
export default useAddressData;
