const MagnifyingGlass = (props: any): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9428 15.9433C16.3333 15.5528 16.9665 15.5528 17.357 15.9433L21.707 20.2933C22.0975 20.6838 22.0975 21.317 21.707 21.7075C21.3165 22.098 20.6833 22.098 20.2928 21.7075L15.9428 17.3575C15.5523 16.967 15.5523 16.3338 15.9428 15.9433Z"
      />
    </svg>
  );
};

export default MagnifyingGlass;
