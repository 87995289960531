import styled from 'styled-components';

import { Box, Flex, Text } from '../Base';
import MenuItem from '../MenuItem';
import { SideMenuProps } from './types';

const StyledMenuItem = styled(MenuItem)`
  border-radius: 8px;
`;

const SideMenu = ({
  title = '',
  menus,
  menuActiveLabel,
  onClickMenuItem,
}: SideMenuProps): JSX.Element => (
  <Box>
    <Text textStyle={'heading4'} color="text.darkText">
      {title}
    </Text>
    <Flex flexDirection="column" mt={7} style={{ gap: '8px' }}>
      {menus?.map((menu) => (
        <StyledMenuItem
          key={menu.label}
          {...menu}
          isActive={menuActiveLabel === menu.label}
          onClick={() => {
            menu.onClick();
            onClickMenuItem && onClickMenuItem();
          }}
        />
      ))}
    </Flex>
  </Box>
);

export default SideMenu;
