import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  SVGIcon,
  TopStripeModal,
} from '@skooldio/paas-shared-react-components';

import { PURCHASE_ORDER_ERROR } from '../../Domain/usePurchaseOrder.d';
import { Text, ButtonFilled } from '../Base';
import { PurchaseFailedModalProps } from './types';

const getPurchaseOrderErrorMessage = (error: PURCHASE_ORDER_ERROR) => {
  switch (error) {
    case PURCHASE_ORDER_ERROR.DUPLICATED_ONLINE_COURSE:
      return {
        title: 'purchase_failed_modal_item_exists_title',
        description: 'purchase_failed_modal_item_exists_description',
        buttonText: 'close',
      };
    default:
      return {
        title: 'purchase_failed_modal_default_title',
        description: 'purchase_failed_modal_default_description',
        buttonText: 'try_again',
      };
  }
};

const PurchaseFailedModal = ({
  open,
  purchaseOrderFailedMessage,
  onModalClose,
  ...props
}: PurchaseFailedModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { title, description, buttonText } = getPurchaseOrderErrorMessage(
    purchaseOrderFailedMessage
  );

  return (
    <TopStripeModal disableEscapeKeyDown disableBackdropClick open={open} {...props}>
      <ModalHeader style={{ textAlign: 'center' }}>
        <SVGIcon name={'ERROR'} width={'72px'} height={'72px'} color={colors.primary[500]} />
        <Text mt={7} textStyle={{ _: 'heading4', md: 'heading3' }}>
          {t(title)}
        </Text>
      </ModalHeader>
      <ModalContent style={{ textAlign: 'center' }}>
        <Text
          mt={4}
          textStyle={'body1'}
          color={'text.darkText'}
          style={{ 'white-space': 'pre-line' }}
        >
          {t(description)}
        </Text>
      </ModalContent>
      <ModalFooter>
        <ButtonFilled
          mt={7}
          py={'6px'}
          width={{ _: '100%', sm: 'auto' }}
          minWidth={160}
          borderRadius={25}
          color="primary"
          onClick={onModalClose}
        >
          <Text textStyle={'body1'} fontWeight={600} color={'white'}>
            {t(buttonText)}
          </Text>
        </ButtonFilled>
      </ModalFooter>
    </TopStripeModal>
  );
};

export default PurchaseFailedModal;
