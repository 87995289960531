import { SKU_CATEGORY } from '../../../Contexts/PaymentProvider/PaymentProvider.d';

const mapGTMProductType = (productType?: SKU_CATEGORY) => {
  switch (productType) {
    case SKU_CATEGORY.ONLINE_COURSE:
      return 'COURSE';
    case SKU_CATEGORY.COLLECTION:
      return 'BUNDLE';
    case SKU_CATEGORY.WORKSHOP_BATCH:
      return 'WORKSHOP';
    default:
      return 'N/A';
  }
};

export default mapGTMProductType;
