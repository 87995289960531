import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import retryWithDelay from '../../Utils/retryWithDelay';
import { getMyCourses } from '../../GraphQL/query/Product.query';
import { CLASSROOM_MODE, MyCourseDataInputProps, MyCoursesQuery, MyCoursesInput } from './types';

const getMyCourseByCourseCode = (myCoursesData, courseCode) => {
  const myPurchasedOnlineCourses = myCoursesData?.myCourses ?? [];

  const myPurchasedOnlineCourse = myPurchasedOnlineCourses.find(
    (onlineCourse) => onlineCourse?.courseInfo?.courseCode === courseCode
  );
  return myPurchasedOnlineCourse;
};

const useMyCourseData = ({ courseCode }: MyCourseDataInputProps, apolloProps: any = {}) => {
  const {
    data: myCoursesData,
    loading,
    error,
    refetch: refetchMyCourseData,
  } = useQuery<MyCoursesQuery, MyCoursesInput>(getMyCourses, {
    variables: { mode: CLASSROOM_MODE.FULL },
    onError: (error) => console.error(error),
    ...apolloProps,
  });

  const { skip } = apolloProps;

  useEffect(() => {
    if (courseCode && !skip) {
      retryWithDelay(
        async () => {
          try {
            const { data } = await refetchMyCourseData();
            const courseData = getMyCourseByCourseCode(data, courseCode);

            if (courseData) {
              return true;
            }
          } catch (error) {
            console.error(error);
          }
          return false;
        },
        // failed
        () => {},
        // maxAttempts (seconds)
        50
      );
    }
  }, [courseCode]);

  return {
    data: getMyCourseByCourseCode(myCoursesData, courseCode),
    loading,
    error,
  };
};

export default useMyCourseData;
