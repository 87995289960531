const specialPath = {
  '/courses/[permalink]': '/browse',
};

export const isActivePath = (currentPath: string, permalink: string): boolean => {
  if (specialPath[currentPath]) {
    return specialPath[currentPath] === permalink;
  }
  return currentPath.includes(permalink);
};
