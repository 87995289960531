import { SideMenu as SideMenuView } from '@skooldio/paas-shared-react-components';

import { useAccountMenu } from '../../Contexts/AccountMenuContext';
import { useAuth } from '../../Contexts/AuthContext';

const SideMenu = (): JSX.Element => {
  const { accountMenu, availableAccountMenuList } = useAccountMenu();
  const { userInfo } = useAuth();

  const userFullName =
    userInfo?.firstname && userInfo?.lastname ? `${userInfo.firstname} ${userInfo.lastname}` : '';

  return (
    <SideMenuView
      title={userFullName}
      menus={availableAccountMenuList}
      menuActiveLabel={accountMenu.HISTORY.label}
    />
  );
};

export default SideMenu;
