import { useTheme } from 'styled-components';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import PaymentMethodOptionLabel from './PaymentMethodOptionLabel';
import { PaymentMethodOptionProps } from './PaymentMethod.d';

const useStyles = ({ isSelected }): ClassNameMap<'ratio' | 'optionItemWrapper'> => {
  const theme = useTheme();
  const classes = makeStyles({
    ratio: {
      color: theme.colors.primary[500],
    },
    optionItemWrapper: {
      border: isSelected
        ? `1px solid ${theme.colors.primary[500]}`
        : `1px solid ${theme.colors.grayscale[300]}`,
      padding: `${theme.space[5]}px ${theme.space[6]}px`,
      borderRadius: `${theme.space[2]}px`,
      margin: 0,
    },
  })();

  return classes;
};

const PaymentMethodOption = (props: PaymentMethodOptionProps): JSX.Element => {
  const {
    text = 'ชำระด้วย PromptPay (QR Code)',
    imageList = [],
    method = 'promptpay',
    isSelected,
  } = props;
  const classes = useStyles({ isSelected });
  return (
    <FormControlLabel
      data-testid={`payment-method-option-${method}`}
      classes={{ root: classes.optionItemWrapper }}
      key={method}
      value={method}
      control={<Radio classes={{ checked: classes.ratio }} color="default" />}
      label={<PaymentMethodOptionLabel text={text} imageList={imageList} />}
    />
  );
};

export default PaymentMethodOption;
