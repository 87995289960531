const CertificateCardBadge = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={44} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCAB19"
          d="m10.29 20.357 9.753 3.418-6.621 19.574-1.348-2.953-3.553 1.322-1.565-3.098-3.288 1.31z"
        />
        <path
          fill="#FFCC69"
          d="m21.993 20.357-9.754 3.418L18.86 43.35l1.348-2.953 3.553 1.322 1.564-3.098 3.289 1.31z"
        />
        <circle cx={16} cy={16} r={16} fill="#FCAB19" />
        <circle cx={16} cy={16} r={13} fill="#FFCC69" />
        <path fill="#FFF" d="M13.75 19.128 10.623 16l-1.066 1.058 4.193 4.192 9-9-1.058-1.057z" />
      </g>
    </svg>
  );
};

export default CertificateCardBadge;
