import { useTheme } from 'styled-components';
import {
  LayoutProps,
  SpaceProps,
  TypographyProps,
  BorderProps,
  ButtonStyleProps,
} from 'styled-system';
import { ButtonFilled as BaseButtonFilled } from './Button';
import { BUTTON_VARIANTS } from './types';

interface ButtonFilledProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    LayoutProps,
    SpaceProps,
    TypographyProps,
    BorderProps,
    ButtonStyleProps {
  textColor?: string;
  color?: string;
  children: React.ReactNode;
}

const BUTTON_COLOR_VARIANTS = {
  [BUTTON_VARIANTS.textColorSecondary]: { backgroundColor: 'primary', textColor: 'secondary.500' },
  [BUTTON_VARIANTS.default]: { backgroundColor: 'primary', textColor: 'white' },
};

const ButtonFilled = ({
  textColor: textColorProp,
  color: colorProp,
  children,
  ...props
}: ButtonFilledProps): JSX.Element => {
  const theme = useTheme();
  const buttonVariant = theme.variants.button;
  const { backgroundColor, textColor } = BUTTON_COLOR_VARIANTS?.[buttonVariant] ?? {};

  return (
    <BaseButtonFilled
      textColor={textColorProp ?? textColor}
      color={colorProp ?? backgroundColor}
      {...props}
    >
      {children}
    </BaseButtonFilled>
  );
};

export default ButtonFilled;
