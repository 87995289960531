import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const FORMAT_DATE = 'DD';
const FORMAT_DATE_MONTH = 'DD MMM';
const FORMAT_DATE_YEAR = 'DD MMM YYYY';
const FORMAT_DAY = 'ddd';

// This function is duplicated from DateUtils.js in skooldio-web repo.
export const formatDateRange = (
  startDateStr,
  endDateStr,
  defaultDateStr,
  options: {
    sameDayFormat?: string;
    sameMonthFormat?: string;
    sameYearFormat?: string;
    differentYearFormat?: string;
  } = {}
) => {
  const {
    sameDayFormat = `${FORMAT_DAY}, ${FORMAT_DATE_YEAR}`,
    sameMonthFormat = `${FORMAT_DAY}, ${FORMAT_DATE}`,
    sameYearFormat = `${FORMAT_DAY}, ${FORMAT_DATE_MONTH}`,
    differentYearFormat = `${FORMAT_DAY}, ${FORMAT_DATE_YEAR}`,
  } = options;

  if (!startDateStr && !endDateStr) return defaultDateStr;

  const startDate = dayjs(startDateStr);
  if (!endDateStr) return startDate.format(sameDayFormat);

  const endDate = dayjs(endDateStr);

  if (startDate.isSame(endDate, 'day')) {
    return `${startDate.format(sameDayFormat)}`;
  }
  if (startDate.isSame(endDate, 'month')) {
    return `${startDate.format(sameMonthFormat)} - ${endDate.format(FORMAT_DATE_YEAR)}`;
  }
  if (startDate.isSame(endDate, 'year')) {
    return `${startDate.format(sameYearFormat)} - ${endDate.format(FORMAT_DATE_YEAR)}`;
  }
  return `${startDate.format(differentYearFormat)} - ${endDate.format(FORMAT_DATE_YEAR)}`;
};

export const formatDate = (dateStr: Date | string, format = FORMAT_DATE_MONTH) => {
  return dayjs(dateStr).format(format);
};

export const getFullDate = (dateString) => {
  const date = new Date(dateString);
  // Returns DD/MM/YY (year in B.E.)
  // Example output: 18/7/65
  const options: Intl.DateTimeFormatOptions = {
    dateStyle: 'short',
  };
  return new Intl.DateTimeFormat('th-TH', options).format(date);
};

export const get24hTime = (dateString) => {
  const date = new Date(dateString);
  // Example output: 21:52
  const options: Intl.DateTimeFormatOptions = {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Intl.DateTimeFormat('th-TH', options).format(date);
};

export const getFullThaiDateWithDay = (dateString) => {
  const date = new Date(dateString);
  // Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Asia/Bangkok',
  };
  return new Intl.DateTimeFormat('th-TH', options).format(date);
};

export const getFullThaiDate = (dateString) => {
  const date = new Date(dateString);
  // Example output: 18 มกราคม 2565
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Asia/Bangkok',
  };
  return new Intl.DateTimeFormat('th-TH', options).format(date);
};

export const getShortThaiDate = (dateString) => {
  const date = new Date(dateString);
  // Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    timeZone: 'Asia/Bangkok',
  };
  return new Intl.DateTimeFormat('th-TH', options).format(date);
};

export const getShortThaiDateWithDay = (dateString) => {
  const date = new Date(dateString);
  // Example output: พฤ. 5 ต.ค. 2566
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'Asia/Bangkok',
  };
  return new Intl.DateTimeFormat('th-TH', options).format(date);
};

export const getFullThaiDateWithFormattedDay = (dateString) => {
  const date = new Date(dateString);
  // Example output: ศุกร์ 1 ก.ย. 2566
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'Asia/Bangkok',
  };
  return new Intl.DateTimeFormat('th-TH', options)
    .format(date)
    .replace('วัน', '')
    .replace('ที่', '');
};

export const getShortThaiDateFullYear = (dateString) => {
  const date = new Date(dateString);
  // Example output: 18 ก.ค. 2565
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'Asia/Bangkok',
  };
  return new Intl.DateTimeFormat('th-TH', options).format(date);
};

export const formatTimeFromSeconds = (seconds) => {
  const hour = Math.floor(dayjs.duration(seconds, 'seconds').asHours());
  const minute = dayjs.duration(seconds, 'seconds').minutes();
  if (hour > 0) {
    if (minute > 0) {
      return `${hour} ชั่วโมง ${minute} นาที`;
    }
    return `${hour} ชั่วโมง`;
  }
  return `${minute} นาที`;
};

export const intlDateLocale = {
  th: 'th-TH',
  en: 'en-US',
};
