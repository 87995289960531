const ExtensionOutlined = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.86667 14H3.33333C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V10.1333C2.53333 10.1333 3 9.964 3.4 9.62533C3.8 9.28622 4 8.85556 4 8.33333C4 7.81111 3.8 7.38045 3.4 7.04133C3 6.70267 2.53333 6.53333 2 6.53333V4C2 3.63333 2.13044 3.31956 2.39133 3.05867C2.65267 2.79733 2.96667 2.66667 3.33333 2.66667H6C6 2.2 6.16111 1.80556 6.48333 1.48333C6.80556 1.16111 7.2 1 7.66667 1C8.13333 1 8.52778 1.16111 8.85 1.48333C9.17222 1.80556 9.33333 2.2 9.33333 2.66667H12C12.3667 2.66667 12.6807 2.79733 12.942 3.05867C13.2029 3.31956 13.3333 3.63333 13.3333 4V6.66667C13.8 6.66667 14.1944 6.82778 14.5167 7.15C14.8389 7.47222 15 7.86667 15 8.33333C15 8.8 14.8389 9.19444 14.5167 9.51667C14.1944 9.83889 13.8 10 13.3333 10V12.6667C13.3333 13.0333 13.2029 13.3473 12.942 13.6087C12.6807 13.8696 12.3667 14 12 14H9.46667C9.46667 13.4444 9.29178 12.9722 8.942 12.5833C8.59178 12.1944 8.16667 12 7.66667 12C7.16667 12 6.74178 12.1944 6.392 12.5833C6.04178 12.9722 5.86667 13.4444 5.86667 14ZM3.33333 12.6667H4.75C5.01667 11.9333 5.44444 11.4167 6.03333 11.1167C6.62222 10.8167 7.16667 10.6667 7.66667 10.6667C8.16667 10.6667 8.71111 10.8167 9.3 11.1167C9.88889 11.4167 10.3167 11.9333 10.5833 12.6667H12V8.66667H13.3333C13.4222 8.66667 13.5 8.63333 13.5667 8.56667C13.6333 8.5 13.6667 8.42222 13.6667 8.33333C13.6667 8.24444 13.6333 8.16667 13.5667 8.1C13.5 8.03333 13.4222 8 13.3333 8H12V4H8V2.66667C8 2.57778 7.96667 2.5 7.9 2.43333C7.83333 2.36667 7.75556 2.33333 7.66667 2.33333C7.57778 2.33333 7.5 2.36667 7.43333 2.43333C7.36667 2.5 7.33333 2.57778 7.33333 2.66667V4H3.33333V5.46667C3.93333 5.68889 4.41667 6.06111 4.78333 6.58333C5.15 7.10556 5.33333 7.68889 5.33333 8.33333C5.33333 8.96667 5.15 9.54445 4.78333 10.0667C4.41667 10.5889 3.93333 10.9667 3.33333 11.2V12.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExtensionOutlined;
