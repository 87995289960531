const DiamondCoin = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={11} fill="#515FE0" />
      <circle cx={12} cy={12} r={10} fill="#fff" />
      <circle cx={12} cy={12} r={9} fill="#515FE0" />
      <path
        d="M14.783 10.274H18L16.367 7h-3.219l1.635 3.274ZM12.1 7h-.199l-1.64 3.274h3.48L12.101 7ZM12.469 11.21h5.382l-5.382 6.455V11.21ZM11.532 17.665V11.21H6.15l5.382 6.455ZM10.852 7l-1.634 3.274H6L7.634 7h3.218Z"
        fill="#fff"
      />
    </svg>
  );
};

export default DiamondCoin;
