// export const REGEX_EMAIL_FORMAT =
//   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// this regex format excludes other languages characters eg. thai.
export const REGEX_EMAIL_FORMAT =
  /^([a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([a-zA-Z0-9_+-]+\.)+[a-zA-Z]{2,})$/;

export const REGEX_PHONE_FORMAT = /[0-9]{3}-[0-9]{3}-[0-9]{4}/;

export const MOBILE_PHONE_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const REGEX_TAX_IDENTIFICATION_NUMBER_FORMAT = /^(\d{13})?$/;
