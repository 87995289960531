import styled from 'styled-components';
import MUICheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';

interface StyledCheckBoxProps extends CheckboxProps {
  $invalid?: boolean;
}

const StyledCheckbox = styled(MUICheckbox)<StyledCheckBoxProps>`
  && {
    padding: 0px;
    svg {
      width: 24px;
      height: 24px;
    }
    &.MuiCheckbox-colorSecondary {
      color: ${({ theme, $invalid }) =>
        $invalid ? theme.colors.text.redText : theme.colors.grayscale['300']};
    }
    &.MuiCheckbox-colorSecondary.Mui-checked {
      color: ${({ theme }) => theme.colors.primary[500]};
    }
  }
`;

const StyledFormControlLabel = styled(MUIFormControlLabel)`
  && {
    margin: 0;
    color: ${({ theme }) => theme.colors.text.darkText};
  }
  .MuiFormControlLabel-label {
    margin-left: 16px;
  }
`;

interface CheckboxWithLabelProps extends CheckboxProps {
  label?: string;
  invalid?: boolean;
}

const Checkbox = ({ label, invalid, ...props }: CheckboxWithLabelProps) => {
  if (label) {
    return (
      <StyledFormControlLabel
        control={<StyledCheckbox $invalid={invalid} {...props} />}
        label={label}
      />
    );
  }
  return <StyledCheckbox $invalid={invalid} {...props} />;
};

export default Checkbox;
