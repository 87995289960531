export interface handleQuickRegisterInput {
  firstName: string;
  lastName: string;
  email: string;
}

export interface QuickRegisterInputProps {
  firstName: string;
  lastName: string;
  email: string;
  consentVersion?: string;
}

export enum QUICK_REGISTER_ERROR_CODE {
  DUPLICATED_EMAIL = 400,
}

export enum QUICK_REGISTER_RESPONSE_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum REDIRECTION_MESSAGE {
  DUPLICATED_EMAIL = 'DUPLICATED_EMAIL',
}
