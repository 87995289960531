import { Grid } from '../../Base';
import { LayoutProps, SpaceProps, ColorProps, FlexboxProps } from 'styled-system';

interface ModalFooterProps
  extends React.HTMLAttributes<HTMLDivElement>,
    LayoutProps,
    SpaceProps,
    ColorProps,
    FlexboxProps {
  color?: string;
  children: React.ReactNode;
}

const ModalFooter = ({ children, ...props }: ModalFooterProps) => {
  return (
    <Grid
      gridTemplateColumns={'repeat(auto-fit, minmax(160px, auto))'}
      gridGap={4}
      justifyContent={'center'}
      alignItems={'center'}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default ModalFooter;
