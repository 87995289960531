import { usePayment } from '../../Contexts/PaymentProvider';
import { useConfig } from '../../Contexts/ConfigContext';

import { CUSTOMER_TYPE, SHIPMENT_METHOD } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import { Checkbox, Box, Text } from '@skooldio/paas-shared-react-components';
import styled from 'styled-components';
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';
import PaymentAddress from '../PaymentAddress';

const StyledFormControlLabel = styled(MUIFormControlLabel)`
  && {
    margin: 0px;
    margin-top: ${({ theme }) => theme.space[4]}px;
  }
  .MuiFormControlLabel-label {
    margin-left: 16px;
  }
`;

const PaymentTaxInvoiceDeliveryAddress = () => {
  const {
    customerType,
    updateTaxInvoiceAddressAsShipmentAddress,
    useTaxInvoiceAddressAsShipmentAddress,
  } = usePayment();
  const { payment: paymentConfig } = useConfig();

  let config = 'individual';
  if (customerType === CUSTOMER_TYPE.CORPORATE) config = 'corporate';

  const taxInvoiceDeliveryMethod =
    paymentConfig?.[config]?.taxInvoiceDeliveryMethod ?? SHIPMENT_METHOD.ONLINE;

  if (taxInvoiceDeliveryMethod === SHIPMENT_METHOD.ONLINE) return null;

  return (
    <>
      <StyledFormControlLabel
        onChange={(e, checked) => updateTaxInvoiceAddressAsShipmentAddress(checked)}
        checked={useTaxInvoiceAddressAsShipmentAddress}
        control={<Checkbox />}
        label={
          <Text textStyle="body2" color="text.darkText" fontWeight="600">
            ใช้ที่อยู่ในการจัดส่งเอกสารเดียวกับที่อยู่ในใบกำกับภาษี
          </Text>
        }
      />
      {useTaxInvoiceAddressAsShipmentAddress ? null : (
        <>
          <Box mt={4} />
          <PaymentAddress
            scope={`postalAddress`}
            addressInputProps={{ label: 'ที่อยู่ (สำหรับจัดส่งเอกสารทางไปรษณีย์)' }}
          />
        </>
      )}
    </>
  );
};

export { PaymentTaxInvoiceDeliveryAddress };
export default PaymentTaxInvoiceDeliveryAddress;
