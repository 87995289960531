import { useMemo, useEffect } from 'react';
import { EmptyPurchaseHistory, PurchaseHistoryShell } from '@skooldio/paas-shared-react-components';

import { useConfig } from '../../Contexts/ConfigContext';
import useMySalesOrderData from '../../Domain/useMySalesOrderData';
import { usePurchaseHistoryPaging } from '../../Contexts/PurchaseHistoryPagingProvider';

import PurchaseHistoryList from '../PurchaseHistoryList';
import PurchaseHistoryPagination from '../PurchaseHistoryPagination';

const PurchaseHistory = () => {
  const { currentPage, updateTotalPage } = usePurchaseHistoryPaging();
  const { purchaseHistoryCardsPerPage } = useConfig();

  const { data, loading } = useMySalesOrderData({
    paging: { pageSize: purchaseHistoryCardsPerPage ?? 5, currentPage: currentPage },
  });

  // TODO: define type useMySalesOrderData and remove <number>
  const currentTotalSalesOrderAmount = useMemo<number>(() => data?.total ?? 0, [data]);
  const notFoundSalesOrder = useMemo(() => !data || data.total === 0, [data]);

  useEffect(() => {
    updateTotalPage(currentTotalSalesOrderAmount);
  }, [currentTotalSalesOrderAmount, updateTotalPage]);

  if (loading) return null;

  return (
    <PurchaseHistoryShell total={data?.total}>
      {notFoundSalesOrder ? (
        <EmptyPurchaseHistory />
      ) : (
        <>
          <PurchaseHistoryList />
          <PurchaseHistoryPagination />
        </>
      )}
    </PurchaseHistoryShell>
  );
};

export default PurchaseHistory;
