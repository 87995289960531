const Mail = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FAA919"
      {...props}
    >
      <path
        d="M8 8H40C42.2 8 44 9.8 44 12V36C44 38.2 42.2 40 40 40H8C5.8 40 4 38.2 4 36V12C4 9.8 5.8 8 8 8Z"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M44 12L24 26L4 12" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Mail;
