import { useState, useCallback, createContext, useContext } from 'react';

import { useConfig } from '../../Contexts/ConfigContext';

const PurchaseHistoryPagingContext = createContext();

const PurchaseHistoryPagingProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const { purchaseHistoryCardsPerPage } = useConfig();

  const updateCurrentPage = useCallback((newCurrentPage) => {
    setCurrentPage(newCurrentPage);
  }, []);

  const updateTotalPage = useCallback((totalCourses) => {
    const newTotalPages = Math.ceil(totalCourses / purchaseHistoryCardsPerPage);
    setTotalPages(newTotalPages);
  }, []);

  return (
    <PurchaseHistoryPagingContext.Provider
      value={{ totalPages, currentPage, updateTotalPage, updateCurrentPage }}
    >
      {children}
    </PurchaseHistoryPagingContext.Provider>
  );
};

const usePurchaseHistoryPaging = () => {
  const context = useContext(PurchaseHistoryPagingContext);
  if (context === undefined) {
    throw new Error('usePurchaseHistoryPaging must be used within PurchaseHistoryPagingContext');
  }
  return context;
};

export { PurchaseHistoryPagingProvider, usePurchaseHistoryPaging };
