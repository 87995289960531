const MortarBoard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth={0}
    viewBox="0 0 24 24"
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3 1 9l11 6 9-4.91V17h2V9L12 3z"
      stroke="none"
    />
  </svg>
);

export default MortarBoard;
