import { useState, createContext, useContext } from 'react';

import { mergeConfigOverrides, defaultConfig as initialConfig } from '../../Utils/config';

declare global {
  interface Window {
    Skooldio: any;
  }
}

type InitialConfigType = typeof initialConfig;

interface IConfigContext extends Partial<InitialConfigType> {
  [key: string]: any;
}

const ConfigContext = createContext<IConfigContext>({});

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(mergeConfigOverrides(window.Skooldio?.paymentConfig ?? {}));

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within ConfigContext');
  }
  return context;
};

export { ConfigProvider, useConfig, initialConfig };
