import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Box from '../Base/Box';

interface LegacyModalProps {
  muiProps: {
    toggleComponent?: JSX.Element;
    onOpen?: () => void;
    onClose?: () => void;
    isActive: boolean;
    muiStyles?: Record<string, unknown>;
  };
  header?: JSX.Element;
  contents?: JSX.Element;
  footer?: JSX.Element;
  footerProps?: Record<string, unknown>;
}

const useDialogStyles = makeStyles({
  root: ({ transBlack }: { transBlack: string }) => ({
    backgroundColor: 'transparent',
    '& .MuiBackdrop-root': {
      backgroundColor: `${transBlack}`,
    },
  }),
  paper: {
    backgroundColor: 'transparent',
    borderRadius: '16px',
    overflow: 'hddent',
    boxShadow: 'none',
    maxWidth: '480px',
    width: '100%',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '& .MuiDialogContent-root': {
      overflowY: 'visible',
    },
  },
});

const useDialogContentStyles = makeStyles({
  root: {
    padding: '0px',
    '&:first-child': {
      paddingTop: '0px', // Override MUI Styling
    },
  },
});

const ModalCard = styled(Box)`
  background: white;
`;

/* TODO: Remove LegacyModal after implement new Modals */
const LegacyModal = (props: LegacyModalProps) => {
  const { header, contents, footer, footerProps, muiProps } = props;
  const { isActive, toggleComponent, onOpen, onClose, muiStyles, ...restMuiProps } = muiProps;
  const dialogClasses = useDialogStyles({ transBlack: 'rgba(0,0,0,.8)' });
  const dialogContentClasses = useDialogContentStyles();

  const handleClose = (e: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose?.();
  };

  return (
    <>
      {React.isValidElement(toggleComponent) &&
        React.cloneElement(toggleComponent as React.ReactElement<any>, { onClick: onOpen })}
      <Dialog
        open={isActive}
        onClose={handleClose}
        classes={{ ...dialogClasses, ...muiStyles }}
        {...restMuiProps}
      >
        <DialogContent classes={dialogContentClasses}>
          <ModalCard>
            <Box height={10} bg={'primary.500'} />
            <Box px={{ _: 4, md: 7 }} py={7}>
              {header && <Box>{header}</Box>}
              {contents && <Box>{contents}</Box>}
              {footer && <Box {...footerProps}>{footer}</Box>}
            </Box>
          </ModalCard>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LegacyModal;
