import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import SectionTitle from '../SectionTitle';
import TextInput from '../Base/TextInput';
import Grid from '../Base/Grid';
import Box from '../Base/Box';

import TextMaskedInput from '../../Components/TextMaskedInput';
import { useConfig } from '../../Contexts/ConfigContext';
import AccountClarificationText from '../AccountClarificationText';

import { PaymentUserInfoProps } from './types';

import { Text } from '../Base';

export const PAYMENT_USER_INFO_FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
};

/* #region STYLING */
const StyledHelperText = styled((props) => <Text textStyle="caption" {...props} />)`
  color: rgba(0, 0, 0, 0.54);
  margin: 4px 2px;

  & > span {
    cursor: pointer;
    text-decoration: underline;
    margin: 0 4px;
  }
`;

const PaymentUserInfoWrapper = styled(Grid)`
  ${({ theme }) => css`
    width: 100%;
    gap: ${theme.space[4]}px;

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(2, auto);
    }
  `}

  ${({ theme, isAnonymous }) =>
    isAnonymous &&
    css`
      /* select a p element under the first input group (firstname) that doesn't contain the class 'mui-error' */
      .MuiFormControl-root.MuiTextField-root:first-child p:not([class*='Mui-error']) {
        display: none;

        @media screen and (min-width: ${theme.breakpoints.lg}) {
          display: block;
        }
      }

      /* select a p element under the second input group (lastname) that doesn't contain the class 'mui-error' */
      .MuiFormControl-root.MuiTextField-root:nth-child(2) p:not([class*='Mui-error']) {
        display: block;

        @media screen and (min-width: ${theme.breakpoints.lg}) {
          display: none;
        }
      }
    `}
`;
/* #endregion */

// TODO: Migrate all input into form input by wrapping TextInput component with useController
// Ref: https://github.com/Skooldio/paas-web-monorepo/pull/63#pullrequestreview-739820082
const PaymentUserInfo = ({
  sectionTitle,
  firstNameInputProps,
  lastNameInputProps,
  emailInputProps,
  phoneNumberInputProps,
  isLoggedIn,
  children,
}: PaymentUserInfoProps): JSX.Element => {
  const { t } = useTranslation();
  const { enableAnonymousUser } = useConfig();
  const { setValue } = useFormContext();
  const { suggestionText: emailSuggestionText } = emailInputProps ?? {};

  const isAnonymousUser = enableAnonymousUser && !isLoggedIn;
  const emailLabel = isAnonymousUser
    ? t('purchase_form_email_anonymous')
    : t('purchase_form_email');

  return (
    <Box>
      <SectionTitle title={sectionTitle} />
      <Grid width={1} gridGap={4}>
        {isAnonymousUser && <AccountClarificationText />}
        {children}
        <PaymentUserInfoWrapper isAnonymous={isAnonymousUser}>
          <TextInput
            label={t('purchase_form_firstname')}
            fullWidth={true}
            {...firstNameInputProps}
          />
          <TextInput label={t('purchase_form_lastname')} fullWidth={true} {...lastNameInputProps} />
          <Box>
            <TextInput label={emailLabel} fullWidth={true} {...emailInputProps} />
            {!!emailSuggestionText && (
              <StyledHelperText>
                หรือคุณหมายถึง
                <span
                  onClick={() =>
                    setValue(PAYMENT_USER_INFO_FIELDS.EMAIL, emailSuggestionText, {
                      shouldValidate: true,
                    })
                  }
                >
                  {emailSuggestionText}
                </span>
                รึเปล่า?
              </StyledHelperText>
            )}
          </Box>
          {phoneNumberInputProps && (
            <TextMaskedInput
              label={t('purchase_form_phone')}
              fullWidth={true}
              {...phoneNumberInputProps}
            />
          )}
        </PaymentUserInfoWrapper>
      </Grid>
    </Box>
  );
};

export default PaymentUserInfo;
