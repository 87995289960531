import { ReactNode } from 'react';
import {
  SHIPMENT_METHOD,
  SHIPMENT_PROVIDER,
} from '@skooldio/paas-shared-react-components/lib/Domain/Payment/constants';

export interface PaymentProviderProps {
  children: ReactNode;
}

export enum SKUStatus {
  COMING_SOON = 'COMING_SOON',
  SELLING = 'SELLING',
  SOLDOUT = 'SOLDOUT',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum CUSTOMER_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATE = 'CORPORATE',
}

export enum PAYMENT_METHOD {
  QR_CODE = 'QRCODE',
  ATM = 'ATM',
  CREDIT_CARD = 'CC',
  ALL = 'ALL',
  IN_APP_PURCHASE_IOS = 'IN_APP_PURCHASE_IOS',
  IN_APP_PURCHASE_ANDROID = 'IN_APP_PURCHASE_ANDROID',
  COUNTER_SERVICE = 'COUNTER_SERVICE',
}

export enum UNIT {
  THB = 'THB',
}

export enum SKU_TYPE {
  BASE = 'BASE',
  STANDARD = 'STANDARD',
  COLLECTION = 'COLLECTION',
  BUNDLE = 'BUNDLE',
  VIRTUAL = 'VIRTUAL',
}

export enum SKU_CATEGORY {
  ALL = 'ALL',
  WORKSHOP = 'WORKSHOP',
  WORKSHOP_BATCH = 'WORKSHOP_BATCH',
  ONLINE_COURSE = 'ONLINE_COURSE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  COLLECTION = 'COLLECTION',
  BOOK = 'BOOK',
}

export { SHIPMENT_PROVIDER, SHIPMENT_METHOD };

export enum SHIPMENT_COUNTRY {
  THAILAND = 'THAILAND',
}

export interface ProductCategoriesProps {
  categories: { permalink: string }[];
  parentPermalink: string;
}

export interface SKUItemMetaSubscriptionConditionProps {
  type: string;
  days?: number;
}
export interface SKUItemMetaSubscriptionProps {
  conditions: SKUItemMetaSubscriptionConditionProps[];
}

export interface SKUItemProps {
  id: number;
  SKUCode: string;
  title: string;
  SKUType: SKU_TYPE;
  SKUCategory: SKU_CATEGORY;
  meta?: SKUItemMetaSubscriptionProps;
  quantity: number;
  totalPrice: number;
  cardImageUrl: string;
}

export type SKUItemsProps = Pick<SKUItemProps, 'title' | 'SKUCategory' | 'meta'>;

type Address = {
  address: string;
  amphur: string;
  district: string;
  province: string;
  zipcode: string;
};
export type handlePurchaseOrderInput = Address & {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  paymentMethod: string;
  companyInfo: {
    name: string;
    taxId: string;
    branch: string;
  } & Address;
  customerInfo: {
    firstName: string;
    lastName: string;
    taxId: string;
  } & Address;
  postalAddress: Address;
};

export interface PurchaseDetailData {
  // Product Detail
  skuCode?: string;
  courseTitle?: string;
  productType?: SKU_CATEGORY;
  stock?: number;
  isVirtualWorkshop?: boolean;

  // Product Purchasing Detail
  productPrice?: number;
  priceInclVAT?: number;
  discountInclVAT?: number;
  totalAmountExclVAT?: number;
  unit?: string;
  paymentAmount?: number;
  withholdingTax?: number;
  paymentMethod?: PAYMENT_METHOD;
  discountCode?: string;
  productImageUrl?: string;
  isShipmentPackage: boolean;
  workshopEventTimeText?: string;
  customerType?: CUSTOMER_TYPE;
  taxInvoiceRequested: boolean;
  useTaxInvoiceAddressAsShipmentAddress: boolean;
  productQuantity?: number;
}

export interface SearchParamsProps {
  d?: string | undefined;
}

export interface IPaymentContext extends PurchaseDetailData {
  // Payment Action
  updateSelectedPaymentMethod: (method: string) => void;
  updateDiscountCode: ({
    discountCode,
    onDiscountCodeInvalid,
  }: {
    discountCode: string;
    onDiscountCodeInvalid?: () => void;
  }) => void;

  updateCustomerType: (newCustomerType: CUSTOMER_TYPE) => void;
  updateTaxInvoiceRequested: (newTaxInvoiceRequested: boolean) => void;
  updateTaxInvoiceAddressAsShipmentAddress: (
    UseTaxInvoiceAddressAsShipmentAddress: boolean
  ) => void;
  updateProductQuantity: (quantity: number) => void;
  handlePurchaseOrder: (input: handlePurchaseOrderInput) => void;

  isInvalidDiscountCode: boolean;
  setIsInvalidDiscountCode: (boolean) => void;
  discountCodeParamValue: string | undefined;
  setSearchParams: (SearchParamsProps) => void;

  // Payment Status
  isPreSalesOrderProcessing: boolean;
  isDiscountCodeValidating: boolean;
  isCoursePurchasing: boolean;
  isPurchaseOrderFailed: boolean;
  isPaymentPreSalesOrderUpdating: boolean;
  purchaseOrderFailedMessage: PURCHASE_ORDER_ERROR;
  clearPurchaseOrderStatus: () => void;
}

export interface handlePurchaseInput extends handlePurchaseOrderInput {}
