import { snakeCase } from 'change-case';

import { SKU_CATEGORY } from '../../../Contexts/PaymentProvider/PaymentProvider.d';

const mapGAEventCategoryValue = ({
  productType,
  productTitle,
  skuCode,
}: {
  productType?: SKU_CATEGORY;
  productTitle?: string;
  skuCode?: string;
}): string => {
  switch (productType) {
    case SKU_CATEGORY.ONLINE_COURSE:
      return `COURSE_${snakeCase(productTitle ?? '').toLocaleLowerCase()}`;
    case SKU_CATEGORY.COLLECTION:
      return `BUNDLE_${snakeCase(productTitle ?? '').toLocaleLowerCase()}`;
    case SKU_CATEGORY.WORKSHOP_BATCH:
      return `WORKSHOP_${snakeCase(productTitle ?? '').toLocaleLowerCase()}_${skuCode}`;
    default:
      return 'N/A';
  }
};

export default mapGAEventCategoryValue;
