const Certificate = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 65V75H100V65H20ZM20 10H100C102.75 10 105.104 10.9792 107.062 12.9375C109.021 14.8958 110 17.25 110 20V75C110 77.75 109.021 80.1042 107.062 82.0625C105.104 84.0208 102.75 85 100 85H80V101.875C80 103.792 79.2083 105.229 77.625 106.188C76.0417 107.146 74.4167 107.208 72.75 106.375L62.25 101.125C61.5833 100.708 60.8333 100.5 60 100.5C59.1667 100.5 58.4167 100.708 57.75 101.125L47.25 106.375C45.5833 107.208 43.9583 107.146 42.375 106.188C40.7917 105.229 40 103.792 40 101.875V85H20C17.25 85 14.8958 84.0208 12.9375 82.0625C10.9792 80.1042 10 77.75 10 75V20C10 17.25 10.9792 14.8958 12.9375 12.9375C14.8958 10.9792 17.25 10 20 10ZM20 50H100V20H20V50Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Certificate;
