import styled from 'styled-components';
import InternalLink from 'next/link';

import { LinkProps, NextLinkProps, AnchorLinkProps } from './Link.d';

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.primary[500]};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary[700]};
  }
`;

const Link = ({ href, children, ...linkProps }: LinkProps): JSX.Element => {
  const destination = href?.toString();
  const anchorProps: AnchorLinkProps = linkProps;

  if (destination && destination.startsWith('http')) {
    return (
      <ExternalLink href={destination} {...anchorProps}>
        {children}
      </ExternalLink>
    );
  }

  const nextLinkProps: NextLinkProps = { href: destination, ...linkProps };
  return (
    <InternalLink {...nextLinkProps} passHref>
      <ExternalLink {...anchorProps}>{children}</ExternalLink>
    </InternalLink>
  );
};

export type { LinkProps };
export default Link;
