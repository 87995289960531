import styled, { useTheme, css } from 'styled-components';

import SVGIcon from '../SVGIcon';
import { Text, Flex } from '../Base';
import { MenuProps } from './types';

const MenuItemWrapper = styled((props) => <Flex alignItem="center" py={3} px={4} {...props} />)`
  cursor: pointer;

  ${({ theme, $isActive }) => css`
    background: ${$isActive && theme.colors.background.uiState.active};
  `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.uiState.hover};
  }
`;

const MenuItem = ({
  label,
  icon,
  onClick,
  isActive,
  className,
  ...props
}: MenuProps): JSX.Element => {
  const theme = useTheme();

  return (
    <MenuItemWrapper onClick={onClick} $isActive={isActive} className={className} {...props}>
      <SVGIcon name={icon} color={theme.colors.icon.uiState.default} width="20px" height="20px" />
      <Text textStyle="body1Medium" ml={3} color="text.darkText">
        {label}
      </Text>
    </MenuItemWrapper>
  );
};

export default MenuItem;
