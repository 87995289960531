import { ApolloError, DocumentNode } from '@apollo/client';

export enum PAYMENT_TASK {
  NONE = 'NONE',
  REDIRECT = 'REDIRECT',
  GET_REDIRECT_URL = 'GET_REDIRECT_URL',
  IN_APP_PURCHASE = 'IN_APP_PURCHASE',
  GET_BILL_PAYMENT = 'GET_BILL_PAYMENT',
}

export enum PURCHASE_ORDER_ERROR {
  DUPLICATED_ONLINE_COURSE = 'DUPLICATED_ONLINE_COURSE',
  UNEXPECTED = 'UNEXPECTED',
}

interface OrderItem {
  SKUCode: string;
  quantity: number;
}

interface ShipmentAddressProps {
  fullName: string;
  phoneNumber: string;
  address: string;
  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;
  country: string;
}

interface ShipmentInfoProps {
  shipmentAddress: ShipmentAddressProps;
}

type AddressInfoProps = {
  address: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
}

interface CompanyInfoProps {
  name: string;
  taxId: string;
  branch: string;
  address: string;
  addressInfo: AddressInfoProps;
}

type CustomerInfoProps = {
  firstName: string;
  lastName: string;
  taxId: string;
  address: string;
  addressInfo: AddressInfoProps;
};

export interface SFFoPlaceOrderWithOfflineTransactionInputProps {
  items: OrderItem[];
  paymentMethod?: PAYMENT_METHOD;
  customerType?: CUSTOMER_TYPE;
  customerFullName?: string;
  customerAddress?: string;
  customerMobile?: string;
  customerEmail?: string;
  discountCode?: string;
  resultUrl?: string;
  shipment?: ShipmentInfoProps;
  companyInfo?: CompanyInfoProps;
  customerInfo?: CustomerInfoProps;
  taxInvoiceRequested?: boolean;
}

interface SaleOrderItemsProps {
  id: number;
  orderNo: string;
  type: string;
  discountCode?: string;
  VAT?: number;
  paymentAmount?: number;
  paymentMethod?: string;
}

interface SFFoPlaceOrderWithOfflineTransactionDataProps {
  isSuccess: boolean;
  task: PAYMENT_TASK;
  responseUrl?: string;
  totalAmount?: number;
  salesOrder?: SaleOrderItemsProps;
  error?: string;
}

export interface PlaceOrderWithOfflineTransactionMutation extends DocumentNode {
  sfFoPlaceOrderWithOfflineTransaction: SFFoPlaceOrderWithOfflineTransactionDataProps;
}
export interface PlaceOrderWithOfflineTransactionMutationOptionsProps {
  input?: SFFoPlaceOrderWithOfflineTransactionInputProps;
  onError?: (error: ApolloError) => void;
}
