import { Navigation as NavigationView } from '@skooldio/paas-shared-react-components';
import useAppMenu from '../../Domain/useAppMenu';
import { useLocation } from 'react-router-dom';

const Navigation = ({ disabledAppMenu }: { disabledAppMenu: boolean }) => {
  const { appMenu } = useAppMenu();
  const location = useLocation();

  const menuItems = disabledAppMenu
    ? []
    : appMenu.map((menu) => ({
        permalink: menu.link,
        menuText: menu.label,
      }));

  return <NavigationView menuItems={menuItems} currentPath={location.pathname} />;
};

export default Navigation;
