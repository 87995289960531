const ViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" {...props}>
    <path
      d="M8 4c2.527 0 4.78 1.42 5.88 3.667A6.513 6.513 0 018 11.333a6.514 6.514 0 01-5.88-3.666A6.513 6.513 0 018 4zm0-1.333c-3.333 0-6.18 2.073-7.333 5 1.153 2.926 4 5 7.333 5 3.334 0 6.18-2.074 7.334-5-1.154-2.927-4-5-7.334-5zM8 6a1.667 1.667 0 110 3.335A1.667 1.667 0 018 6zm0-1.333c-1.653 0-3 1.346-3 3 0 1.653 1.347 3 3 3 1.654 0 3-1.347 3-3 0-1.654-1.346-3-3-3z"
      fill="currentColor"
    />
  </svg>
);

export default ViewIcon;
