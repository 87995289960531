const Lock = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5 11h-14a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2ZM7.5 11V7a5 5 0 1 1 10 0v4"
        stroke="#BFBFBF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Lock;
