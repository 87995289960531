import { Grid } from '../Base';

const SidebarLayout = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Grid
    maxWidth={1200}
    width={1}
    gridTemplateColumns={{ _: '100%', md: 'minmax(180px,1fr) minmax(500px,3fr)' }}
    gridGap={5}
    m="0 auto"
    px={4}
    py={{ _: 6, md: 7 }}
  >
    {children}
  </Grid>
);

export default SidebarLayout;
