import fetch from 'unfetch';

const resolveArgument = (value) => (typeof value === 'string' ? value : JSON.stringify(value));

export const argsToString = (args = {}) => {
  const keys = Object.keys(args);
  if (keys.length === 0) return '';
  return '?' + keys.map((key) => `${key}=${resolveArgument(args[key])}`).join('&');
};

const parseValue = (res, parseAsJson = true) => {
  if (res.status === 204) return {};
  return parseAsJson ? res.json() : res.text();
};

function fetch2(
  path,
  params = null,
  method = METHOD.GET,
  body,
  fetchOptions = { parseJson: true }
) {
  const options = {
    method,
    headers: Object.assign(
      {},
      {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }
    ),
    credentials: 'include',
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  const callPath = params ? `${path}${argsToString(params)}` : path;

  return fetch(callPath, options).then((res) => parseValue(res, fetchOptions.parseJson));
}

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

fetch2.METHOD = METHOD;

export default fetch2;
