import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NotFound, LoadingModal } from '@skooldio/paas-shared-react-components';
import styled from 'styled-components';

import { useConfig } from '../Contexts/ConfigContext';
import AppLayout from './Containers/AppLayout';
import PrivateRoute from './Containers/PrivateRoute';

import PurchaseRoute from './Purchase';
import PurchaseHistory from './PurchaseHistory';
import ThankYouRoute from './ThankYou';
import useGoogleAnalytics from '../Domain/GoogleAnalytics';
import ExternalRedirect from './ExternalRedirect';

const WhiteColorBackground = styled.div`
  > main {
    background-color: white;
  }
`;

const RoutesApp = (): JSX.Element => {
  const { storeURL } = useConfig();
  useGoogleAnalytics();
  return (
    <Routes>
      <Route
        path="/"
        element={
          storeURL ? <ExternalRedirect to={storeURL.toString()} /> : <LoadingModal isOpen={true} />
        }
      />
      <Route
        path="/purchase/:skuCode"
        element={
          <PrivateRoute>
            <AppLayout disabledNavigationAppMenu={true}>
              <PurchaseRoute />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/purchase-history"
        element={
          <PrivateRoute>
            <WhiteColorBackground>
              <AppLayout>
                <PurchaseHistory />
              </AppLayout>
            </WhiteColorBackground>
          </PrivateRoute>
        }
      />
      <Route
        path="/orders/:orderId/thank-you"
        element={
          <PrivateRoute>
            <AppLayout disabledNavigationAppMenu={true}>
              <ThankYouRoute />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <AppLayout>
            <NotFound />
          </AppLayout>
        }
      />
    </Routes>
  );
};

const AppRoutes = (): JSX.Element => {
  return (
    <Router>
      <RoutesApp />
    </Router>
  );
};

export default AppRoutes;
