import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { Text, Flex, Box } from '../Base';
import BaseLink from '../Base/Link';
import SVGIcon from '../SVGIcon';

import { SocialMediaProps } from './types';

const Link = styled(BaseLink)`
  line-height: 0;
  display: block;
`;

const SocialWrapper = styled(Box)`
  &:last-child {
    margin-right: 0;
  }
`;

const SocialMedia = ({ socialChannel = [] }: SocialMediaProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  // Should not be displayed if there's no social media channel
  if (socialChannel.length === 0) {
    return null;
  }

  return (
    <Flex justifyContent="flex-start" alignItems="center">
      <Text textStyle={{ _: 'body2', md: 'body1' }} color="text.darkText" flex="0 0 auto">
        {t('footer_social_media')}
      </Text>
      <Flex ml={{ _: 4, lg: 6 }} justifyContent="flex-start" alignItems="center">
        {socialChannel.map(({ iconName, width, height, socialMediaUrl }) => (
          <SocialWrapper mr={{ _: 4, lg: 6 }} key={iconName}>
            <Link href={socialMediaUrl} target="_blank" rel="noreferrer noopener">
              <SVGIcon
                name={iconName}
                color={theme.colors.text.darkText}
                width={width}
                height={height}
              />
            </Link>
          </SocialWrapper>
        ))}
      </Flex>
    </Flex>
  );
};

export default SocialMedia;
