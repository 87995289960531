import styled from 'styled-components';

import Header from '../../Containers/Header';
import Footer from '../../Containers/Footer';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AppLayout = ({
  disabledNavigationAppMenu = false,
  children,
}: {
  disabledNavigationAppMenu?: boolean;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <Main>
      <Header disabledNavigationAppMenu={disabledNavigationAppMenu} />
      {children}
      <Footer />
    </Main>
  );
};

export default AppLayout;
