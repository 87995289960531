import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { makeStyles, MenuItem, PopoverOrigin } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import Text from '../Text';

import TextInput from '../TextInput';

import { SelectionInputProps } from './types';

const DropdownArrow = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 9l6 6 6-6"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const useStyle = (): ClassNameMap<
  'menuItem' | 'selection' | 'selectionPaper' | 'selectionIcon'
> => {
  const theme = useTheme();
  const classes = makeStyles({
    menuItem: {
      minHeight: 0,
      padding: 0,
      '&.Mui-disabled': { opacity: 1 },
    },
    selection: {
      padding: `0 ${theme.space[7]}px 0 0 !important`,
    },
    selectionPaper: {
      maxHeight: '320px',
    },
    selectionIcon: {
      color: theme.colors.primary[500],
    },
  })();
  return classes;
};

const SelectionInput = ({
  placeholder,
  options,
  renderItem,
  ...props
}: SelectionInputProps): JSX.Element => {
  const classes = useStyle();
  const selectProps = useMemo(
    () => ({
      displayEmpty: placeholder ? true : false,
      classes: { root: classes.selection, icon: classes.selectionIcon },
      IconComponent: DropdownArrow,
      MenuProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        } as PopoverOrigin,
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        } as PopoverOrigin,
        marginThreshold: 0,
        classes: {
          paper: classes.selectionPaper,
        },
        MenuListProps: {
          classes: { root: classes.menuItem },
          disablePadding: true,
        },
      },
    }),
    [classes, placeholder]
  );
  return (
    <TextInput placeholder={placeholder} {...props} select SelectProps={selectProps}>
      {placeholder && (
        <MenuItem value="" disabled classes={{ root: classes.menuItem }}>
          <Text px={4} py={2} color="text.darkTextAlt">
            {placeholder}
          </Text>
        </MenuItem>
      )}
      {Array.isArray(options) && options.length
        ? options.map((option, index) => (
            <MenuItem
              {...option}
              key={`${index}-${option.value}`}
              value={option.value}
              classes={{ root: classes.menuItem }}
            >
              {typeof renderItem === 'function' ? (
                React.cloneElement(renderItem({ ...option, index }))
              ) : (
                <Text textOverflow="ellipsis" overflow="hidden" px={4} py={2}>
                  {option.label}
                </Text>
              )}
            </MenuItem>
          ))
        : null}
    </TextInput>
  );
};

export type { SelectionInputProps };
export default SelectionInput;
