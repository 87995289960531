import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex, ButtonOutlined, ButtonFilled } from '../Base';
import { GuestMenuProps } from './types';

const GuestMenuWrapper = styled((props) => <Flex px={5} pb={5} {...props} />)`
  gap: 8px;
`;

const GuestMenu = ({ login, register, onclickMenu }: GuestMenuProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <GuestMenuWrapper>
      <ButtonFilled
        width={1}
        py={1}
        fontWeight="600"
        onClick={() => {
          login();
          onclickMenu && onclickMenu();
        }}
      >
        {t('guest_menu_login_button')}
      </ButtonFilled>
      <ButtonOutlined
        width={1}
        py={1}
        color="button.outlined"
        fontWeight="600"
        onClick={() => {
          register();
          onclickMenu && onclickMenu();
        }}
      >
        {t('guest_menu_register_button')}
      </ButtonOutlined>
    </GuestMenuWrapper>
  );
};

export default GuestMenu;
