const LibraryBooks = (props) => {
  return (
    <svg width={120} height={120} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 30H10v70c0 5.5 4.5 10 10 10h70v-10H20V30zm80-20H40c-5.5 0-10 4.5-10 10v60c0 5.5 4.5 10 10 10h60c5.5 0 10-4.5 10-10V20c0-5.5-4.5-10-10-10zm-5 45H45V45h50v10zM75 75H45V65h30v10zm20-40H45V25h50v10z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LibraryBooks;
