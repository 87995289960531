import React, { useContext } from 'react';

import { AuthContext } from './AuthProvider';

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('usePurchaseState must be used within AuthProvider');
  }
  return context;
};

const withAuth = (WrappedComponent) => {
  return (props) => {
    const authContext = useContext(AuthContext);
    return <WrappedComponent {...props} {...authContext} />;
  };
};

export { withAuth, useAuth };
