import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Flex, Text, Box } from '../Base';
import SVGIcon, { ICON_LIST } from '../SVGIcon';

const NotFound = (): JSX.Element => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems="center" py={11} px={4}>
      <SVGIcon name={ICON_LIST.SENTIMENT_DISSATISFIED} color={colors.icon.largeIcon} />
      <Text textStyle="heading3" pt={6} textAlign="center" color="text.darkText">
        {t('not_found')}
      </Text>
      <Text textStyle="body1" color="text.darkTextAlt" pt={4} textAlign="center">
        <Box display="inline-block">กรุณาตรวจสอบ URL ของคุณอีกครั้ง</Box>
        <Box display="inline-block">ซึ่งอาจสะกดผิดหรือไม่มีหน้านี้อยู่ในระบบแล้ว</Box>
      </Text>
    </Flex>
  );
};

export default NotFound;
