import styled from 'styled-components';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { Box, Text } from '../../Base';
import { LineEllipsis } from '../../Mixins/LineEllipsis';

interface WorkshopInformationProps {
  title: string;
  subTitle?: string;
  titleTextStyle?: string | object;
  subTitleTextStyle?: string | object;
}

const Title = styled((props) => (
  <Text as="h2" color="text.darkText" fontWeight="semibold" {...props} />
))`
  ${({ $ellipsisLine }) => LineEllipsis($ellipsisLine, true)}
`;

const SubTitle = styled((props) => <Text m={0} mt={1} color="text.darkTextAlt" {...props} />)`
  ${({ $ellipsisLine }) => LineEllipsis($ellipsisLine, true)}
`;

const WorkshopInformation = ({
  title,
  subTitle,
  titleTextStyle = { _: 'body2', sm: 'body1Medium' },
  subTitleTextStyle = 'caption',
}: WorkshopInformationProps): JSX.Element => {
  const { breakpoints } = useTheme();
  const isMobileSize = useMediaQuery(breakpoints.down('xs'));

  return (
    <Box>
      <Title textStyle={titleTextStyle} mb={2} $ellipsisLine={2}>
        {title}
      </Title>
      {!isMobileSize && !!subTitle && (
        <SubTitle textStyle={subTitleTextStyle} $ellipsisLine={2}>
          {subTitle}
        </SubTitle>
      )}
    </Box>
  );
};

export { WorkshopInformation };
