import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import SVGIcon, { ICON_LIST } from '../SVGIcon';

import { Flex, Text } from '../Base';

const EmptyPurchaseHistory = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation('purchase-history');

  return (
    <Flex flexDirection="column" alignItems="center" pt={10} pb={11} mx="auto">
      <SVGIcon
        name={ICON_LIST.HISTORY}
        color={theme.colors.icon.largeIcon}
        width="120"
        height="90"
        viewBox="1 0 21 18"
      />
      <Text mt={4} textStyle="body1" color="text.darkTextAlt">
        {t('empty_purchase_history')}
      </Text>
    </Flex>
  );
};

export default EmptyPurchaseHistory;
