import urlJoin from 'url-join';

import fetch from '../../Contexts/Utils/fetch';
import {
  handleQuickRegisterInput,
  QuickRegisterInputProps,
  QUICK_REGISTER_ERROR_CODE,
  QUICK_REGISTER_RESPONSE_STATUS,
  REDIRECTION_MESSAGE,
} from './types';
import { useConfig } from '../../Contexts/ConfigContext';
import { useAuth } from '../../Contexts/AuthContext';
import goToLoginPage from '../../Utils/goToLoginPage';

const quickRegister = async (
  path,
  { firstName, lastName, email, consentVersion }: QuickRegisterInputProps
) => {
  try {
    const result = await fetch(path, null, fetch.METHOD.POST, {
      firstName,
      lastName,
      email,
      isVerified: true,
      userConsent: { terms: { accept: true, acceptedVersion: consentVersion } },
    });

    return result;
  } catch (error) {
    throw Error(error);
  }
};

const useQuickRegister = () => {
  const { loginUrl, userAPI, discountCodeParamKey } = useConfig();
  const { fetchUserInfo } = useAuth();
  const QUICK_REGISTER_PATH = urlJoin(userAPI, '/quickRegister');

  const handleQuickRegister = async (
    purchaseData: handleQuickRegisterInput,
    consentVersion?: string,
    discountCode?: string
  ) => {
    const { firstName, lastName, email } = purchaseData;

    const quickRegisterResponse = await quickRegister(QUICK_REGISTER_PATH, {
      firstName,
      lastName,
      email,
      consentVersion,
    });

    if (quickRegisterResponse.error) {
      const errorMessage = quickRegisterResponse.error?.message;

      if (quickRegisterResponse.error.statusCode === QUICK_REGISTER_ERROR_CODE.DUPLICATED_EMAIL) {
        console.error(`QuickRegister: ${REDIRECTION_MESSAGE.DUPLICATED_EMAIL}`);
        goToLoginPage({
          loginUrl,
          discountCode,
          discountCodeParamKey,
          redirectionMessage: REDIRECTION_MESSAGE.DUPLICATED_EMAIL,
        });
        return QUICK_REGISTER_RESPONSE_STATUS.ERROR;
      }

      throw Error(`QuickRegister API Error - ${errorMessage}`);
    }

    fetchUserInfo();
    return QUICK_REGISTER_RESPONSE_STATUS.SUCCESS;
  };

  return { handleQuickRegister };
};

export default useQuickRegister;
