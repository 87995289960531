import { useController } from 'react-hook-form';

import PaymentUserAddress, {
  PaymentUserAddressProps,
  PAYMENT_USER_ADDRESS_FIELDS,
} from '../../Components/PaymentUserAddress';

type PaymentAddressProps = Partial<PaymentUserAddressProps> & {
  scope?: string;
};

const PaymentAddress = ({
  scope,
  addressInputProps,
  provinceInputProps,
  amphurInputProps,
  districtInputProps,
  zipcodeInputProps,
}: PaymentAddressProps): JSX.Element => {
  const {
    field: { ref: addressInputRef, ...addressInputField },
    fieldState: { invalid: isAddressInputInValid, error: addressInputError },
  } = useController({
    name: [scope ? `${scope}.` : '', PAYMENT_USER_ADDRESS_FIELDS.ADDRESS].join(''),
    rules: { required: 'กรุณากรอกข้อมูลที่อยู่' },
    defaultValue: '',
  });
  const {
    field: { ref: provinceInputRef, ...provinceInputField },
    fieldState: { invalid: isProvinceInputInValid, error: provinceInputError },
  } = useController({
    name: [scope ? `${scope}.` : '', PAYMENT_USER_ADDRESS_FIELDS.PROVINCE].join(''),
    rules: { required: 'กรุณาเลือกจังหวัด' },
    defaultValue: '',
  });
  const {
    field: { ref: amphurInputRef, ...amphurInputField },
    fieldState: { invalid: isAmphurInputInValid, error: amphurInputError },
  } = useController({
    name: [scope ? `${scope}.` : '', PAYMENT_USER_ADDRESS_FIELDS.AMPHUR].join(''),
    rules: { required: 'กรุณาเลือกเขต/อำเภอ' },
    defaultValue: '',
  });
  const {
    field: { ref: districtInputRef, ...districtInputField },
    fieldState: { invalid: isDistrictInputInValid, error: districtInputError },
  } = useController({
    name: [scope ? `${scope}.` : '', PAYMENT_USER_ADDRESS_FIELDS.DISTRICT].join(''),
    rules: { required: 'กรุณาเลือกแขวง/ตำบล' },
    defaultValue: '',
  });
  const {
    field: { ref: zipcodeInputRef, ...zipcodeInputField },
    fieldState: { invalid: isZipcodeInputInValid, error: zipcodeInputError },
  } = useController({
    name: [scope ? `${scope}.` : '', PAYMENT_USER_ADDRESS_FIELDS.ZIP_CODE].join(''),
    rules: { required: 'กรุณาเลือกรหัสไปรษณีย์' },
    defaultValue: '',
  });

  return (
    <PaymentUserAddress
      addressInputProps={{
        inputRef: addressInputRef,
        error: isAddressInputInValid,
        helperText: addressInputError?.message,
        ...addressInputProps,
        ...addressInputField,
      }}
      provinceInputProps={{
        inputRef: provinceInputRef,
        error: isProvinceInputInValid,
        helperText: provinceInputError?.message,
        ...provinceInputProps,
        ...provinceInputField,
      }}
      amphurInputProps={{
        inputRef: amphurInputRef,
        error: isAmphurInputInValid,
        helperText: amphurInputError?.message,
        ...amphurInputProps,
        ...amphurInputField,
      }}
      districtInputProps={{
        inputRef: districtInputRef,
        error: isDistrictInputInValid,
        helperText: districtInputError?.message,
        ...districtInputProps,
        ...districtInputField,
      }}
      zipcodeInputProps={{
        inputRef: zipcodeInputRef,
        error: isZipcodeInputInValid,
        helperText: zipcodeInputError?.message,
        ...zipcodeInputProps,
        ...zipcodeInputField,
      }}
    />
  );
};

export default PaymentAddress;
