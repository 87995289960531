const LargeMagnifyingGlass = (props) => {
  return (
    <svg
      width={120}
      height={120}
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 20c-19.33 0-35 15.67-35 35s15.67 35 35 35 35-15.67 35-35-15.67-35-35-35zM10 55c0-24.853 20.147-45 45-45s45 20.147 45 45-20.147 45-45 45-45-20.147-45-45z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.716 79.715a5 5 0 017.072 0l21.749 21.749a5 5 0 01-7.071 7.072l-21.75-21.75a5 5 0 010-7.072z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LargeMagnifyingGlass;
