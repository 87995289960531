import { isValidElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Drawer } from '@material-ui/core';

import useDisclosure from '../../Utils/useDisclosure';
import { Box, Flex } from '../../Components/Base';
import Hamburger from '../../Components/Hamburger';
import SVGIcon, { ICON_LIST } from '../../Components/SVGIcon';
import Account from '../../Components/Account';

import { MenuSideNavProps } from './types';

const Divider = styled((props) => <Box width={1} height={4} boxSizing="border-box" {...props} />)`
  border-top: ${({ theme }) => `solid 1px ${theme.colors.border.divider}`};
`;

const StyledDrawer = styled((props) => <Drawer {...props} />)`
  ${({ theme }) => css`
    .MuiPaper-root {
      background-color: ${theme.colors.background.default};
    }
  `}
`;

const MenuSideNav = ({
  accountName,
  accountMembershipStatus,
  upperMenu,
  lowerMenu,
}: MenuSideNavProps): JSX.Element => {
  const theme = useTheme();
  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <Hamburger onClick={open} />
      <StyledDrawer anchor={'right'} open={isOpen} onClose={close}>
        <Box width="320px">
          <Flex justifyContent="flex-end" pt={4} px={6}>
            <SVGIcon name={ICON_LIST.CLOSE} fill={theme.colors.grayscale[400]} onClick={close} />
          </Flex>
          <Account name={accountName} style={{ padding: '12px 16px 20px' }} />
          {typeof accountMembershipStatus === 'function'
            ? accountMembershipStatus({ isMenuSideOpen: isOpen })
            : accountMembershipStatus}
          {typeof upperMenu === 'function' ? upperMenu({ onClose: close }) : upperMenu}
          <Divider />
          {lowerMenu && (
            <>
              {typeof lowerMenu === 'function' ? lowerMenu({ onClose: close }) : lowerMenu}
              <Divider />
            </>
          )}
        </Box>
      </StyledDrawer>
    </>
  );
};

export default MenuSideNav;
