const SentimentDissatisfied = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="120"
      height="121"
      viewBox="0 0 120 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M77.5 55.5C81.6421 55.5 85 52.1421 85 48C85 43.8579 81.6421 40.5 77.5 40.5C73.3579 40.5 70 43.8579 70 48C70 52.1421 73.3579 55.5 77.5 55.5Z"
        fill="currentColor"
      />
      <path
        d="M42.5 55.5C46.6421 55.5 50 52.1421 50 48C50 43.8579 46.6421 40.5 42.5 40.5C38.3579 40.5 35 43.8579 35 48C35 52.1421 38.3579 55.5 42.5 55.5Z"
        fill="currentColor"
      />
      <path
        d="M60 70.5C48.35 70.5 38.4 77.75 34.4 88H42.75C46.2 82.05 52.6 78 60 78C67.4 78 73.75 82.05 77.25 88H85.6C81.6 77.75 71.65 70.5 60 70.5ZM59.95 10.5C32.35 10.5 10 32.9 10 60.5C10 88.1 32.35 110.5 59.95 110.5C87.6 110.5 110 88.1 110 60.5C110 32.9 87.6 10.5 59.95 10.5ZM60 100.5C37.9 100.5 20 82.6 20 60.5C20 38.4 37.9 20.5 60 20.5C82.1 20.5 100 38.4 100 60.5C100 82.6 82.1 100.5 60 100.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SentimentDissatisfied;
