import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    @font-face {
      font-family: 'Anuphan';
      font-weight: 300;
      font-display: swap;
      src: url('/static/fonts/Anuphan/Anuphan-Light.woff2') format('woff2');
      src: url('/static/fonts/Anuphan/Anuphan-Light.woff2') format('woff2'),
        url('/static/fonts/Anuphan/Anuphan-Light.woff') format('woff');
    }

    @font-face {
      font-family: 'Anuphan';
      font-weight: 400;
      font-display: swap;
      src: url('/static/fonts/Anuphan/Anuphan-Regular.woff2') format('woff2');
      src: url('/static/fonts/Anuphan/Anuphan-Regular.woff2') format('woff2'),
        url('/static/fonts/Anuphan/Anuphan-Regular.woff') format('woff');
    }

    @font-face {
      font-family: 'Anuphan';
      font-display: swap;
      font-weight: 500;
      src: url('/static/fonts/Anuphan/Anuphan-Medium.woff2') format('woff2');
      src: url('/static/fonts/Anuphan/Anuphan-Medium.woff2') format('woff2'),
        url('/static/fonts/Anuphan/Anuphan-Medium.woff') format('woff');
    }

    @font-face {
      font-family: 'Anuphan';
      font-weight: 600;
      font-display: swap;
      src: url('/static/fonts/Anuphan/Anuphan-SemiBold.woff2') format('woff2');
      src: url('/static/fonts/Anuphan/Anuphan-SemiBold.woff2') format('woff2'),
        url('/static/fonts/Anuphan/Anuphan-SemiBold.woff') format('woff');
    }

   * {
      font-family: Anuphan, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, serif;
      box-sizing: border-box;
    }
    body {
      margin: 0;
      padding: 0;
      background-color: hsla(0, 0%, 98%, 1);
    }

    a {
      text-decoration: none;
    }
    /* temporary style, will be removed */
    #root {
      /* height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center; */
    }
`;

export default GlobalStyle;
