import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import reportWebVitals from './reportWebVitals';

const sentry = window.Skooldio?.paymentConfig?.sentry;

if (sentry?.dsn) {
  Sentry.init({
    dsn: sentry?.dsn,
    integrations: [new Integrations.BrowserTracing()],
    environment: sentry?.environment ?? 'development',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
