import { SimpleModal, ModalHeader, ModalContent } from '@skooldio/paas-shared-react-components';
import { Text, ButtonFilled } from '../Base';

import CreatePasswordIcon from './images/create-password-icon.svg';
import { CreatePasswordModalProps } from './types';

// TODO: Add simple modal and wrap this content
const CreatePasswordModal = ({
  open,
  email,
  onEmailSend,
  onModalClose,
  ...props
}: CreatePasswordModalProps): JSX.Element => {
  return (
    <SimpleModal disableEscapeKeyDown disableBackdropClick open={open} {...props}>
      <ModalHeader style={{ textAlign: 'center' }}>
        <img width={100} height={100} src={CreatePasswordIcon} alt="Create password locked" />
        <Text textStyle={{ _: 'heading5', md: 'heading4' }} mt={2}>
          อีกนิดเดียว! เช็กอีเมล
          <br />
          เพื่อตั้งรหัสผ่านก่อนเข้าห้องเรียน
        </Text>
      </ModalHeader>
      <ModalContent style={{ textAlign: 'center' }}>
        <Text mt={4} textStyle={'body1'} color={'text.darkText'}>
          โดยกดลิงก์ที่ได้รับในอีเมล
        </Text>
        <Text
          textStyle={'body1'}
          color={'text.darkText'}
          fontWeight={600}
          style={{ 'word-break': 'break-all' }}
        >
          {email}
        </Text>
        <ButtonFilled
          my={7}
          py={'6px'}
          width={{ _: '100%', sm: 'auto' }}
          minWidth={160}
          borderRadius={25}
          color="primary"
          textColor="white"
          fontWeight={600}
          onClick={onModalClose}
        >
          ปิด
        </ButtonFilled>
        {/* TODO: Add resend email text  */}
        {/* <Text textStyle={'caption'} color={'text.darkText'}>
          ยังไม่ได้รับอีเมล{' '}
          <Text
            as={'a'}
            style={{ cursor: 'pointer' }}
            textStyle={'caption'}
            color={'system.info.500'}
            onClick={onEmailSend}
          >
            ส่งอีเมลใหม่อีกครั้ง
          </Text>
        </Text> */}
        <Text textStyle={'caption'} color={'text.darkTextAlt'}>
          *หากไม่ได้รับอีเมลกรุณาติดต่อมาที่ hello@skooldio.com
        </Text>
      </ModalContent>
    </SimpleModal>
  );
};

export default CreatePasswordModal;
