import { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

// remove next line if shared works
// import theme from './Components/Theme';
import {
  extendTheme,
  I18nextProvider,
  type I18nextProviderProps,
} from '@skooldio/paas-shared-react-components';

import AppRoutes from './Routes';
import { createApolloClient } from './lib/GraphQL/apolloClient';
import { AuthProvider } from './Contexts/AuthContext';
import { AnonymousAuthProvider } from './Contexts/AnonymousAuthContext';
import { ConfigProvider, useConfig } from './Contexts/ConfigContext';
import Meta from './Containers/Meta';
import GlobalStyle from './Components/GlobalStyle';
import { googleTagManagerInitialize } from './Utils/analytics/googleTagManager';
import UserConsentValidation from './Containers/UserConsentValidation';

import i18n from './i18n';

const muiTheme = createTheme({
  typography: {
    fontFamily: 'inherit',
  },
});

const theme = extendTheme(false, window.Skooldio.paymentThemeConfig);

function App() {
  const { browserStoreGraphQL, analytics } = useConfig();
  const { platformGoogleTagManager, customerGoogleTagManager } = analytics ?? {};
  const client = createApolloClient(browserStoreGraphQL);

  useEffect(() => {
    googleTagManagerInitialize(platformGoogleTagManager, customerGoogleTagManager);
  }, [platformGoogleTagManager, customerGoogleTagManager]);

  return (
    <I18nextProvider i18n={i18n as I18nextProviderProps['i18n']}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <AnonymousAuthProvider>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={muiTheme}>
                <UserConsentValidation />
                <GlobalStyle />
                <Meta />
                <AppRoutes />
              </MuiThemeProvider>
            </ThemeProvider>
          </AnonymousAuthProvider>
        </AuthProvider>
      </ApolloProvider>
    </I18nextProvider>
  );
}

const AppWithConfig = () => (
  <ConfigProvider>
    <App />
  </ConfigProvider>
);

export default AppWithConfig;
