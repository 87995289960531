const ClockIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9935 1.3335C4.3135 1.3335 1.3335 4.32016 1.3335 8.00016C1.3335 11.6802 4.3135 14.6668 7.9935 14.6668C11.6802 14.6668 14.6668 11.6802 14.6668 8.00016C14.6668 4.32016 11.6802 1.3335 7.9935 1.3335ZM8.00016 13.3335C5.0535 13.3335 2.66683 10.9468 2.66683 8.00016C2.66683 5.0535 5.0535 2.66683 8.00016 2.66683C10.9468 2.66683 13.3335 5.0535 13.3335 8.00016C13.3335 10.9468 10.9468 13.3335 8.00016 13.3335ZM8.3335 4.66683H7.3335V8.66683L10.8335 10.7668L11.3335 9.94683L8.3335 8.16683V4.66683Z"
      />
    </svg>
  );
};

export default ClockIcon;
