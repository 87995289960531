import { EventTime } from '../../../Components/ProductCard/Workshop/types';
import dayjs from 'dayjs';
import {
  get24hTime,
  getFullThaiDateWithFormattedDay,
  getShortThaiDateFullYear,
  getShortThaiDateWithDay,
} from '../../dateUtils';

const formattedDate = (dateString: string | number | Date, options: Intl.DateTimeFormatOptions) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('th-TH', options)
    .format(date)
    .replace('วัน', '')
    .replace('ที่', '');
};

const noMonthOption: Intl.DateTimeFormatOptions = {
  // พฤหัสบดี 5
  weekday: 'long',
  day: 'numeric',
};

const noYearOption: Intl.DateTimeFormatOptions = {
  // พฤหัสบดี 5 ต.ค.
  weekday: 'long',
  month: 'short',
  day: 'numeric',
};

export const formatDateRange = (startDateStr, endDateStr) => {
  const startDate = dayjs(startDateStr);
  if (!endDateStr) return getFullThaiDateWithFormattedDay(startDate);

  const endDate = dayjs(endDateStr);

  if (startDate.isSame(endDate, 'day')) {
    // sameDayFormat
    return getFullThaiDateWithFormattedDay(startDate);
  }
  if (startDate.isSame(endDate, 'month')) {
    // sameMonthFormat
    return `${formattedDate(startDateStr, noMonthOption)} - ${getShortThaiDateFullYear(endDate)}`;
  }
  if (startDate.isSame(endDate, 'year')) {
    // sameYearFormat
    return `${formattedDate(startDateStr, noYearOption)} - ${getShortThaiDateFullYear(endDate)}`;
  }
  // sameYearFormat and differentYearFormat
  return `${formattedDate(startDateStr, noYearOption)} - ${getShortThaiDateFullYear(endDate)}`;
};

export const getFormattedWorkshopDate = (eventTime: EventTime, isWorkshopCard = false) => {
  const { startDate, startTime, endDate, endTime } = eventTime;

  if (isWorkshopCard) {
    return getShortThaiDateWithDay(startDate);
  }

  const formattedDate = formatDateRange(startDate, endDate);
  const timeStr = `เวลา ${get24hTime(startTime)} - ${get24hTime(endTime)}`;

  return `${formattedDate} ${timeStr}`;
};
